/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Sep 15, 2019
UPDATE DATE: Sep 15, 2019
REVISION: 1
NAME: astronaut.work-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#work {
.banner{
    position: relative;
    overflow: hidden;
}

.ban-text{
    color: #FFF;
    position: relative;
    padding: 190px 10% 100px 10%;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
    text-align: center;
}

.ban-text .big-text{
    display: block;
    font-size: 50px;
    line-height: 62px;
    font-weight: 100;
    text-transform: capitalize;
    margin: 0 0 10px 0;
}

.ban-text .big-text img{
    display: inline-block;
    vertical-align: middle;
    width: 280px;
    background: rgba(255, 255, 255, .75);
    margin: -5px 15px 0 15px;
    padding: 15px;
    border-radius: 20px;
    animation: logo-anim 3.5s infinite ease;
    -webkit-animation: logo-anim 3.5s infinite ease;
}

@keyframes logo-anim{
    0% {background: rgba(255, 255, 255, .75); box-shadow: 0 0 30px rgba(255, 255, 255, .4); transform: scale(1, 1);}
    50% {background: rgba(255, 255, 255, 1); box-shadow: 0 0 20px rgba(255, 255, 255, .2); transform: scale(1.05, 1.05);}
    100% {background: rgba(255, 255, 255, .75); box-shadow: 0 0 30px rgba(255, 255, 255, .4); transform: scale(1, 1);}
}

@-webkit-keyframes logo-anim{
    0% {background: rgba(255, 255, 255, .75); -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, .4); -webkit-transform: scale(1, 1);}
    50% {background: rgba(255, 255, 255, 1); -webkit-box-shadow: 0 0 20px rgba(255, 255, 255, .2); -webkit-transform: scale(1.05, 1.05);}
    100% {background: rgba(255, 255, 255, .75); -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, .4); -webkit-transform: scale(1, 1);}
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/work.jpg) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

ul.the-work{
    margin: 10px 0;
}

ul.the-work > li{
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    line-height: 24px;
}

ul.the-work > li:nth-child(even){
    text-align: right;
}

ul.the-work > li > ul{
    overflow: auto;
}

ul.the-work > li > ul > li{
    box-sizing: border-box;
}

ul.the-work > li:nth-child(odd) > ul > li{
    float: left;
}

ul.the-work > li:nth-child(even) > ul > li{
    float: right;
}

ul.the-work > li > ul > li:first-child{
    width: 40%;
    position: relative;
}

ul.the-work > li > ul > li:last-child{
    width: 60%;
    padding: 40px;
}

ul.the-work > li > ul > li > span{
    display: block;
    position: relative;
    margin: 0 0 25px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
}

ul.the-work > li > ul > li > span:after{
    content: '';
    position: absolute;
    height: 1px;
    width: 0%;
    background: #CCC;
    bottom: -14px;
    left: 0;
    animation: line-anim 4s infinite ease;
    -webkit-animation: line-anim 4s infinite ease;
}

ul.the-work > li:nth-child(even) > ul > li > span:after{
    left: auto;
    right: 0;
}

@keyframes line-anim{
    0% {width: 0%; opacity: 1;}
    90% {width: 100%; opacity: 1;}
    100% {width: 0%; opacity: 0;}
}

@-webkit-keyframes line-anim{
    0% {width: 0%; opacity: 1;}
    90% {width: 100%; opacity: 1;}
    100% {width: 0%; opacity: 0;}
}

ul.the-work > li > ul > li > span > em{
    position: relative;
    font-style: normal;
    padding: 0 0 15px 0;
    box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);
    z-index: 1;
    animation: act-anim 4s infinite ease;
    -webkit-animation: act-anim 4s infinite ease;
}

@keyframes act-anim{
    0% {box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
    15% {box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
    35% {box-shadow: inset 0 -5px 0 rgba(86, 79, 219, .85);}
    90% {box-shadow: inset 0 -5px 0 rgba(86, 79, 219, .85);}
    100% {box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
}

@-webkit-keyframes act-anim{
    0% {-webkit-box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
    15% {-webkit-box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
    35% {-webkit-box-shadow: inset 0 -5px 0 rgba(86, 79, 219, .85);}
    90% {-webkit-box-shadow: inset 0 -5px 0 rgba(86, 79, 219, .85);}
    100% {-webkit-box-shadow: inset 0 0 0 rgba(86, 79, 219, 0);}
}

ul.the-work > li > ul > li > img{
    display: block;
    width: 50%;
    height: 200px;
    margin: 0 0 0 auto;
    object-fit: contain;
    transform: translate(5px, 0);
    animation: img-anim 4s infinite ease;
    -webkit-animation: img-anim 4s infinite ease;
}

@keyframes img-anim{
    0% {transform: translate(5px, 0);}
    50% {transform: translate(-5px, 0);}
    100% {transform: translate(5px, 0);}
}

@-webkit-keyframes img-anim{
    0% {-webkit-transform: translate(5px, 0);}
    50% {-webkit-transform: translate(-5px, 0);}
    100% {-webkit-transform: translate(5px, 0);}
}

ul.the-work > li:nth-child(even) > ul > li > img{
    margin: 0 auto 0 0;
}

ul.the-work > li:nth-child(1) > ul > li > span:after,
ul.the-work > li:nth-child(1) > ul > li > span > em,
ul.the-work > li:nth-child(1) > ul > li > img{
    animation-delay: 0s;
}

ul.the-work > li:nth-child(2) > ul > li > span:after,
ul.the-work > li:nth-child(2) > ul > li > span > em,
ul.the-work > li:nth-child(2) > ul > li > img{
    animation-delay: 0.5s;
}

ul.the-work > li:nth-child(3) > ul > li > span:after,
ul.the-work > li:nth-child(3) > ul > li > span > em,
ul.the-work > li:nth-child(3) > ul > li > img{
    animation-delay: 1s;
}

ul.the-work > li:nth-child(4) > ul > li > span:after,
ul.the-work > li:nth-child(4) > ul > li > span > em,
ul.the-work > li:nth-child(4) > ul > li > img{
    animation-delay: 1.5s;
}

ul.the-work > li:nth-child(5) > ul > li > span:after,
ul.the-work > li:nth-child(5) > ul > li > span > em,
ul.the-work > li:nth-child(5) > ul > li > img{
    animation-delay: 2s;
}

ul.the-work > li:nth-child(6) > ul > li > span:after,
ul.the-work > li:nth-child(6) > ul > li > span > em,
ul.the-work > li:nth-child(6) > ul > li > img{
    animation-delay: 2.5s;
}

ul.the-work > li:nth-child(7) > ul > li > span:after,
ul.the-work > li:nth-child(7) > ul > li > span > em,
ul.the-work > li:nth-child(7) > ul > li > img{
    animation-delay: 3s;
}

ul.the-work > li:nth-child(8) > ul > li > span:after,
ul.the-work > li:nth-child(8) > ul > li > span > em,
ul.the-work > li:nth-child(8) > ul > li > img{
    animation-delay: 3.5s;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .ban-text{
        padding: 120px 10% 50px 10%;
    }
    
    .ban-text .big-text img{
        display: block;
        margin: 20px auto;
    }
    
    ul.the-work > li > ul > li:first-child{
        width: 30%;
    }
    
    ul.the-work > li > ul > li:last-child{
        width: 70%;
        padding: 10px 40px;
    }
    
    ul.the-work > li > ul > li > img{
        width: 80%
    }
}

@media screen and (max-width: 767px){
    .ban-text .big-text{
        font-size: 40px;
        line-height: 52px;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){
    .ban-text{
        padding: 90px 20px 0 20px;
    }
    
    .ban-text .big-text img{
        width: 240px;
    }
    
    ul.the-work > li > ul > li:first-child{
        width: 30%;
    }
    
    ul.the-work > li > ul > li:last-child{
        width: 70%;
        padding: 10px 40px;
    }
    
    ul.the-work > li > ul > li > img{
        width: 80%
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text{
        padding: 70px 20px 10px 20px;
    }
    
    .ban-text{
        font-size: 14px;
        line-height: 22px;
    }
    
    .ban-text .big-text img{
        display: block;
        margin: 20px auto;
        width: 240px;
    }
    
    ul.the-work{
        margin: -10px 0;
    }
    
    ul.the-work > li{
        padding: 30px 0;
        font-size: 14px;
        line-height: 22px;
    }
    
    ul.the-work > li > ul > li,
    ul.the-work > li:nth-child(odd) > ul > li,
    ul.the-work > li:nth-child(even) > ul > li{
        float: none;
        width: 100%;
        padding: 0;
        text-align: center;
    }
    
    ul.the-work > li > ul > li > img,
    ul.the-work > li:nth-child(even) > ul > li > img{
        margin: 0 auto 20px auto;
        height: auto;
        width: 40%;
    }
    
    ul.the-work > li > ul > li > span{
        font-size: 16px;
        line-height: 24px;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}