/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jul 24, 2019
UPDATE DATE: Jul 24, 2019
REVISION: 1
NAME: astronaut.homepage-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#homepage {
    background: #FFFFFF;

.block {
  display: block;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}

.btn:hover, .btn:focus {
    box-shadow: none;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.inline-content {
  display: inline-block;
}

.img-responsive {
  margin: 0 auto;
  text-align: center;
}

.auto-margin {
  margin: 0 auto;
}

.block {
  display: block;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.relative {
  position: relative;
}

.no-border {
    border: 0;
}

.uppercase {
    text-transform: uppercase;
}

.float-align-left {
  float: left;
}

.float-align-right {
  float: right;
}

.p-l-45-desk {
    padding-left: 45px;
}

.p-r-45-desk {
    padding-right: 45px;
}

/*
* FLEX CONTAINER
*/

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.flex {
    display: flex;
}

.flex-column {
  flex-flow: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-items-start {
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
          align-items: flex-end;
}

.vertical-item--center {
    @extend .flex-container;
    @extend .align-items-center;
}

.vertical-item--top {
    @extend .flex-container;
    @extend .align-items-start;
}

.vertical-item--justify {
    @extend .flex-container;
    @extend .flex-start;
}

.horizontal-item--start {
  @extend .flex-container;
  @extend .flex-center;
  @extend .align-items-start;
}

.horizontal-item--around {
    @extend .flex-container;
    @extend .flex-around;
    @extend .align-items-center;
}

.horizontal-item--between {
    @extend .flex-container;
    @extend .flex-between;
    @extend .align-items-center;
}

.horizontal-item--center {
    @extend .flex-container;
    @extend .flex-center;
    @extend .align-items-center;
}

.vertical-item--between {
    @extend .flex;
    @extend .flex-column;
    @extend .flex-between;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-center {
    align-self: center;
}

.flex-initial {
    flex: initial;
}

.flex-1 {
    flex: 1;
}

.flex-inherit {
    flex: inherit;
}

.flex-row {
    flex-flow: row;
}

.flex-grow {
    flex-grow: 1;
    flex: 1;
}

.max-width-100 {
  max-width: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.pop{
    position: fixed;
    background: #FFF;
    padding: 20px;
    border-radius: 20px;
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
    box-sizing: border-box;
    box-shadow: 0 20px 100px rgba(0, 0, 0, .1);
}

.pop .pop-head{
    position: relative;
    font-size: 20px;
    line-height: 24px;
    padding: 0 27px 0 0;
}

.pop .pop-head a{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: #999;
    font-size: 24px;
    line-height: 24px;
}

.pop .pop-head a:hover{
    color: #564FDB;
    transform: translate(0, -50%) rotate(90deg);
}

.pop a i{}

.pop video{
    display: block;
    margin: 20px 0 0 0;
    width: 100%;
    border-radius: 10px;
    outline: 0;
}

.overlay{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.banner{
    position: relative;
    overflow: hidden;
}

.banner.home:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 600px 800px 0 0;
    border-color: transparent #F7F7F7 transparent #F7F7F7;
    right: 0;
    bottom: 0;
    position: absolute;
}

.banner.home:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 300px 400px 0 0;
    border-color: transparent #EDEDED transparent #EDEDED;
    right: 0;
    bottom: 0;
    position: absolute;
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/homepage.jpg) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

.ban-text{
    color: #18244E;
    position: relative;
    padding: 180px 52% 120px 0;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
    &.partnership-ban{
        padding-bottom: 320px;
    }
}

.ban-text.for{
    padding: 200px 52% 0 0;
    min-height: 850px;
    max-height: 850px;
    color: #FFFFFF;
}

.ban-text a.vid-msg{
    cursor: default;
    display: block;
    color: #18244E;
    position: absolute;
    right: 0%;
    top: 58%;
    transform: translate(0, -50%);
    padding: 0 0 0 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    &.partner-msg{
        right: -40px !important;
        img{
            width: 90%;
        }
    }
    &.for-msg {
        top: 50%;
        right: -92px;
    }
}

.ban-text a.vid-msg img{
    display: inline-block;
    margin: 0;
    width: 100%;
}

.ban-text a.vid-msg.for-msg img{
    width: 100%;
}

.for-tag {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
    background: #FDF1D8;
    padding: 10px 20px;
    border-radius: 40px;
    margin-bottom: 32px;
    &.university {
        background: #EDF7FD;
        border-color: #4B6FF2;
        color: #4B6FF2;
    }
    &.graduate {
        background: #EFF0F6;
        border-color: #7657E6;
        color: #7657E6;
    }
    &.pro {
        background: transparent;
        border-color: #E99F0C;
        color: #E99F0C;
    }
    &.governments {
        background: transparent;
        border-color: #1D90D7;
        color: #1D90D7;
    }
    &.blue {
        background: transparent;
        border-color: #54AFE8;
        color: #54AFE8;
    }
    &.assess {
        background: transparent;
        border-color: #58D6A8;
        color: #58D6A8;
    }
    &.talent {
        background: transparent;
        border-color: #DB334D;
        color: #DB334D;
    }
}

.background-color {
    height: 535px;
    // width: 50%;
    width: 200%;
    position: absolute;
    top: 15%;
    // right: 0;
    left: -150%;
    z-index: -1;
    border-radius: 0 20px 20px 0;
    &.university {
        background: #4B6FF2;
    }
    &.graduate {
        background: #43969D;
    }
    &.pro {
        background: #F5BC48;
    }
    &.governments {
        background: #1D90D7;
    }
    &.blue {
        background: #54AFE8;
    }
    &.assess {
        background: #58D6A8;
    }
    &.talent {
        background: #DB334D;
    }
}

.red-back-feature {
    background: #C04051;
    height: calc(100% - 133px);
    position: absolute;
    top: 0;
    right: 0;
    width: 38%;
}

.navy-back-feature {
    background: #1A234B;
    height: calc(100% - 99px);
    position: absolute;
    top: 0;
    right: 0;
    width: 38%;
    z-index: 1;
}

.navy-back-feature-left {
    background: #1A234B;
    height: 486px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 38%;
    z-index: 0;
    &.for-1 {
        height: 509px;
        z-index: 1;
    }
    &.for-2 {
        background: #43969D;
        height: 482px;
        z-index: 1;
    }
    &.for-4-red {
        background: #C04051;
        height: 564px;
        z-index: 1;
    }
    &.for-5-red {
        background: #C04051;
        height: 537px;
        z-index: 1;
    }
}

.navy-back-feature-right {
    background: #1A234B;
    height: 486px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 38%;
    z-index: 0;
    &.telkom {
        height: 651px;
    }
    &.for-1 {
        height: 525px;
        z-index: 1;
    }
    &.for-2 {
        height: 455px;
        z-index: 1;
    }
    &.for-3 {
        background: #43969D;
        height: 455px;
        z-index: 1;
    }
    &.for-4 {
        background: #43969D;
        height: 383px;
        z-index: 1;
    }
    &.for-5 {
        height: 508.5px;
        z-index: 1;
    }
}

.red-back-feature-right {
    background: #C04051;
    height: 486px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 38%;
    z-index: 1;
    &.for-1 {
        height: 636px;
    }
}

.purple-back-feature-right {
    background: #32059D;
    height: 486px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 38%;
    z-index: 1;
    &.for-1 {
        height: 679px;
    }
    &.for-2 {
        height: 582px;
    }
    &.for-3 {
        height: 411px;
    }
}

.ban-text .big-text{
    display: block;
    font-size: 48px;
    line-height: 70px;
    font-weight: bold;
    margin: 0 0 12px 0;
}

.ban-text .partnership-desc{
    display: block;
    font-size: 18px;
    line-height: 27px;
    height: 108px;
    width: 510px;
}

.ban-text .big-text em{
    font-weight: bold;
    color: #DB334D;
    position: relative;
    font-style: normal;
}

.ban-text .big-text em::after{
    content: ".";
    color: #18244E;
}

.ban-text .ban-link{
    margin: 15px 0 0 0;
}

.ban-text .ban-link a{
    display: inline-block;
    font-weight: bold;
    color: #DB334D;
    vertical-align: top;
    margin-top: 12px;
}

.ban-text .ban-link > div{
    display: inline-block;
}

.ban-text .ban-link a.try{
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    padding: 14px 32px;
    margin: 0 12px 0 0;
    border-radius: 6px;
    border: 2px solid #DB334D;
    background: #DB334D;
    color: #FFF;
    &.for {
        background: #1A234B;
    }
}

.ban-text .ban-link a.request-demo-logged{
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    padding: 14px 32px;
    margin: 0 48px 0 0;
    border-radius: 6px;
    border: 2px solid #ED2453;
    background: transparent;
    color: #DB334D;
}

// .ban-text .ban-link a.try:hover{
//     color: #564FDB;
//     background: rgba(255, 255, 255, 1);
// }

.span-cc {
    margin-top: 24px;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #494CA1
}

.partner{
    position: relative;
    padding: 48px 20px;
    overflow: visible;
    margin: -140px auto 0 auto;
    z-index: 2;
    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #18244E;
        &.big-letter{
            display: block;
            font-size: 48px;
            line-height: 72px;
            font-weight: bold;
        }
    }
}

.demonstration-margin{
    position: relative;
    padding: 196px 20px;
    overflow: visible;
   
    z-index: 2;
    p {
        
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #18244E;
        &.big-letter{
            display: block;
            font-size: 48px;
            line-height: 72px;
            font-weight: bold;
        }
    }
}

.demo-title-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    p{
        width: 70%;
    }
}

.partner-no-margin{
    position: relative;
    padding: 48px 20px;
    overflow: visible;
   
    z-index: 2;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #18244E;
        &.big-letter{
            display: block;
            font-size: 48px;
            line-height: 72px;
            font-weight: bold;
        }
    }
}

.partner .part-wrap{
    background: #FFF;
    margin-top: 16px;
}

.and-many{
    position: relative;
    text-align: center;
    color: #999;
    margin: 30px 0 -10px 0;
    font-size: 18px;
    line-height: 20px;
}

.and-many:before,
.and-many:after{
    content: '';
    position: absolute;
    height: 1px;
    background: #CCC;
    width: 40%;
    right: 0;
    top: 50%;
    margin: -0.5px 0 0 0;
}

.and-many:after{
    left: 0;
    right: auto;
}

.partner ul{
    overflow: auto;
}

.partner ul li{
    float: left;
    width: 10%;
    padding: 10px;
    box-sizing: border-box;
}

.partner ul li:nth-child(1),
.partner ul li:nth-child(2),
.partner ul li:nth-child(3),
.partner ul li:nth-child(4),
.partner ul li:nth-child(5){
    width: 10%;
}

.partner ul li a{
    display: block;
    cursor: default;
}

.partner ul li a img{
    width: 100%;
    height: 55px;
    object-fit: contain;
    // filter: grayscale(100%);
    // -webkit-filter: grayscale(100%);
    // transition: all 200ms ease-in-out;
    // -moz-transition: all 200ms ease-in-out;
    // -webkit-transition: all 200ms ease-in-out;
}

.partner ul li a:hover img{
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
}

.partner-page-sec {
    padding-top: 80px;
}

.sec-con{
    padding: 100px 20px;
    &.partnership-section {
        padding: 1px 20px;
    }
    &.red {
        background-color: #C04051;
    }
}

.sec-con ul{}

.sec-con ul li{}

.sec-con ul li ul{
    overflow: auto;
    margin: -150px -10px -150px -10px;
    padding: 200px 0;
    &.card-partner{
        margin: 0;
        overflow: hidden;
        padding: 120px 0;
        &.last-card {
            padding: 120px 0 180px;
        }
    }
    &.colab {
        margin: -220px -10px;
    }
    &.assess {
        margin: -50px -10px -200px;
    }
    &.univ-last {
        padding: 150px 0 300px !important;
    }
}

.sec-con.for-astrnt ul li ul{
    margin: -150px -10px;
    padding: 160px 0;
}

.sec-con ul li ul li{
    position: relative;
    color: #18244E;
    font-size: 32px;
    line-height: 48px;
    padding: 1px 10px;
    float: left;
    font-weight: bold;
    box-sizing: border-box;
}

.sec-con ul li ul.on-right li{
    float: right;
}

.sec-con ul li ul.on-left li:first-child{
    width: 50%;
}

.sec-con ul li ul.on-left li:last-child{
    text-align: right;
    width: 50%;
}

.sec-con ul li ul.on-right li:first-child{
    text-align: left;
    width: 50%;
}

.sec-con ul li ul.on-right li:last-child{
    width: 50%;
}

.sec-con ul li ul li label{
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #DB334D;
    margin: 0 0 8px 0;
}

.sec-con ul li ul li a{
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #DB334D;
    margin: 32px 0 0 0;
    cursor: pointer;
    &:hover {
        text-transform: unset;
        text-decoration: unset;
    }
}

.sec-con ul li ul li span{
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0 0 0;
}

.sec-con ul li ul li > img{
    position: absolute;
    display: block;
    z-index: 1;
    top: -25px;
    height: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 100%;
    &.img-card-left{
        top: -100px;
        left: 40px;
        max-width: 104%;
    }
    &.img-card-right{
        top: -100px;
        left: -100px;
        max-width: 104%;
    }
}

.sec-con ul li ul.on-right.feature-list li > img{
    left: -30px;
    max-width: 100%;
}

.sec-con ul li ul li > img.case-icon{
    position: absolute;
    display: block;
    z-index: 1;
    top: -70px;
    height: 465px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.sec-con ul li ul li .button-feature{
    cursor: pointer;
    display: inline-block;
    background: transparent;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #494CA1;
    opacity: 0.8;
    width: 275px;
    border: 2px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px 20px;
}

.div-button-feature {
    display: flex;
    justify-content: space-between;
}

.feature-list > li {
    .div-button-feature:nth-of-type(2){
        margin: 32px 0;
    }
}

.p-feature {
    padding-top: 30px;
    padding-bottom: 0px;
}

.sec-con ul li ul li .button-feature.active,
.sec-con ul li ul li .button-feature:hover{
    border-color: #18244E;
    opacity: 1;
    color: #18244E;
    background: #F3F2FF;
    box-shadow: 0px 4px 16px rgba(24, 36, 78, 0.1);
}

.sec-con ul li ul li .button-case{
    cursor: pointer;
    display: inline-block;
    background: transparent;
    margin-top: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #DB334D;
    width: auto;
    border: 2px solid #DB334D;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 14px 32px;
}

.sec-con ul li:nth-child(2) ul li img{
    height: 260px;
}

.sec-con ul li:nth-child(3) ul li img{
    top: 10px;
}

.sec-con ul li ul li .square-pers{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 300px;
    height: 300px;
    background: #FFF;
    border-radius: 50px;
    margin: -75px auto 0 auto;
    perspective: 50em;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, .1);
    animation: shad-anim 4s infinite ease;
    -webkit-animation: shad-anim 4s infinite ease;
}

.sec-con ul li:nth-child(1) ul li .square-pers{
    animation-delay: 0s;
}

.sec-con ul li:nth-child(2) ul li .square-pers{
    animation-delay: 0.3s;
}

.sec-con ul li:nth-child(3) ul li .square-pers{
    animation-delay: 0.6s;
}

.sec-desc {
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    }
    .desc-check-yellow {
        .fa-check {
            background: #F8DC86;
            color: #C04051;
            width: 28px;
            font-size: 16px;
            height: 28px;
            line-height: 29px;
            border-radius: 28px;
            text-align: center;
            margin-right: 16px;
        }
        span {
            display: block;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin-bottom: 12px;
        }
    }
    .list-navy {
        display: inline-block;
        width: auto;
        padding: 24px;
        border-radius: 15px;
        background-color: #18244E;
        color: white;
        ul {
            list-style-type: disc;
            padding-left: 30px;
            margin: 0;
        }
    }
    &.white {
        color: white;
        p {font-weight: 600;}
    }
    &.navy {
        h1 {
            color: #18244E;
        }
        p {
            font-weight: 400;
            color: #1A234B;
        }
    }
}

.waterfall-red {
    z-index: 1;
    position: relative;
    align-items: flex-start;
    .flex-item-left {
        margin-top: -40px;
        padding-top: 40px;
        border-radius: 0px 70px 0px 0px;
    }
    .flex-item-right {
        flex-direction: column;
        align-items: center;
        > div {
            padding: 47px 96px 12px 40px;
            background-color: #C04051;
            border-radius: 0 0 0 70px;
            margin-top: -40px;
            position: relative;
            height: 100%;
        }
    }
    .under-img-report {
        position: relative;
        text-align: center;
        margin: 24px 0 0;
        width: 75%;
        color: #1A234B;
    }
    .corner-arch {
        position: absolute;
        top: 0;
        right: -1px;
    }
}

.waterfall-navy {
    z-index: 2;
    position: relative;
    padding-bottom: 99px;
    .flex-item-left {
        margin-top: -40px;
        padding-top: 40px;
        border-radius: 0px 70px 0px 0px;
    }
    .flex-item-right {
        padding: 100px 100px 100px 60px;
        background-color: #1A234B;
        border-radius: 0 0 0 70px;
        margin-top: -40px;
        position: relative;
        height: 100%;
    }
    .under-img-report {
        position: absolute;
        text-align: center;
        margin: 0;
        bottom: -100px;
        width: 75%;
        color: #1A234B;
    }
    .corner-arch {
        position: absolute;
        top: 0;
        right: -1px;
    }
}

.container-sheeren {
    margin-top: 40px;
    h2, p {
        color: #1A234B;
    }
}

.sec-device {
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #57BFC9;
        margin: 0 0 24px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0 0 40px;
    }
}

.navy-container-text {
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #57BFC9;
        margin: 0 0 24px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
    }
}

.navy-box-hanging {
    padding: 50px 72px 50px 0;
    border-radius: 0 32px 32px 0;
    background-color: #1A234B;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #57BFC9;
        &.tech {
            color: #FFF;
        }
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
    &.force-aqua {
        background-color: #43969D;
        h1 {
            color: #FFF !important;
        }
        .btn-primary {
            background: #1A234B;
        }
    }
}

.solution-top-box-hanging {
    padding: 50px 72px 50px 0;
    border-radius: 0 32px 32px 0;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #FFFFFF;
    }
    span {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
    &.aqua {
        background: #43969D;
        .btn-primary {
            background: #1A234B;
            // background: #C04051;
        }
    }
    &.red {
        background: #C04051;
        .btn-primary {
            background: #1A234B;
        }
    }
    &.navy {
        background: #1A234B;
        .btn-primary {
            background: #C04051;
        }
    }
    &.purple {
        background: #32059D;
        .btn-primary {
            background: #C04051;
        }
    }
}

.solution-back-top {
    position: absolute;
    top: 160px;
    left: 0;
    // transform: translateY(-50%);
    width: 38%;
    z-index: 1;
    &.aqua {
        background: #43969D;
        height: 507px;
    }
    &.red {
        background: #C04051;
        height: 554px;
    }
    &.navy {
        background: #1A234B;
        height: 410px;
    }
    &.purple {
        background: #32059D;
        height: 455px;
        top: 188px;
    }
}

.img-solution-aqua {
    position: absolute;
    bottom: 0;
    right: -72px;
}

.img-solution-navy {
    position: absolute;
    bottom: 0;
    right: -30px;
}

.navy-box-hanging-right {
    padding: 50px 0 50px 72px;
    border-radius: 32px 0 0 32px;
    background-color: #1A234B;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #57BFC9;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
}

.navy-box-hanging-right-sol {
    padding: 50px 0 50px 72px;
    border-radius: 32px 0 0 32px;
    background-color: #1A234B;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #FFF;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
    &.force-aqua {
        background-color: #43969D;
        .btn-primary {
            background: #1A234B;
        }
    }
}

.red-box-hanging-right {
    padding: 64px 0 64px 64px;
    border-radius: 32px 0 0 32px;
    background-color: #C04051;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: white;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #1A234B;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
}

.red-box-hanging-left {
    padding: 64px 64px 64px 0;
    border-radius: 0 32px 32px 0;
    background-color: #C04051;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: white;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #1A234B;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
}

.purple-box-hanging-right {
    padding: 64px 0 64px 64px;
    border-radius: 32px 0 0 32px;
    background-color: #32059D;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: white;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
    img {
        margin-top: 16px;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
        margin-top: 32px;
    }
}

.red-box-hanging {
    padding: 50px 72px 50px 0;
    border-radius: 32px 0 0 32px ;
    background-color: #C04051;
    z-index: 2;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        color: #57BFC9;
    }
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: white;
        margin: 0;
        display: block;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: white;
        margin: 0;
    }
}

.with-container {
    padding-right: 50px;
    width: 100%;
    img {
        position: absolute;
        top: 40px;
        left: 0;
        max-width: 560px;
    }
}

.active-slider{
    display: block!important;
}
/* Fading animation */
.fadeIn {
    animation: fadeIn 300ms; 
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.container-fireworks {
    > .flex-container {
        min-height: 731px;
    }
}

.review-slider {
    width: 75%;
    margin: 0 auto;
    position: relative;
    display: none;
    p {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
        color: #1A234B;
        margin: 0 0 32px;
    }
    .source-div {
        width: 75%;
        margin: 0 auto;
        &.telkom {
            width: 80%;
        }
        .person-img {
            margin-right: 24px;
        }
        h5, h6 {
            text-align: left;
        }
        h5 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            color: #30479C;
            margin: 0 0 4px;
        }
        h6 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #1A234B;
            margin: 0;
        }
    }
    &.solution {
        display: block;
    }
}

.btn-slider-big {
    border-radius: 50%;
    border: 0;
    width: 50px;
    height: 50px;
    margin: 0 6px;
    opacity: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 9;
    &.navy {
        background: #18244E;
        color: #fff;
    }
    &.white {
        background: white;
        color: #B5394A;
    }
    .fas {
        font-size: 32px;
    }
    &.left {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &.right {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.btn-container-mobile {
    justify-content: center;
    margin-top: 24px;
    display: flex;
    z-index: 1;
    .btn-slider-big {
        position: relative;
        top: unset;
        right: unset;
        transform: none;
    }
}

.step-container {
    display: flex;
    h1 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
        text-align: left;
        margin: 0 0 16px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        margin: 0 0 32px;
    }
    > div:first-of-type {
        width: 30%;
    }
    > div:last-of-type {
        width: 70%;
    }
    .btn-primary {
        border-radius: 6px;
        background: #C04051;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        text-decoration: none;
        padding: 14px 32px;
    }
    b {
        color: #564FDB;
    }
}

.div-integration {
    margin: 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(24, 36, 78, 0.1);
        border-radius: 6px;
        width: 120px;
        height: 120px;
        margin-bottom: 16px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    div:nth-child(4),
    div:nth-child(5),
    div:nth-child(6){
        margin: 0;
    }
    img {
        width: 100%;
        vertical-align: middle;
    }
}

@keyframes shad-anim{
    0% {box-shadow: 10px 10px 30px rgba(0, 0, 0, .2); transform: translateY(10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
    50% {box-shadow: 30px 30px 50px rgba(0, 0, 0, .1); transform: translateY(-10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
    100% {box-shadow: 10px 10px 30px rgba(0, 0, 0, .2); transform: translateY(10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
}

@-webkit-keyframes shad-anim{
    0% {-webkit-box-shadow: 10px 10px 30px rgba(0, 0, 0, .2); -webkit-transform: translateY(10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
    50% {-webkit-box-shadow: 30px 30px 50px rgba(0, 0, 0, .1); -webkit-transform: translateY(-10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
    100% {-webkit-box-shadow: 10px 10px 30px rgba(0, 0, 0, .2); -webkit-transform: translateY(10px) perspective(50em) rotateX(55deg) rotateY(0) rotate(45deg);}
}

.section-review {
    padding: 40px 106px 40px 135px;
    .top-review {
        text-align: center;
        margin-top: 60px;
        label, p {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: #18244E;
        }
        label {
            background: rgba(219, 51, 77, 0.3);
            padding: 0 70px;
            border-radius: 100px;
        }
    }

    .slider-container{
        position: relative;
        overflow: hidden;
    }
    .company-review {
        margin-top: 100px;
        opacity: 0;
        .review-text {
            margin-left: 40px;
            img {
                width: 100px;
            }
            .top-review-text {
                width: 100%;
                height: 51px;
            }
            .middle-review-text {
                margin-top: 13px;
                img {
                    width: 18px;
                }
                label {
                    margin-top: 13px;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: #18244E;
                }
                p {
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #494CA1;
                }
            }
        }
        &.active-anim {
            opacity: 1 !important;
            
          }
        &.absolute{
            position: absolute;
        }
    }
    
    .no-transition{
        transition: none !important;
    }

    .slider-item-container{
        opacity: 0;
        visibility: hidden;
        > img {
            margin-left: 16px;
        }
        &.active{
            opacity: 1; 
            visibility: visible;
        }
        &.absolute{
            position: absolute;
        }
    }

    .slider-item{
        opacity: 0;
        visibility: hidden;
        transition: opacity ease-in-out 0.7s;
        &.active-anim{
            opacity: 1 !important;
            visibility: visible;
            transition: opacity ease-in-out 0.7s;
        }
        &.absolute{
            position: absolute;
        }
    }

    .transition-card{
        transition: opacity ease-in-out 0.7s;
    }

    .candidate-hr {
        margin-top: 64px;
        position: relative;
        label {
            margin: 0;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #103EE5;
            padding: 8px 24px;
            background: #ECF0FE;
            border: 1px solid #4B6FF2;
            border-radius: 40px;
            position: absolute;
            left: 0;
            top: -18px;
        }
        hr {
            border-top: 1px solid #D9E1FC;
        }
    }
    .app-review {
        margin-top: 45px;
        margin-left: -16px;
        .app-text-review {
            width: 50%;
            margin-top: 13px;
            .fa-chevron-left {cursor: pointer;}
            .fa-chevron-right {cursor: pointer;}
            button {
                border: 0;
                background: transparent;
                width: 10px;
                padding: 0;
            }
            p {
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #18244E;
                margin: 0 16px;
            }
            label {
                display: block;
                margin: 16px 0;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #494CA1;
            }
            .bottom-dots {
                text-align: center;
                div {
                    display: inline-block;
                    cursor: pointer;
                    width: 8px;
                    height: 8px;
                    border: 0;
                    background: #18244E;
                    border-radius: 8px;
                    margin: 0 2px;

                    &.disabled {
                        background: #CFCFCF;
                    }
                }
            }
        }
        .img-appstore {
            margin-right: 46px;
        }
    }
}

.bottom-dots {
    align-self: flex-end;
    button {
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 0;
        background: #18244E;
        border-radius: 14px;
        &.disabled {
            background: #CFCFCF;
        }
    }
    button:first-of-type {margin-right: 8px;}
    button:last-of-type {margin-left: 8px;}
}

.arrow-slider {
    button{
        background: #18244E;
        color: #FFF;
        border-radius: 50%;
        border: 0;
        width: 36px;
        height: 36px;
        margin: 0 6px;
        i {
            font-size: 18px;
            line-height: 24px;
        }
        &.disabled {
            opacity: 0.3;
        }
    }
}

ul.the-insight{
    overflow: auto;
    margin: -15px -15px -25px -15px;
    padding: 0 0 20px 0;
}

ul.the-insight > li{
    float: left;
    padding: 15px;
    width: 50%;
    box-sizing: border-box;
}

ul.the-insight > li a{
    display: block;
    background: #FFF;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .1);
}

ul.the-insight > li a img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

ul.the-insight > li a ul.ins-con{
    margin: -5px;
    padding: 20px;
}

ul.the-insight > li a ul.ins-con > li{
    padding: 5px;
    color: #666;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.the-insight > li a ul.ins-con > li:nth-child(1){
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
}

ul.the-insight > li a:hover ul.ins-con > li:nth-child(1){
    color: #564FDB;
}

ul.the-insight > li a ul.ins-con > li:nth-child(2){
    color: #999;
}

ul.the-insight > li a ul.ins-con > li:nth-child(3){
    font-size: 14px;
    line-height: 16px;
}

.sec-insight{
    padding: 60px 20px;
}

.januar-li ul {
    flex-wrap: wrap;
    display: flex;
}
.januar-li ul li {
    flex: 1 0 25%;
}

.januar-li .on-top img {
    max-width: 300px;
}

.januar-div1 {
    display: flex;
    padding: 121px 0 0;
}

.januar-div2 {
    width: 50%;
    img {
        width: 100%;
    }
    label {
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        color: #18244E;
        max-width: 520px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #18244E;
        max-width: 520px;
    }
}

.experience-review {
    text-align: center;
    label {
        display: block;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        color: #18244E;
    }
    span {
        display: block;
        margin-top: 26px;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #18244E;
    }
    .rate {
        margin: 70px 0 25px;
        img {
            margin: 0 20px;
        }
    }
    .review-text-app {
        margin-top: 24px;
        opacity: 0;
        transition: opacity ease-in-out 0.7s;
        > div{
            background: #D9E1FC;
            box-shadow: 0px 4px 10px rgba(167, 207, 255, 0.1);
            border-radius: 10px;
            padding: 31px 24px;
            text-align: left;
            height: 420px;
            width: 365px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #18244E;
            text-align: left;
            margin: 24px 0;
        }
        label {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: #18244E;
            margin: 0;
            img {
                margin-left: 10px;
                margin-top: -5px;
            }
        }

        &.active-anim {
            opacity: 1 !important;
            transition: opacity ease-in-out 0.7s;
            
          }
        &.absolute{
            position: absolute;
            visibility: hidden;
        }
    }
    .bottom-dots {
        margin-top: 40px;
        button {margin: 0 4px;}
    }
    .button-read-review {
        text-align: center;
        margin-top: 40px;
        a {
            width: auto;
            margin: 0 8px;
            display: inline-block;
            padding: 14px 32px;
            background: transparent;
            border: 2px solid #18244E;
            border-radius: 6px;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: #18244E;
            text-decoration: unset;
        }
    }
}

.flow-container {
    label {
        display: block;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        text-align: center;
        color: #18244E;
        margin-bottom: 26px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #18244E;
    }
}

.section-top-features {
    text-align: center;
    padding-bottom: 0;
    label {
        display: block;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        color: #18244E;
        margin: 64px 0 48px;
    }
}

.section-all-features {
    padding: 80px 0 40px;
    text-align: center;
    ul {
        li {
            width: 33.33%;
            border-top: 2px solid;
            margin: 0 25px 50px;
            min-height: 480px;
            .on-top {
                padding: 24px 0px 0;
                display: flex;
                flex-flow: column;
                align-items: center;
                flex-grow: 1;
                flex: 1;
                justify-content: space-between;
                height: 100%;
                label {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #18244E;
                    margin-bottom: 16px;
                }
                p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #18244E;
                }
            }
        }
        li:nth-child(1) {border-top-color: #4B6FF2;}
        li:nth-child(2) {border-top-color: #F5BC48;}
        li:nth-child(3) {border-top-color: #58D6A8;}
        li:nth-child(4) {border-top-color: #DB334D;}
        li:nth-child(5) {border-top-color: #564FDB;}
        li:nth-child(6) {border-top-color: #1D90D7;}
        li:nth-child(7) {border-top-color: #F5BC48;}
        li:nth-child(8) {border-top-color: #4B6FF2;}
        li:nth-child(9) {border-top-color: #58D6A8;}
        li:nth-child(10) {border-top-color: #1D90D7;}
        li:nth-child(11) {border-top-color: #DB334D;}
        li:nth-child(12) {border-top-color: #564FDB;}
        li:nth-child(13) {border-top-color: #58D6A8;}
        li:nth-child(14) {border-top-color: #8219C8;}
        li:nth-child(15) {border-top-color: #F5BC48;}
    }
}

.rc-duration, .rc-break-time {
    &.rc-time-picker {
        display: unset;
        border-radius: 4px;
    }
    .rc-time-picker-input {
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        height: 34px;
    }
}

.rc-max-time {
    &.rc-time-picker {
        display: unset;
        border-radius: 4px;
    }
    .rc-time-picker-input {
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        height: 36px;
        border: 1px solid #828282 !important;
        border-left: 0 !important;
        border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
}

.rc-date-custom {
    width: 100%;
    .rc-time-picker-input {
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
}

.rc-time-picker-panel-select {
    width: 56px;
}

.img-interview-vinsen {
    top: 40px !important;
    max-width: 90% !important;
}

// start responsive homepage from 768 to 1278 width

@media screen and (min-width: 768px) and (max-width: 1278px){
    .red-back-feature,
    .navy-back-feature-left,
    .navy-back-feature-right,
    .purple-back-feature-right,
    .red-back-feature-right.for-1,
    .solution-back-top {
        display: none !important;
    }
    .waterfall-red .flex-item-right {
        margin-right: -20px;
    }
    .container-fireworks .flex-item-left {
        .navy-box-hanging {
            margin-left: -20px;
            padding-left: 20px;
        }
    }
    //box hanging left
    .navy-box-hanging.force-aqua,
    .navy-box-hanging,
    .red-box-hanging-left,
    .solution-top-box-hanging {
        margin-left: -20px;
        padding-left: 20px;
    }
    //box hanging right
    .navy-box-hanging-right-sol,
    .red-box-hanging-right,
    .purple-box-hanging-right {
        margin-right: -20px;
        padding-right: 20px;
    }

    // img absolute solution top section
    .img-solution-navy,
    .img-solution-aqua {
        position: relative;
        bottom: unset;
        right: unset;
    }

    .dix-text-sheeren {
        padding: 0 16px;
    }
    .with-container {
        img {
            max-width: 100%;
        }
    }
}

@media screen and (min-width: 923px) and (max-width: 1237px) and (orientation: landscape){
    .ban-text a.vid-msg{
        width: 50% !important;
    }
    .ban-text .ban-link a {
        margin: 12px 12px 0 0 !important;
    }
}

@media screen and (min-width: 786px) and (max-width: 923px){
    .ban-text a.vid-msg{
        width: 50% !important;
    }
    .ban-text .ban-link a {
        margin: 12px 12px 0 0 !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 786px) and (orientation: landscape){
    .ban-text a.vid-msg{
        width: 50% !important;
    }
    .ban-text .ban-link a {
        margin: 12px 12px 0 0 !important;
    }
}

@media screen and (min-width: 786px) and (max-width: 1024px) and (orientation: portrait){
    .ban-text a.vid-msg{
        width: 100% !important;
    }
}
@media screen and (max-width: 1180px){
    .review-slider {
        .source-div {
            > div {
                justify-content: center;
                flex-grow: 1;
                .person-img {
                    margin: 0 0 24px;
                }
                h5, h6 {text-align: left;margin-left: 8px;}
                .logo-review {
                    margin-top: 40px;
                }
            }
        }
    }
}

// end responsive homepage from 768 to 1278 width

@media screen and (min-width: 768px) and (max-width: 1024px){
    .pop{
        width: 75%;
    }
    
    .sec-con{
        padding: 200px 20px;
    }
    
    .sec-con ul li ul{
        margin: -200px -10px -200px -10px;
    }
    
    .sec-con ul li ul li{
        font-size: 20px;
        line-height: 26px;
    }
    
    .sec-con ul li ul li span{
        font-size: 14px;
        line-height: 20px;
    }
    
    .sec-con ul li:nth-child(2) ul li img{
        height: 230px;
    }
    
    .sec-con ul li ul li .square-pers{
        width: 270px;
        height: 270px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    // .ban-text{
    //     padding: 190px 40% 240px 40px;
    // }
    
    .partner{
        margin: -89px auto 0 auto;
    }
    
    .partner ul{
        margin: -10px;
    }
    
    .partner ul li,
    .partner ul li:nth-child(1),
    .partner ul li:nth-child(2),
    .partner ul li:nth-child(3),
    .partner ul li:nth-child(4),
    .partner ul li:nth-child(5){
        width: 9.09%;
        padding: 10px;
    }
    
    .and-many{
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .ban-text{
        padding: 190px 40px 240px 40px;
        text-align: center;
    }
    
    .ban-text a.vid-msg {
        right: 0;
        position: relative;
        max-width: none;
        padding: 50px 0 0 0;
        transform: translate(0, 6%);
    }
    
    .sec-con{
        padding: 230px 20px 150px 20px;
    }
    
    .sec-con ul li ul{
        margin: -230px -10px -230px -10px;
    }
    
    .sec-con ul li ul.on-left li:first-child{
        width: 55%;
    }

    .sec-con ul li ul.on-left li:last-child{
        width: 45%;
    }

    .sec-con ul li ul.on-right li:first-child{
        width: 55%;
    }

    .sec-con ul li ul.on-right li:last-child{
        width: 45%;
    }
    
    ul.the-insight > li a img{
        height: 200px;
    }
    .review-slider {
        .source-div {
            > div {
                justify-content: center;
                flex-grow: 1;
                .person-img {
                    margin: 0 0 24px;
                }
                h5, h6 {text-align: center;margin-left: 8px;}
                .logo-review {
                    margin-top: 40px;
                }
            }
        }
    }
    .waterfall-navy {
        padding: 0;
        .flex-item-left {
            margin-top: 0;
            border-radius: 32px 32px 0 32px;
        }
        .flex-item-right {
            padding: 40px 16px;
            border-radius: 0 32px 32px 0;
            margin-right: 16px;
            margin-bottom: 40px;
        }
        .with-container {
            height: 360px;
            padding-right: 0;
            img {
                max-width: 90%;
                max-height: 75%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .corner-arch {
            right: 0;
            width: 32px;
            height: 32px;
        }
        .navy-container-text {
            h1 {
                margin-top: 0;
                margin-bottom: 12px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .navy-back-feature {
        display: none !important;
    }
}

@media screen and (max-width: 767px){
    .banner.home > section {
        padding: 24px 20px 40px;
    }
    .sec-con ul li ul.on-right.feature-list li > img{
        left: unset;
        max-width: 100%;
    }

    .app-review {
        margin-left: 0 !important;
    }

    .sec-con ul li ul.colab {
        margin: 0 !important;
    }

    .p-l-45-desk {
        padding-left: 0;
    }

    .p-r-45-desk {
        padding-right: 0;
    }

    .januar-div1 {
        flex-flow: column;
    }

    .januar-div2 {
        width: 100%;
        img {
            width: 100%;
            display: block;
        }
        label {
            font-size: 32px;
            line-height: 48px;
            margin: 24px 0 12px;
        }
        p {
            font-size: 14px;
            line-height: 21px;
            margin: 0 0 40px;
        }
        &:last-child {
            margin: 0 -16px;
            float: unset !important;
            position: relative;
            width: auto;
        }
    }

    .for-tag {
        text-transform: capitalize;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        background: #FDF1D8;
        padding: 6px 19px;
        border-radius: 40px;
        margin-bottom: 16px;
    }

    .experience-review {
        text-align: center;
        label {
            font-size: 24px;
            line-height: 36px;
        }
        span {
            margin-top: 8px;
            font-size: 14px;
            line-height: 21px;
        }
        .rate {
            margin: 40px 0 32px;
            img {
                margin: 0;
                max-width: 100%;
            }
            a {
                max-width: 50%;
            }
        }
        .arrow-slider {
            button{
                width: 24px;
                height: 24px;
                i {font-size: 14px;}
            }
        }
        .review-text-app {
            > div {
                height: auto;
                width: 100%;
                img {
                    width: 12px;
                }
                p{
                    font-size: 14px;
                    line-height: 21px;
                    margin: 11px 0 16px;
                }
                label {
                    font-size: 14px;
                    line-height: 21px;
                    img {width: auto;}
                }
            }
        }
        .button-read-review {
            a{
                padding: 12px 0;
                margin: 0 0 16px;
                font-size: 16px;
                line-height: 24px;
                width: 100%;
            }
        }
    }

    .flow-container {
        label {
            font-size: 24px;
            line-height: 36px;
            margin: 32px 0 12px;
        }
        p{
            font-size: 14px;
            line-height: 21px;
        }
        .flow-faq {
            padding: 0 0 40px !important;
            > li {
                padding: 0 !important;
            }
            .dropdown-new {
                font-size: 18px;
                line-height: 27px;
                padding: 24px 28px 24px 16px;
                margin: 0px 0 0;
                text-align: left;
                i {
                    right: 14px;
                    top: 32px;
                    font-size: 14px;
                }
            }
            > li ul {
                padding: 0 16px 16px !important;
                > li {
                    font-size: 16px;
                    line-height: 24px;
                    span {
                        font-size: 14px;
                        line-height: 21px;
                    }
                    &:last-child {
                        padding: 8px 0 !important;
                        span, a {
                            font-size: 12px;
                            line-height: 18px;
                        }
                        i {
                            font-size: 10px;
                        }
                    }
                }
            }
            .button-test {
                font-size: 16px !important;
                line-height: 24px !important;
                width: 100%;
                padding: 12px 0;
            }
        }
        ul > li > ul > li {
            padding: 0 !important;
        }
        .mobile-text-force {
            font-size: 14px !important;
            line-height: 21px !important;
        }
    }

    .partner ul{
        margin: 0;
    }

    .ban-text .partnership-desc{
        height: auto;
        width: 100%;
        font-size: 14px;
        line-height: 21px;
    }

    .partner-page-sec {
        margin: -60px auto 0 auto !important;
        .big-letter{
            font-size: 24px !important;
            line-height: 36px !important;
            margin: 0 !important;
        }
    }
    
    .sec-con ul li ul li > img.img-card-right,
    .sec-con ul li ul li > img.img-card-left {
        top: unset;
        left: unset;
        max-width: 100%;
    }

    .sec-con ul li ul.card-partner {
        margin: 0;
        li {
            font-size: 18px;
            line-height: 27px;
            span {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    .partner-no-margin{
        position: relative;
        padding: 40px 16px;
        overflow: hidden;
        p {
            font-size: 14px;
            line-height: 21px;
            &.big-letter{
                font-size: 24px;
                line-height: 36px;
            }
        }
    }

    .partnership-container {
        margin-top: 40px !important;
        .box-reg {
            padding: 20px 14px;
        }
    }

    .section-top-features {
        padding: 24px 16px 0;
        label {
            margin: 0 0 30px;
            font-size: 32px;
            line-height: 48px;
        }
        img {
            max-width: 100%;
        }
    }

    .section-all-features {
        padding: 32px 16px;
        text-align: center;
        ul {
            li {
                width: 100%;
                margin: 0 0 24px;
                .text {
                    p {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }
    }

    .demonstration-margin {
        padding: 24px 16px 36px;
        .demo-title-container {
            width: 100%;
            p {
                width: 100%;
                font-size: 14px;
                line-height: 21px;
            }
            .big-letter {
                font-size: 32px;
                line-height: 48px;
            }
        }
    }
    .box-reg.partnership-form.demo {
        padding: 22px 14px !important;
    }
    
    .partner ul li,
    .partner ul li:nth-child(1),
    .partner ul li:nth-child(2),
    .partner ul li:nth-child(3),
    .partner ul li:nth-child(4),
    .partner ul li:nth-child(5){
        padding: 10px;
    }
    
    .and-many{
        font-size: 16px;
        line-height: 18px;
    }
    
    .sec-con ul li ul li{
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
    }

    .sec-con.for-astrnt ul li ul li{
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }
    
    .sec-con ul li ul li span{
        font-size: 14px;
        line-height: 21px;
    }

    .sec-con ul li ul li a {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }

    .ban-text .ban-link a{
        display: inline-block;
        font-weight: bold;
        color: #DB334D;
        vertical-align: top;
        margin-top: 12px;
    }

    .ban-text .ban-link > div{
        display: inline-block;
        width: 100%;
    }

    .ban-text .ban-link a.try{
        margin: 0;
    }
    
    .pop{
        width: auto;
        border-radius: 0;
    }
    
    .pop .pop-head{
        font-size: 18px;
        line-height: 24px;
    }
    
    .ban-text a.vid-msg{
        right: 0;
        position: relative;
        max-width: none;
        padding: 50px 0 0 0;
        transform: translate(0, 6%);
    }

    .div-button-feature{
        display: block;
        margin: 0 !important;
    }

    .feature-list {
        .div-button-feature:nth-of-type(2) {
            margin-top: 36px !important;
        }
    }
    
    .button-feature{
        width: 100% !important;
        margin-bottom: 16px;
        padding: 12px 0 !important;
    }

    .button-case {
        width: 100% !important;
    }

    .red-back-feature {
        height: 180px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: unset;
        width: 100%;
    }

    .section-review {
        padding: 1px 20px 40px;
        .top-review {
            label, p {
                font-size: 24px;
                line-height: 36px;
            }
            label {
                padding: 7px 0;
                width: 100%;
            }
            p{
                margin-top: 8px;
            }
        }
        .middle-review-text {
            display: inline-block;
            width: 60%;
            margin-top: 0 !important;
            label {
                font-size: 16px !important;
                line-height: 24px !important;
                margin-top: 0 !important;
            }
            p {
                font-size: 12px !important;
                line-height: 18px !important;
                margin-bottom: 0;
                margin-top: 3px !important;
            }
            img {
                width: 9px !important;
                vertical-align: top;
            }
        }
        .company-review {
            margin-top: 32px;
            flex-flow: column;
            .review-text {
                margin: 0;
                .top-review-text {
                    .arrow-slider {
                        button{
                            width: 24px;
                            height: 24px;
                            i {font-size: 14px;}
                        }
                    }
                }
            }
            .image-review-container-mobile {
                margin: 0;
                width: 35%;
                img{
                    width: 100%;
                }
            }
            .bottom-dots {
                align-self: flex-start;
                margin-top: 16px;
            }
        }
        .candidate-hr label {
            font-size: 12px;
        }
        .slider-item-container {
            > div {
                .app-text-review {
                    width: 100%;
                    p{
                        font-size: 14px;
                        line-height: 21px;
                    }
                    label{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
            > img {
                width: 12px;
                margin-left: 24px;
            }
        }
        .store-container {
            width: 100%;
            margin-top: 40px;
        }
    }
    .div-integration {
        margin: 0;
        justify-content: space-around;
        > div {
            width: 100px;
            height: 100px;
        }
    }
    .case-icon {
        position: relative !important;
        height: auto !important;
        margin-top: 0px !important;
        max-width: 85% !important;
        top: 0 !important;
    }

    .ban-text.for{}
    .background-color {
        top: unset;
        bottom: 10%;
        right: unset;
        margin-left: -30px;
        width: 120%;
        height: 350px;
    }

    .ban-text a.vid-msg{
        &.partner-msg{
            right: unset !important;
            margin: 0 -20px;
            padding: 40px 0 0 0;
            transform: translate(0, -2%);
            img {
                width: 100%;
            }
        }
    }
    .ban-text{
        &.partnership-ban{
            padding-bottom: 10px !important;
        }
    }
    .partner-page-sec {
        padding: 50px 16px 0 !important;
    }
    .img-interview-vinsen {
        top: 0 !important;
        max-width: 100% !important;
    }

    // new homepage resp

    .waterfall-navy {
        padding: 0;
        .flex-item-left {
            margin-top: 0;
            border-radius: 32px 32px 0 32px;
        }
        .flex-item-right {
            padding: 40px 16px;
            border-radius: 0 32px 32px 0;
            margin-right: 16px;
            margin-bottom: 40px;
        }
        .with-container {
            height: 360px;
            padding-right: 0;
            img {
                max-width: 90%;
                max-height: 75%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .corner-arch {
            right: 0;
            width: 32px;
            height: 32px;
        }
        .navy-container-text {
            h1 {
                margin-top: 0;
                margin-bottom: 12px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .sec-desc {
        h1 {
            font-size: 32px;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        .desc-check-yellow span {
            font-size: 16px;
            line-height: 24px;
        }
        .list-navy {
            ul {
                padding-left: 15px;
            }
        }
    }
    .waterfall-red {
        .corner-arch {
            right: unset;
            left: -20px;
            top: -1px;
            transform: scaleX(-1);
            width: 32px;
            height: 32px;
        }
        .flex-item-right {
            > div {
                margin: 40px -20px 0;
                padding: 16px 40px 5px;
                border-radius: 32px 0 0 0;
            }
        }
        .under-img-report {
            // bottom: -100px;
            font-size: 14px;
            line-height: 21px;
            width: 90%;
        }
    }
    .container-sheeren {
        img {
            margin-bottom: 32px;
        }
        p {
            margin-bottom: 24px;
        }
        h2 {
            margin: 24px 0 4px;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .section-darkblue {
        > section {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
    .p-t-80, .p-t-100 {
        padding-top: 40px !important;
    }
    .p-b-80, .p-b-100 {
        padding-bottom: 40px !important;
    }
    .sec-device {
        h1 {
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 12px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }
    .flip-flex-mobile {
        flex-direction: column-reverse;
    }
    .navy-box-hanging {
        padding: 24px 24px 24px 16px;
        border-radius: 0 16px 16px 0;
        // background-color: #1A234B;
        margin-left: -20px;
        margin-top: 24px;
        z-index: 2;
        h1 {
            font-size: 32px;
            line-height: 48px;
        }
        span, p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .navy-box-hanging-right {
        padding: 24px 16px 24px 24px;
        border-radius: 16px 0 0 16px;
        // background-color: #1A234B;
        margin-right: -20px;
        margin-top: 24px;
        z-index: 2;
        h1 {
            font-size: 32px;
            line-height: 48px;
        }
        span, p {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .review-slider {
        width: 100%;
        p {
            font-size: 18px;
            line-height: 27px;
        }
        .source-div {
            width: 100%;
            > div {
                justify-content: center;
                flex-grow: 1;
                .person-img {
                    margin: 0 0 24px;
                }
                h5, h6 {text-align: center;margin-left: 0;}
                .logo-review {
                    margin-top: 40px;
                }
            }
        }
    }
    .navy-container-text {
        h1 {
            font-size: 32px;
            line-height: 48px;
            margin-top: 24px;
        }
        .btn-primary {
            width: 100%;
            text-align: center;
        }
    }
    .step-container {
        position: relative;
        z-index: 1;
        flex-direction: column;
        > div {
            width: 100% !important;
        }
        h1 {
            font-size: 32px;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        .btn-primary {
            width: 100%;
            text-align: center;
        }
        img {
            margin-top: 40px;
        }
    }
    .img-solution-aqua, .img-solution-navy {
        position: relative;
        bottom: unset;
        right: unset;
    }
    .solution-top-box-hanging {
        padding: 24px 16px;
        margin-left: -20px;
        h1 {
            font-size: 32px;
            line-height: 48px;
        }
        span {
            font-size: 18px;
            line-height: 27px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        .btn-primary {
            padding: 12px 16px;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
    }
    .sol-sec1-mobile {
        .flex-item-left {
            margin-bottom: 28px;
        }
        .red-box-hanging-right, .purple-box-hanging-right, .navy-box-hanging-right-sol {
            padding: 24px 16px;
            margin-right: -20px;
            h1 {
                font-size: 32px;
                line-height: 48px;
            }
            span {
                font-size: 18px;
                line-height: 27px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
            .btn-primary {
                padding: 12px 16px;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-top: 16px;
            }
        }
    }
    .sol-sec2-mobile {
        > .flex-container {
            flex-direction: column-reverse;
        }
        .flex-item-left {
            margin-bottom: 28px;
        }
        .navy-box-hanging, .red-box-hanging-left {
            padding: 24px 16px;
            margin-left: -20px;
            h1 {
                font-size: 32px;
                line-height: 48px;
            }
            span {
                font-size: 18px;
                line-height: 27px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
            .btn-primary {
                padding: 12px 16px;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-top: 16px;
            }
        }
    }
    .section-top-solution {
        .flex-container {
            .flex-item-right {
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){
    .pop{
        width: auto;
        margin: 0 20%;
        border-radius: 20px;
    }
    
    .ban-text{
        padding: 90px 20px 140px 20px;
        text-align: left;
    }
    
    .and-many:before,
    .and-many:after{
        width: 30%;
    }
    
    .sec-con{
        padding: 230px 20px 150px 20px;
    }
    
    .sec-con ul li ul{
        margin: -230px -10px -230px -10px;
    }
    
    .sec-con ul li ul.on-left li:first-child{
        width: 55%;
    }

    .sec-con ul li ul.on-left li:last-child{
        width: 45%;
    }

    .sec-con ul li ul.on-right li:first-child{
        width: 55%;
    }

    .sec-con ul li ul.on-right li:last-child{
        width: 45%;
    }
    
    .sec-con ul li ul li img{
        height: 200px;
    }
    
    .sec-con ul li:nth-child(2) ul li img{
        height: 230px;
    }
    
    .sec-con ul li ul li .square-pers{
        width: 270px;
        height: 270px;
    }
    
    ul.the-insight > li a img{
        height: 150px;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text .ban-link a.request-demo-logged{
        margin-top: 12px;
    }
    ul {margin: 0;}
    .sec-light-purple {
        .ban-text {
            padding-bottom: 0 !important;
        }
    }
    .ban-text{
        padding: 0 0 155px 0 !important;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
    }
    .ban-text.for{
        min-height: unset;
        max-height: unset;
    }
    
    .ban-text .big-text{
        font-size: 32px;
        line-height: 48px;
    }
    
    .ban-text .ban-link a,
    .ban-text .ban-link a.try{
        display: block;
        width: 100%;
    }

    .ban-text .ban-link a{
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-top: 28px;
    }
    
    .ban-text .ban-link a.try{
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }

    .span-cc {
        display: block;
        margin-top: 0;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }
    
    .partner ul li,
    .partner ul li:nth-child(1),
    .partner ul li:nth-child(2),
    .partner ul li:nth-child(3),
    .partner ul li:nth-child(4),
    .partner ul li:nth-child(5){
        width: 50%;
    }

    .partner{
        position: relative;
        padding: 32px 16px 0;
        overflow: visible;
        margin: -140px auto 0 auto;
        z-index: 2;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #18244E;
            &.big-letter{
                display: block;
                font-size: 48px;
                line-height: 72px;
                font-weight: bold;
            }
        }
    }
    
    .and-many:before,
    .and-many:after{
        width: 15%;
    }
    
    .sec-con{
        padding: 0 16px;
    }
    
    .sec-con ul li{
/*        padding: 20px 0;*/
    }
    
    .sec-con ul li ul{
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }

    .sec-con ul li ul.univ-last {
        padding: 0 !important;
    }
    
    .sec-con ul li ul li img{
        height: auto;
        max-width: 100%;
        position: relative;
        top: 0;
    }

    .sec-con ul li ul li label{
        font-size: 12px;
        line-height: 18px;
    }
    
    .sec-con ul li:nth-child(2) ul li img{
        height: 180px;
    }
    
    .sec-con ul li ul li .square-pers{
        width: 220px;
        height: 220px;
    }
    
    .sec-con ul li ul.on-left li:first-child,
    .sec-con ul li ul.on-left li:last-child,
    .sec-con ul li ul.on-right li:first-child,
    .sec-con ul li ul.on-right li:last-child{
        width: 100%;
        text-align: left;
        padding: 70px 0 0;
    }

    .sec-con ul li ul.on-left li:first-child,
    .sec-con ul li ul.on-right li:first-child{
        padding: 32px 0 0;
    }

    .sec-con ul li ul.on-left li:last-child,
    .sec-con ul li ul.on-right li:last-child{
        padding: 40px 0 50px;
    }

    .sec-con ul li ul.on-right.feature-list li {
        padding: 70px 0 20px;
    }

    .sec-con ul li ul.on-right.feature-list li:nth-child(2) {
        padding: 20px 0 20px;
    }

    .sec-con ul li ul.on-right.feature-list li img{
        top: 0;
        max-width: 90%;
    }

    // .sec-con ul li ul.on-left li:last-child,
    // .sec-con ul li ul.on-right li:last-child{
    //     padding: 70px 0 100px;
    // }
    
    ul.the-insight > li{
        width: 100%;
    }
    
    ul.the-insight > li a img{
        height: 200px;
    }
}

@media screen and (max-width: 360px){
    .background-color{
        height: 330px;
    }
}

@media screen and (max-width: 320px){
    .background-color{
        height: 300px;
    }
}


/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}
