/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 26, 2019
UPDATE DATE: Oct 26, 2019
REVISION: 1
NAME: astronaut.misc-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#misc {
    background-color: #EFF0F6;
    color: #18244E;
.misc-tit{
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    margin: 0 0 20px 0;
    text-align: center;
}

ul.list-misc{
    list-style: decimal;
    padding: 0 0 0 40px;
    margin: -7.5px 0;
}

ul.list-misc > li{
    padding: 7.5px 0 2.5px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #18244E;
    text-align: justify;
}

.intro{
    margin: 0 0 20px 0;
}

.intro p,
ul.list-misc > li > p{
    margin: 10px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #18244E;
    text-align: justify;
}

ul.list-misc > li > ul{
    color: #18244E;
    list-style: lower-alpha;
    padding: 0 0 0 20px;
    margin: 5px 0;
}

ul.list-misc > li > ul > li{
    padding: 5px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
}

ul.list-misc > li > ul > li > ul{
    padding: 0 0 0 20px;
    list-style: lower-alpha;
    margin: 2.5px 0 -2.5px 0;
}

ul.list-misc > li > ul > li > ul > li{
    padding: 2.5px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
}

ul.list-misc li a{
    font-weight: bold;
    color: #4B6FF2;
}

ul.list-misc li a:hover{
    color: #4B6FF2;
}

.last-update{
    margin: 30px 0 0 0;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){
    .section-misc {
        padding: 24px 16px 40px;
    }
    .sec-con {
        padding: 24px 16px;
    }
    .misc-tit{
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
    }
    ul.list-misc > li{
        font-size: 14px;
        line-height: 21px;
    }

    .intro p,
    ul.list-misc > li > p{
        font-size: 14px;
        line-height: 21px;
    }

    ul.list-misc > li > ul > li{
        font-size: 14px;
        line-height: 21px;
    }

    ul.list-misc > li > ul > li > ul > li{
        font-size: 14px;
        line-height: 21px;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}