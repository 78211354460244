/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Nov 11, 2019
UPDATE DATE: Nov 11, 2019
REVISION: 1
NAME: astronaut.help-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#help {
.banner{
    position: relative;
    overflow: hidden;
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/help.jpg?v=1) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

.ban-text{
    color: #FFF;
    position: relative;
    padding: 140px 0 50px 0;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    z-index: 2;
}

.ban-text .big-text{
    display: block;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
}

.ban-text .big-text em{
    font-weight: bold;
    position: relative;
    font-style: normal;
}

.search-help{
    position: relative;
    width: 55%;
    margin: 20px auto;
}

.search-help input{
    height: 55px;
    font-size: 18px;
    border: none;
    padding: 15px 55px 15px 15px;
    border-radius: 5px;
}

.search-help input:hover{
    box-shadow: 0 0 5px rgba(255, 255, 255, .5);
}

.search-help input:focus{
    outline: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.search-help button{
    position: absolute;
    opacity: .5;
    pointer-events: none;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 35px;
    border: none;
    color: #FFF;
    font-size: 18px;
    text-align: center;
    border-radius: 100px;
    box-shadow: 0 0 0 #564FDB;
    background: rgb(86,79,219);
    background: -moz-linear-gradient(-45deg,  rgba(86,79,219,1) 0%, rgba(132,79,219,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(86,79,219,1) 0%,rgba(132,79,219,1) 100%);
    background: linear-gradient(135deg,  rgba(86,79,219,1) 0%,rgba(132,79,219,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#564fdb', endColorstr='#844fdb',GradientType=1 );
}

.search-help button:hover{
    box-shadow: 0 0 10px #564FDB;
}

.search-help button i{
    
}

ul.list-help{
    margin: -20px 0;
}

ul.list-help > li{
    border-bottom: 1px solid #CCC;
}

ul.list-help > li > a{
    display: block;
    position: relative;
    padding: 20px 0 20px 26px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #666;
}

ul.list-help > li a:hover{
    color: #564FDB;
}

ul.list-help > li a i{
    position: absolute;
    left: 0;
    top: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.list-help > li a.is-on i{
    transform: rotate(180deg);
}

ul.list-help > li div{
    margin: -20px 0 0 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    line-height: 20px;
}

ul.list-help > li div a,
.cant a{
    font-weight: bold;
}

ul.list-help > li div a:hover,
.cant a:hover{
    color: #844fdb;
}


ul.list-help > li div p{
    margin: 10px 0;
}

ul.list-help > li ul{
    list-style: disc;
    margin: 5px 0;
    padding: 0 0 0 20px;
}

ul.list-help > li ul.use-num{
    list-style: decimal;
}

ul.list-help > li ul > li{
    padding: 5px 0;
}

.cant{
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
}

ul.the-other{
    overflow: auto;
    margin: 10px -10px;
}

ul.the-other li{
    float: left;
    width: 38%;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    box-sizing: border-box;
}

ul.the-other li:first-child{
    width: 62%;
}

ul.the-other li img{
    display: block;
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0 0 10px 0;
}

ul.the-other li span{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: block;
    margin: 0 0 8px 0;
}

ul.the-other li a{
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin: 10px 0 0 0;
}

.help-empty{
    padding: 20px 0;
    text-align: center;
    color: #999;
}

.help-empty span{
    display: block;
    margin: 0 0 20px 0;
    color: rgba(86, 79, 219, .25);
    font-size: 70px;
    line-height: 70px;
}

.help-empty span img{
    opacity: .5;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .search-help{
        width: 60%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .search-help{
        width: 80%;
    }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
    .ban-text{
        padding: 90px 0 0 0;
    }
    
    .search-help{
        width: 80%;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text{
        padding: 110px 0 20px 0;
    }
    
    .search-help{
        width: 100%;
    }
    
    ul.the-other{
        margin: 0 -10px;
    }
    
    ul.the-other li,
    ul.the-other li:first-child{
        padding: 20px 10px;
        width: 100%;
        text-align: center;
    }
    
    ul.the-other li img{
        margin: 0 auto 10px auto;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}