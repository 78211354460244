/*
AUTHOR: Ajid Muhamad <ajid@astrnt.co>
CREATE DATE:  06 Mei, 2022
TYPE: Cascading Style Sheet
*/
#how-it-work {
    width: 100%;
    background: #F7F7FC;
    .btn-danger {
        background: #DB334D;
    }
    .top-banner{
        position: relative;
        overflow: hidden;
        min-height: 720px;
        left: 0px;
        top: 0px;
        section {
            padding-bottom: 0;
        }
        .banner-text{
            width: 100%;
            margin: auto;
            margin-top: 124px;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 72px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #18244E;
            }
        }
        .banner-img{
            width: 100%;
            margin: auto;
            bottom: 0;
            margin-bottom: 0px;
            margin-top: 25px;
            background: url(../img/banner/banner-center.svg);
            background-repeat: no-repeat;
            background-position: bottom;
            background-size:cover;
            img{
                width:100%;
                bottom: 0px;
            }
        }
        
    }

    .integration{
        .box-image{
            width: 96px;
            height: 96px;
            background: #D9E1FC;
            border-radius: 36px;
            margin: auto;
            padding: 20px;
            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .box-content{
            text-align: center;
            margin-bottom: 50px;
            padding: 25px;
        }
        .box-title{
            /* Text / Large Bold */
            // width: 100%;
            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            // display: flex;
            align-items: center;
            text-align: center;

            color: #18244E;
            h2{
                text-align: center;
            }
        }
        .box-text{
            /* Text / Small */

            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            text-align: center;

            color: #18244E;
        }
    }
    #sec-request-demo{
        width:100%;
        max-width: 100%;
    }
    .request-demo{
        width:100%;
        background: #F3F2FF;
        padding: 64px 97px;
        h1{
            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
            /* or 150% */

            display: flex;
            align-items: center;

            color: #18244E;
        }
        p{
            /* Text / Medium */

            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height, or 150% */

            display: flex;
            align-items: center;

            color: #18244E;
            margin-bottom: 30px;
        }
        .btn-start-trial{
            padding: 14px 32px;
            height: 55px;
            background: #DB334D;
            border-radius: 6px;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
        }
        .btn-request-demo{
            padding: 14px 60px;
            height: 55px;
            background: transparent;
            color: #DB334D;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
        }
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #494CA1;
            margin-top:15px ;
        }

        .img-request-demo{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            border-radius: 6px;
        }
    }

    .faq{
        padding-bottom: 60px;
        .faq-head-text{
            text-align: center;
            width: 100%;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
            color: #18244E;
        }
        .has-search{
            position: relative;
            .form-control{
                height: 48px;
                border-radius: 6px;
                border: 2px solid #E8E8E8;
            }
            span {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                display: block;
                width: 48px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                pointer-events: none;
                color:#404040;
            }
        }
        .sidenav{
            padding: 25px;
            ul{
                li{
                    // width: 294px;
                    padding: 15px;
                    height: 56px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    align-items: center;
                    text-align: left;
                    color: #18244E;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    margin: 15px;
                    cursor: pointer;
                    a{
                        color: #18244E;
                    }
                    
                }
                li:hover,
                li:focus{
                    background: #DCDDFF;
                    color: #18244E;
                }
                .selected,
                .selected:hover,
                .selected:focus{
                    background: #18244E;
                    color: #fff;
                    a{
                        color: #fff;
                    }
                }
            }
        }
        .post-title{
            margin-top: 40px;
            margin-bottom: 24px;
            h1{
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                color: #18244E;
                text-align: left;
            }
        }
        .post-author{
            padding: 15px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 404px;
            height: 48px;
            margin-bottom: 50px;
            .author-avatar{
                position: relative;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: 1.5px solid #000000;
                float: left;
                img{
                    display: block;
                    margin: auto;
                    padding: 10px;
                    padding-bottom: 0px;
                }
            }
            .author-detail{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 4px 0px 0px;
                width: 332px;
                height: 43px;

                flex: none;
                order: 1;
                flex-grow: 0;
                margin: 0px 24px;
                .author-name{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #18244E;
                }
                .post-date{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #808080;
                }
            }

        }
        .post-content{
            .div-wisiwig{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                color: #18244E;
                ol {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    align-items: center;
                    color: #18244E;
                    list-style-type: decimal;
                    margin-left: 20px;
                    ul{
                        list-style-type:disc;
                        margin-left: 20px;
                    }
                }
                ul{
                    list-style-type:disc;
                    margin-left: 20px;
                }
                img{
                    width: 100%;
                    margin-top:16px;
                    margin-bottom: 24px;
                }
                p{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    align-items: center;
                    color: #18244E;
                }
                a{
                    color: #2d26b8;
                    margin-left: 5px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .accordion{
            .card {
                position: relative;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: #fff;
                background-clip: border-box;
                border: 1px solid rgba(0,0,0,.125);
                border-radius: 0.25rem;
                margin:16px;
                .card-header {
                    text-align: left;
                    position: relative;
                    background: #F5F5F5;
                    border-radius: 6px;
                    padding: 24px;
                    h2{
                        margin-top: 0;
                        margin-bottom: 0.5rem;
                        line-height: 1.2;
                        text-align: left;
                        font-family: 'OpenSans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #18244E;
                    }
                }
                .card-header:first-child {
                    border-radius: 6px;
                }
                .card-body {
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                    padding: 24px;
                    background: #F5F5F5;
                    .div-wisiwig{
                        color: #18244E;
                        ol {
                            list-style-type: decimal;
                            margin-left: 20px;
                            ul{
                                list-style-type:disc;
                                margin-left: 20px;
                            }
                        }
                        ul{
                            list-style-type:disc;
                            margin-left: 20px;
                        }
                        img{
                            width: 100%;
                        }        
                        a{
                            color: #2d26b8;
                            margin-left: 5px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
                .card-header:after {
                    font-family: 'Glyphicons Halflings';
                    content: "\e259";
                    position: absolute;
                    color: #18244E;
                    font-size: 18px;
                    line-height: 22px;
                    right: 20px;
                    top: calc(50% - 10px);
                }
                .card-header[aria-expanded="true"]::after {
                    -webkit-transform: rotate(180deg);
                    -moz-transform:    rotate(180deg);
                    -ms-transform:     rotate(180deg);
                    -o-transform:      rotate(180deg);
                    transform:         rotate(180deg);
                }
            }
        }

        .search-result{
            margin-top: 80px;
            color: #18244E;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: left;
            }
            h2{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
            }
            p{
                margin-bottom: 30px;
                margin-right: 130px;
            }
            button{
                width: auto;
                padding: 14px 32px;
                background: #DB334D;
                border-radius: 6px;
            }
        }
    }

    .btn,
    button{
        font-style:normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
    }

    @media screen and (max-width: 767px) and (orientation: portrait){
        .top-banner{
            position: relative;
            overflow: hidden;
            min-height: auto;
            left: unset;
            top: unset;
            section {
                padding: 24px 16px 0;
            }
            .banner-text{
                width: 100%;
                margin: 0;
                text-align: center;
                h1{
                    font-size: 32px;
                    line-height: 48px;
                    width: 100%;
                    margin: 0 0 32px;
                }
            }
            .banner-img{
                width: 100%;
                margin: auto;
                bottom: 0;
                margin-bottom: 0px;
                margin-top: 25px;
                background: url(../img/banner/banner-center.svg);
                background-repeat: no-repeat;
                background-position: bottom;
                background-size:cover;
                img{
                    width:100%;
                    bottom: 0px;
                }
            }
            
        }

        .integration{
            .box-image {
                border-radius: 60px;
            }
            .box-content{
                text-align: center;
                margin-bottom: 30px;
                padding: 0;
                h2 {
                    font-size: 18px;
                    line-height: 27px;
                }
                p {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        .request-demo{
            width:100%;
            background: #F3F2FF;
            padding: 16px 32px;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                /* or 150% */
    
                display: flex;
                align-items: center;
    
                color: #18244E;
            }
            p{
                /* Text / Medium */
    
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                /* identical to box height, or 150% */
    
                display: flex;
                align-items: center;
    
                color: #18244E;
                margin-bottom: 30px;
            }
            .btn-start-trial{
                padding: 14px 32px;
                height: 55px;
                background: #DB334D;
                border-radius: 6px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
            .btn-request-demo{
                padding: 14px 32px;
                height: 55px;
                background: transparent;
                color: #DB334D;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                margin-top: 44px;
                margin-bottom: 44px;
            }
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #494CA1;
                margin-top:16px ;
            }
    
            .img-request-demo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                border-radius: 6px;
            }

            .button-center-xs{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .search-result{
            margin-top: 40px;
            text-align: left;
            h1{
                font-size: 24px !important;
                line-height: 36px !important;
                margin: 0 0 12px;
            }
            h2{
                font-size: 16px !important;
                line-height: 24px !important;
            }
            p{
                font-size: 14px !important;
                line-height: 21px !important;
                margin: 0 0 24px !important;
            }
            a{
                font-size: 16px;
                line-height: 24px;
                padding: 12px 0 !important;
                width: 100%;
            }
            img{
                margin-top: 25px;
                width: 100%;
                display: inline-block;
            }
        }
        .faq{
            .faq-head-text{
                text-align: center;
                width: 100%;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                color: #18244E;
            }
            .has-search{
                position: relative;
                .form-control{
                    height: 48px;
                    border-radius: 6px;
                    border: 2px solid #E8E8E8;
                }
                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    display: block;
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    pointer-events: none;
                    color:#404040;
                }
            }
            .post-title{
                margin-bottom: 24px;
                h1{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    color: #18244E;
                    text-align: left;
                }
            }
            .post-author{
                padding: 15px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                width: 404px;
                height: 48px;
                margin-bottom: 50px;
                .author-avatar{
                    position: relative;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1.5px solid #000000;
                    float: left;
                    img{
                        display: block;
                        margin: auto;
                        padding: 10px;
                        padding-bottom: 0px;
                    }
                }
                .author-detail{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 4px 0px 0px;
                    width: 332px;
                    height: 43px;
    
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin: 0px 24px;
                    .author-name{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #18244E;
                    }
                    .post-date{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #808080;
                    }
                }
    
            }
            .accordion{
                .card {
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #F5F5F5;
                    background-clip: border-box;
                    border: 0;
                    border-radius: 6px;
                    margin:16px 0;
                    .card-header {
                        text-align: left;
                        position: relative;
                        background: #F5F5F5;
                        border-radius: 6px;
                        padding: 24px;
                        h2{
                            margin-top: 0;
                            margin-bottom: 0.5rem;
                            line-height: 1.2;
                            text-align: left;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: #18244E;
                        }
                    }
                    .card-header:first-child {
                        border-radius: 6px;
                    }
                    .card-body {
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        padding: 24px;
                        background: #F5F5F5;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #18244E;
                        .div-wisiwig{
                            ol {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #18244E;
                                list-style-type: decimal;
                                margin-left: 20px;
                                ul{
                                    list-style-type:disc;
                                    margin-left: 20px;
                                }
                            }
                            ul{
                                list-style-type:disc;
                                margin-left: 20px;
                            }
                            img{
                                width: 100%;
                            }
                            p{
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #18244E;
                            }
                            a{
                                color: #2d26b8;
                                margin-left: 5px;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                    .card-header:after {
                        font-family: 'Glyphicons Halflings';
                        content: "\e259";
                        position: absolute;
                        color: #18244E;
                        font-size: 18px;
                        line-height: 22px;
                        right: 20px;
                        top: calc(50% - 10px);
                    }
                    .card-header[aria-expanded="true"]::after {
                        -webkit-transform: rotate(180deg);
                        -moz-transform:    rotate(180deg);
                        -ms-transform:     rotate(180deg);
                        -o-transform:      rotate(180deg);
                        transform:         rotate(180deg);
                    }
                }
            }
    
            .search-result{
                margin-top: 0px;
                padding: 24px;
                h1{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    text-align: left;
                }
                h2{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                }
                p{
                    margin-bottom: 30px;
                }
                .btn{
                    width: 100%;
                    padding: 14px 32px;
                    background: #DB334D;
                    border-radius: 6px;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
        .top-banner{
            position: relative;
            overflow: hidden;
            min-height: 390px;
            left: 0px;
            top: 0px;
            .banner-text{
                width: 75%;
                left: 202px;
                margin: auto;
                margin-top: 124px;
                h1{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #18244E;
                }
            }
            .banner-img{
                width: 100%;
                margin: auto;
                bottom: 0;
                margin-bottom: 0px;
                margin-top: 25px;
                background: url(../img/banner/banner-center.svg);
                background-repeat: no-repeat;
                background-position: bottom;
                background-size:cover;
                img{
                    width:100%;
                    bottom: 0px;
                }
            }
            
        }

        .integration{
            .box-content{
                text-align: center;
                margin-bottom: 30px;
                padding: 0px 25px;
            }
        }

        .request-demo{
            width:100%;
            background: #F3F2FF;
            padding: 16px 32px;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                /* or 150% */
    
                display: flex;
                align-items: center;
    
                color: #18244E;
            }
            p{
                /* Text / Medium */
    
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                /* identical to box height, or 150% */
    
                display: flex;
                align-items: center;
    
                color: #18244E;
                margin-bottom: 30px;
            }
            .btn-start-trial{
                padding: 14px 32px;
                height: 55px;
                background: #DB334D;
                border-radius: 6px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
            .btn-request-demo{
                padding: 14px 32px;
                height: 55px;
                background: transparent;
                color: #DB334D;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                margin-top: 44px;
                margin-bottom: 44px;
            }
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #494CA1;
                margin-top:16px ;
            }
    
            .img-request-demo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                border-radius: 6px;
            }
            .button-center-xs{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .search-result{
            margin-top: 24px;
            padding: 24px;
            text-align: center;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center !important;
            }
            h2{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
            }
            p{
                margin-bottom: 30px;
            }
            button{
                width: auto;
                padding: 14px 32px;
                background: #DB334D;
                border-radius: 6px;
                text-align: center;
            }
            img{
                margin-top: 25px;
                width: 100%;
            }
        }

        .faq{
            .faq-head-text{
                text-align: center;
                width: 100%;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                color: #18244E;
            }
            .has-search{
                position: relative;
                .form-control{
                    height: 48px;
                    border-radius: 6px;
                    border: 2px solid #E8E8E8;
                }
                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    display: block;
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    pointer-events: none;
                    color:#404040;
                }
            }
            .post-title{
                margin-bottom: 24px;
                h1{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    color: #18244E;
                    text-align: left;
                }
            }
            .post-author{
                padding: 15px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                width: 404px;
                height: 48px;
                margin-bottom: 50px;
                .author-avatar{
                    position: relative;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1.5px solid #000000;
                    float: left;
                    img{
                        display: block;
                        margin: auto;
                        padding: 10px;
                        padding-bottom: 0px;
                    }
                }
                .author-detail{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 4px 0px 0px;
                    width: 332px;
                    height: 43px;
    
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    margin: 0px 24px;
                    .author-name{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #18244E;
                    }
                    .post-date{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #808080;
                    }
                }
    
            }
            .accordion{
                .card {
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #fff;
                    background-clip: border-box;
                    border: 1px solid rgba(0,0,0,.125);
                    border-radius: 0.25rem;
                    margin:16px;
                    .card-header {
                        text-align: left;
                        position: relative;
                        background: #F5F5F5;
                        border-radius: 6px;
                        padding: 24px;
                        h2{
                            margin-top: 0;
                            margin-bottom: 0.5rem;
                            line-height: 1.2;
                            text-align: left;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: #18244E;
                        }
                    }
                    .card-header:first-child {
                        border-radius: 6px;
                    }
                    .card-body {
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        padding: 24px;
                        background: #F5F5F5;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #18244E;
                        .div-wisiwig{
                            ol {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #18244E;
                                list-style-type: decimal;
                                margin-left: 20px;
                                ul{
                                    list-style-type:disc;
                                    margin-left: 20px;
                                }
                            }
                            ul{
                                list-style-type:disc;
                                margin-left: 20px;
                            }
                            img{
                                width: 100%;
                            }
                            p{
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #18244E;
                            }
                            a{
                                color: #2d26b8;
                                margin-left: 5px;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                    .card-header:after {
                        font-family: 'Glyphicons Halflings';
                        content: "\e259";
                        position: absolute;
                        color: #18244E;
                        font-size: 18px;
                        line-height: 22px;
                        right: 20px;
                        top: calc(50% - 10px);
                    }
                    .card-header[aria-expanded="true"]::after {
                        -webkit-transform: rotate(180deg);
                        -moz-transform:    rotate(180deg);
                        -ms-transform:     rotate(180deg);
                        -o-transform:      rotate(180deg);
                        transform:         rotate(180deg);
                    }
                }
            }
    
            .search-result{
                h1{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    text-align: left;
                }
                h2{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                }
                p{
                    margin-bottom: 30px;
                }
                .btn{
                    width: 100%;
                    padding: 14px 32px;
                    background: #DB334D;
                    border-radius: 6px;
                }
            }
        }
    }

    /*
    @media screen and (min-width: 1025px) and (max-width: 1600px){}

    @media screen and (min-width: 768px) and (max-width: 1024px){}

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

    @media screen and (max-width: 767px){}

    @media screen and (max-width: 767px) and (orientation: landscape){}

    @media screen and (max-width: 767px) and (orientation: portrait){}
    */


}