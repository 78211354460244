/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Sep 15, 2019
UPDATE DATE: Sep 15, 2019
REVISION: 1
NAME: astronaut.register-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #DB334D / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/

#register {
/*
* FLEX CONTAINER
*/
background: #EFF0F6;
color: #18244E;

&.partnership-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 56px;
    background: transparent;
    .fa, .fas, .far {
        position: absolute;
        right: 28px;
        bottom: 28px;
        cursor: pointer;
        font-size: 22px;
    }
    .rc-time-picker-clear-icon {
        display: none;
    }
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-evenly {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-evenly;
}

.flex-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-items-start {
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
          align-items: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.vertical-item--center {
    @extend .flex-container;
    @extend .align-items-center;
}

.vertical-item--top {
    @extend .flex-container;
    @extend .align-items-start;
}

.vertical-item--justify {
    @extend .flex-container;
    @extend .flex-start;
}

.horizontal-item--around {
    @extend .flex-container;
    @extend .flex-around;
    @extend .align-items-center;
}

.horizontal-item--between {
    @extend .flex-container;
    @extend .flex-between;
    @extend .align-items-center;
}

.horizontal-item--evenly {
  @extend .flex-container;
  @extend .flex-evenly;
  @extend .align-items-center;
}

.horizontal-item--center {
    @extend .flex-container;
    @extend .flex-center;
    @extend .align-items-center;
}

.bold {
    font-weight: bold;
}

.visible {
    visibility: visible !important;
}

.pop{
    position: fixed;
    background: #FFF;
    padding: 16px 16px 32px;
    border-radius: 6px;
    width: 740px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
    box-sizing: border-box;
    box-shadow: 0px 10px 40px rgba(64, 64, 64, 0.05);
    hr {
        margin: 16px -16px;
        border-bottom: 1px solid #E8E8E8;
    }
}

.pop .pop-head{
    position: relative;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    padding: 0;
    // padding: 0 27px 0 0;
    text-align: center;
}

.pop .pop-head a{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: #999;
    font-size: 24px;
    line-height: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

.pop .pop-head a:hover{
    color: #DB334D;
    transform: translate(0, -50%) rotate(90deg);
}

.pop .pop-body{
    color: #333;
    label {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
    .div-selection {
        margin-top: 24px;
        justify-content: center;
        gap: 16px;
        .box-option {
            cursor: pointer;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            border-radius: 6px;
            width: 225px;
            height: 219px;
            padding: 20px 12px 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #404040;
            position: relative;
            .img-container {
                height: 100px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
            img {
                max-width: 136px;
            }
            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                margin-top: 9px;
            }
            p {
                margin: 4px 0 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
            }
            &:hover, &:focus, &:active {
                border: 1px solid #ED2453;
            }
            &.selected {
               border: 1px solid #ED2453; 
            }
            .checkbox-container {
                position: absolute;
                top: 8px;
                right: -4px;
                margin: 0;
                .checkmark {
                    margin-top: 0;
                }
                input:checked ~ .checkmark {
                    background-color: #ED2453;
                }
            }
        }
    }
    .applicant-p {
        margin: 16px 0 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: right;
        a {
            font-weight: 700;
            cursor: pointer;
        }
    }
    .div-info {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 0 16px;
        label {
            font-size: 14px;
            font-weight: bold;
        }
        p {
            font-size: 14px;
            line-height: 18px;
            margin-top: 8px;
        }
        a {
            font-weight: bold;
        }
        ol {
            list-style: decimal;
            margin: 8px 0;
            font-size: 14px;
            line-height: 21px;
            padding-left: 20px;
        }
    }
}

.pop-dropdown {
    .pop-head {
        font-size: 16px !important;
        font-weight: 600;
        line-height: 21px !important;
        text-align: left;
        color: #333333;
    }
    .pop-body {
        margin-top: 12px;
    }
}

.select-plan-modal {
    padding-right: 5px;
    .checkbox-container input:checked ~ .checkmark {
        background-color: #ED2453;
    }
    .checkbox-container {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        color: #404040;
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #808080;
            margin: 5px 0 12px;
        }
    }
    hr {
        border-top: 1px solid #D9D9D9;
        margin: 12px 0;
    }
}

.overlay{
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .6);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
}

.center {
    text-align: center;
}

button {
    &[disabled] {
        opacity: 0.35;
        cursor: not-allowed;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    text-transform: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.help-block {
    color: red;
    padding-top: 7px;
    font-size: 13px;
}

.btn-start-trial {
    width: 100%;
}

.btn-continue-pop {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    font-weight: bold;
    border: none;
    color: #FFF;
    border-radius: 6px;
    background: #DB334D;
    height: auto;
    padding: 12px 0;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
}

.btn-login-register {
    cursor: pointer;
    display: block;
    width: 150px;
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
    height: 45px;
    border: none;
    color: #FFF;
    font-size: 16px;
    border-radius: 100px;
    box-shadow: 0 0 0 #DB334D;
    background: #DB334D;
}

.text-success-top {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 44px;
}

.text-success-bottom {
    font-size: 18px;
    line-height: 28px;
    margin-top: 32px;
}

.sec-reg{
    overflow: hidden;
}

ul.lay-reg{
    overflow: auto;
    margin: -10px -30px;
}

ul.lay-reg > li{
    float: left;
    padding: 30px;
    width: 50%;
    box-sizing: border-box;
}

ul.the-form > li label{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

ul.the-form > li input,
ul.the-form > li select,
ul.the-form > li textarea{
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #404040;
    border-radius: 6px;
    padding: 14px 16px;
    height: auto;
    border: 2px solid #E8E8E8;
    box-shadow: none;
}

ul.the-form > li input[name="password"] {
    padding: 14px 40px 14px 16px;
}

ul.the-form > li > button{
    height: auto;
    padding: 12px 0;
    font-size: 16px;
    line-height: 24px;
}

.form-group {
    margin-bottom: 0;
}

.desc-log{
    font-size: 18px;
    line-height: 27px;
    img {
        max-width: 450px;
        float: right;
        margin-top: -60px;
    }
}

.desc-log span{
    font-weight: bold;
    font-size: 47px;
    line-height: 72px;
    display: block;
    margin: 0 0 18px 0;
}

.desc-log ul{
    padding: 30px 0 25px 0;
}

.desc-log ul li{
    padding: 10px 0 10px 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.desc-log ul li i{
    float: left;
    background: #2CB582;
    color: #FFF;
    width: 22px;
    font-size: 12px;
    height: 22px;
    line-height: 23px;
    border-radius: 22px;
    text-align: center;
    margin: 0 0 0 -32px;
    // box-shadow: 0 0 10px rgba(86, 79, 219, .8);
    // animation: check-anim 3s infinite ease;
    // -webkit-animation: check-anim 3s infinite ease;
}

.desc-log ul li:nth-child(1) i{
    animation-delay: 0s;
}

.desc-log ul li:nth-child(2) i{
    animation-delay: 0.2s;
}

.desc-log ul li:nth-child(3) i{
    animation-delay: 0.4s;
}

.desc-log ul li:nth-child(4) i{
    animation-delay: 0.6s;
}

.desc-log ul li:nth-child(5) i{
    animation-delay: 0.8s;
}

@keyframes check-anim{
    0% {background: rgba(86, 79, 219, .75); box-shadow: 0 0 10px rgba(86, 79, 219, .8); transform: scale(1, 1);}
    50% {background: rgba(86, 79, 219, 1); box-shadow: 0 0 30px rgba(86, 79, 219, .4); transform: scale(1.2, 1.2);}
    100% {background: rgba(86, 79, 219, .75); box-shadow: 0 0 10px rgba(86, 79, 219, .8); transform: scale(1, 1);}
}

@-webkit-keyframes check-anim{
    0% {background: rgba(86, 79, 219, .75); -webkit-box-shadow: 0 0 10px rgba(86, 79, 219, .8); -webkit-transform: scale(1, 1);}
    50% {background: rgba(86, 79, 219, 1); -webkit-box-shadow: 0 0 30px rgba(86, 79, 219, .4); -webkit-transform: scale(1.2, 1.2);}
    100% {background: rgba(86, 79, 219, .75); -webkit-box-shadow: 0 0 10px rgba(86, 79, 219, .8); -webkit-transform: scale(1, 1);}
}

.box-reg{
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(51, 51, 51, 0.1);
    color: #333;
    &.partnership-form{
        width: 614px;
        &.demo {
            width: 826px;
            padding: 40px 107px;
            > p {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #333333;
                margin-bottom: 40px;
            }
        }
    }
}

.tit-reg{
    text-align: left;
    font-size: 32px;
    line-height: 48px;
    font-weight: bold;
    margin: -5px 0 36px 0;
    &.tit-reg-verify {
        text-align: center;
    }
}

.tit-reg img{
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 38px;
    margin: -3px 2px 0 2px;
    border: 1px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
}

.tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: white;
  color: #404040;
  line-height: 18px;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 101%;
  box-shadow: 0px 0px 8px 4px rgba(207, 207, 207, 0.32);
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 100%;
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}
.input-tool {
  &:active, &:hover, &:focus {
    .tooltiptext {
      visibility: visible;
    }
  }
  input {
    padding-right: 35px;
  }
}
.pass-req {
  display: block;
  color: #CFCFCF;
  margin-top: 8px;
}
.pass-req.active {
  color: #2CB582 !important;
}
.fa-check-circle {
  margin-right: 7px;
  font-size: 13px;
}

.pass-req-mobile {
  color: #808080;
  font-size: 12px;
  margin-bottom: 5px;
  display: none;
}

.error-form {
    border: 2px solid #ED2453;
    box-shadow: none;
}

.fa-eye, .fa-eye-slash {
    position: absolute;
    right: 25px;
    top: 70px;
    cursor: pointer;
    font-size: 15px;
}

.terms-privacy-container {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    a {
        color: #4B6FF2;
    }
}

.partnership-form{
    text-align: left;
}

.select-plan {
    .select__indicator-separator {
        display: none;
    }
    .select__control {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #404040;
        border-radius: 6px;
        padding: 0 10px;
        height: auto;
        border: 2px solid #E8E8E8;
        box-shadow: none;
    }
    .select__value-container {
        padding: 0;
    }
    .select__multi-value {
        background-color: #E4E8EC;
        border-radius: 4px;
    }
    .select__multi-value__label {
        padding: 4px 4px 4px 12px;
    }
    .select__multi-value__remove {
        padding-right: 6px;
    }
    .asdasd {
        display: block;
        width: 100%;
        height: 36px;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.42857143;
        color: #888888;
        background-color: #ffffff;
        background-image: none;
        border: 1px solid #828282;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    .select__option {
        // background-color: #F7F7FC;
        input:checked ~ .checkmark {
            background-color: #ED2453;
        }
        .checkbox-container {
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 21px !important;
            color: #828282;
            p {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 21px !important;
                color: #808080;
            }
        }
    }
    .select__option--is-selected {
        background-color: #F7F7FC;
    }
    .select__option--is-focused {
        background-color: #F7F7FC;
    }
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 18px !important;
  line-height: 27px !important;
  font-weight: 400 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: transparent;
  border: 2px solid #CFCFCF;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #CFCFCF;
  border: 0;
}

/* When the checkbox is checked, add a red background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #564FDB;
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.training-form textarea {
    height: 128px !important;
}

.partnership-form.has-error .checkbox-container .checkmark {
    border: 2px solid #ED2453 !important;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    ul.lay-reg{
        padding: 0 60px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    ul.lay-reg{
        margin: -30px -10px;
        padding: 0 20px;
    }

    ul.lay-reg > li{
        padding: 30px 10px;
        width: 40%;
    }
    
    ul.lay-reg > li:last-child{
        width: 60%;
    }
    
    .desc-log span{
        margin: 50px 0 5px 0;
    }
}

@media screen and (max-width: 767px){
    .pop .pop-head{
        font-size: 18px;
        line-height: 24px;
        padding: 0 27px 0 0;
    }
    .sec-reg{
        padding: 24px 16px 40px;
    }

    .pop{
        width: auto;
        margin: 0 20%;
        border-radius: 6px;
        margin: 0 5%;
        // max-height: 500px;
        // overflow-y: scroll;
    }

    .pop-body {
        max-height: 400px;
        overflow-y: scroll;
    }

    .div-selection {
        -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
          padding-right: 5px;
          margin-top: 0 !important;
    }

    .pop .pop-body .div-selection .box-option {
        margin-bottom: 10px;
        width: 100%;
        height: auto;
    }

    ul.lay-reg{
        margin: -20px -10px;
        padding: 0 10px 10px 10px;
    }

    ul.lay-reg > li{
        padding: 20px 0px;
        width: 100%;
    }

    .tit-reg {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 28px;
    }

    ul.the-form > li label{
        font-size: 16px;
        line-height: 24px;
    }

    ul.the-form > li input,
    ul.the-form > li select,
    ul.the-form > li textarea{
        font-size: 16px;
        line-height: 24px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .fa-eye, .fa-eye-slash {
        top: 104px;
    }

    .desc-log {
        font-size: 14px;
        line-height: 21px;
    }
    
    .desc-log span{
        margin: 0 0 16px 0;
        font-size: 32px;
        line-height: 48px;
    }

    .desc-log img {
        margin-top: 0;
    }
    
    .desc-log ul{
        padding: 26px 0 20px 0;
    }
    
    .desc-log ul li{
        padding: 5px 0 5px 32px;
    }
    .tooltiptext {
      display: none;
    }
    .pass-req-mobile {
      display: inline-block;
    }
    .checkbox-container {
      font-size: 16px !important;
      line-height: 24px !important;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}