/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 17, 2019
UPDATE DATE: Oct 17, 2019
REVISION: 1
NAME: astronaut.pricing-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #DB334D
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#pricing {
    color: #18244E;
/*
* FLEX CONTAINER
*/

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex-evenly {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-evenly;
}

.flex-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.flex-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-items-start {
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.align-items-end {
  -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
          align-items: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.vertical-item--center {
    @extend .flex-container;
    @extend .align-items-center;
}

.vertical-item--top {
    @extend .flex-container;
    @extend .align-items-start;
}

.vertical-item--justify {
    @extend .flex-container;
    @extend .flex-start;
}

.horizontal-item--around {
    @extend .flex-container;
    @extend .flex-around;
    @extend .align-items-center;
}

.horizontal-item--between {
    @extend .flex-container;
    @extend .flex-between;
    @extend .align-items-center;
}

.horizontal-item--evenly {
  @extend .flex-container;
  @extend .flex-evenly;
  @extend .align-items-center;
}

.horizontal-item--center {
    @extend .flex-container;
    @extend .flex-center;
    @extend .align-items-center;
}

.section-pricing {
    max-width: 100%;
    background-color: #fff;
}
.price-tit{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #18244E;
    line-height: 38px;
    margin: 0 0 40px 0;
    strong {
        color: #DB334D;
    }
    p {
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #18244E;
        margin-top: 16px;
    }
    span {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #494CA1;
        margin-top: 36px;
        display: block;
    }
}

ul.the-price{
    overflow: auto;
    margin: -20px -20px -40px -20px;
}

ul.the-price > li{
    // float: left;
    width: 90%;
    margin: 0 auto;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
}

ul.the-price > li .box-price{
    background: #F7F7FC;
    padding: 26px;
    border-radius: 10px;
}

.tit-price {
    > div:first-child {
        font-size: 16px;
    }
    > div {
        display: inline-block;
        text-align: left;
        p {
            font-size: 16px;
            font-weight: normal;
            min-height: 72px;
        }
    }
    > div:nth-of-type(1) {width: 24%;padding-right: 20px;}
    > div:nth-of-type(2) {width: 20%;padding-right: 20px;}
    > div:nth-of-type(3) {width: 36%;padding-right: 20px;}
    > div:nth-of-type(4) {width: 20%;padding-right: 20px;}
    .div-harga {
        min-height: 60px;
    }
    .div-dollar {
        > div {
            display: inline-block;
            font-weight: normal;
            width: 50%;
        }
        span {
            font-size: 16px;
            font-weight: normal;
        }
        p {
            font-weight: 600;
            font-size: 12px;
            color: #494CA1;
            margin-top: 8px;
            margin-bottom: 0;
        }
        .note-custom-pricing {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #494CA1;
            margin-top: 16px;
        }
        .price-usd {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #494CA1;
            margin-top: 0;
        }
    }
}

.div-dollar-mobile > div {width: 100% !important;}

.box-price {
    .divider {
        border-bottom: 1px solid #BEBBF1;
        margin-bottom: 20px;
    }
}

.comparison-list {
    margin-top: 24px;
    padding: 0 26px;
    .feature-list {
        margin-bottom: 48px;
        label {
            display: block;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #18244E;
        }
        .list-compare {
            padding: 16px 0;
            border-top: 1px solid #CFCFCF;
            display: flex;
            > div {
                display: inline-block;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #18244E;
            }
            > div:nth-of-type(1) {width: 24%;padding-right: 20px;}
            > div:nth-of-type(2) {width: 20%;}
            > div:nth-of-type(3) {width: 18%;}
            > div:nth-of-type(4) {width: 18%;}
            > div:nth-of-type(5) {width: 20%;}
            .fa-check {
                background: #2CB582;
                color: #FFF;
                width: 22px;
                font-size: 12px;
                height: 22px;
                line-height: 23px;
                border-radius: 22px;
                text-align: center;
            }
            a {
                display: inline;
                width: auto;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                border: none;
                padding: 0;
                margin: 0 0 0 8px;
                border-radius: unset;
                cursor: pointer;
                &:hover {
                    background: transparent;
                    color: #DB334D;
                    text-decoration: underline !important;
                }
            }
        }
        .list-compare:first-of-type {
            margin-top: 16px;
        }
        .list-compare:last-of-type {
            border-bottom: 1px solid #CFCFCF;
        }
    }
}

.div-ngo-startup {
    width: 100%;
    background: #F7F7FC;
    border-radius: 8px;
    text-align: center;
    padding: 36px;
    box-sizing: border-box;
    label {
        display: block;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #18244E;
        margin-bottom: 8px;
    }
    p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #18244E;
        margin-bottom: 16px;
    }
    a {
        display: inline-block !important;
    }
}

ul.the-price > li .tit-price,
ul.the-price > li .desc-price{
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

ul.the-price > li .tit-price-covid{
    text-align: left;
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

ul.the-price > li .desc-price{
    margin: 20px 0 0 0;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

ul.the-price > li .desc-price-covid{
        text-align: left;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

ul.the-price > li .box-price > ul{
    margin: -5px;
}

ul.the-price > li .box-price > ul > li{
    position: relative;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 5px 5px 25px;
}

ul.the-price > li .box-price > ul > li > i{
    position: absolute;
    left: 0;
    top: 5px;
    background: #DB334D;
    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 11px;
    line-height: 20px;
    border-radius: 22px;
    text-align: center;
}

ul.the-price > li .box-price-covid > ul{
    margin: -5px;
}

ul.the-price > li .box-price-covid > ul > li{
    position: relative;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    padding: 5px 5px 5px 25px;
}

ul.the-price > li .box-price-covid > ul > li > i{
    position: absolute;
    left: 0;
    top: 5px;
    background: #DB334D;
    color: #FFF;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 22px;
    text-align: center;
}

ul.the-price > li a{
    text-decoration: unset !important;
    display: inline-block;
    width: auto;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    padding: 12px 16px;
    border-radius: 6px;
    margin: 16px 0 0 0;
    background: transparent;
    color: #DB334D;
    border: 2px solid #DB334D;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.the-price > li .box-price-covid a{
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 10px 0 5px;
    border-radius: 100px;
    margin: 11px 0 0 0;
    background: #D8E0FB;
    color: #DB334D;
    border: 2px solid #DB334D;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    div {
        display: inline-block;
        line-height: 16px;
        text-align: right;
        span {
                font-size: 12px;
                font-weight: bold;
        }
        img {
            width: auto;
            height: 30px;
            margin-left: 5px;
            margin-top: -15px;
        }
    }
}

ul.the-price > li a:hover{
    color: #FFF;
    background: #DB334D;
}

.note{
    font-style: italic;
    margin: 40px 0 0 0;
}

.tit-faq{
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #18244E
}

.box-price-mobile {
    .tit-price {
        > div {
            width: 100%;
            padding: 0;
        }
        > div:first-child {
            font-size: unset;
        }
    }
    .tit-price-mobile {
        > div {
            width: auto;
        }
    }
}

.plan-selector {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #DB334D;
    i {margin-left: 5px;}
}

.button-apply-now {
    min-width: 300px;
}

ul.price-faq{
    margin: 30px auto 0;
    max-width: 900px;
}

ul.price-faq > li{
    background: #FFFFFF;
    border-radius: 6px;
    margin-bottom: 12px;
}

ul.price-faq > li > a.dropdown-new{
    display: block;
    position: relative;
    padding: 24px 45px 24px 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #18244E;
    text-decoration: unset;
}

ul.price-faq > li a.dropdown-new i{
    position: absolute;
    right: 24px;
    top: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.price-faq > li a.dropdown-new.is-on i{
    transform: rotate(180deg);
}

ul.price-faq > li div{
    margin: -20px 0 0 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    line-height: 20px;
}

ul.price-faq > li div a,
.cant a{
    font-weight: bold;
    color: #4B6FF2;
    text-decoration: unset;
}


ul.price-faq > li div p{
    margin: 10px 0;
}

ul.price-faq > li ul{
    list-style: none;
    margin: 5px 0;
    padding: 0 25px;
}

ul.price-faq > li ul.use-num{
    list-style: decimal;
}

ul.price-faq > li ul > li{
    padding: 5px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

ul.support-faq{
    margin: 30px auto 0;
    max-width: 900px;
}

ul.support-faq > li{
    background: #F5F5F5;
    border-radius: 6px;
    margin-bottom: 12px;
}

ul.support-faq > li > a.dropdown-new{
    display: block;
    position: relative;
    padding: 24px 45px 24px 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #18244E;
    text-decoration: unset;
}

ul.support-faq > li a.dropdown-new i{
    position: absolute;
    right: 24px;
    top: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.support-faq > li a.dropdown-new.is-on i{
    transform: rotate(180deg);
}

ul.support-faq > li div{
    margin: -20px 0 0 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    line-height: 20px;
}

ul.support-faq > li div a,
.cant a{
    font-weight: bold;
    color: #4B6FF2;
    text-decoration: unset;
}

ul.support-faq > li div a:hover,
.cant a:hover{
    color: #4B6FF2;
    text-decoration: unset;
}


ul.support-faq > li div p{
    margin: 10px 0;
}

ul.support-faq > li ul{
    list-style: none;
    margin: 5px 0;
    padding: 0 25px;
}

ul.support-faq > li ul.use-num{
    list-style: decimal;
}

ul.support-faq > li ul > li{
    padding: 5px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

ul.support-faq > li ul > li ul{
    list-style: disc;
    b {display: block;}
}

ul.flow-faq{
    margin: 30px auto 0;
    max-width: 900px;
    padding: 130px 0 200px !important;
}

ul.flow-faq > li{
    background: transparent;
    margin-bottom: 12px;
    width: 100% !important;
}

ul.flow-faq > li > a.dropdown-new{
    display: block;
    text-decoration: unset;
    position: relative;
    padding: 32px 45px 32px 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #18244E;
    border-bottom: 1px solid #CFCFCF;
}

ul.flow-faq > li a.dropdown-new:hover{
    color: #DB334D;
}

ul.flow-faq > li a.dropdown-new i{
    position: absolute;
    right: 24px;
    top: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.flow-faq > li a.dropdown-new.is-on {
    background: #FFF;
    border-radius: 6px;
    border-top: 6px solid #DB334D;
    border-bottom: 0;
}

ul.flow-faq > li a.dropdown-new.is-on i{
    transform: rotate(180deg);
}

ul.flow-faq > li div{
    margin: -20px 0 0 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    line-height: 20px;
}

ul.flow-faq > li div a,
.cant a{
    font-weight: bold;
    color: #4B6FF2;
    text-decoration: unset;
}


ul.flow-faq > li div p{
    margin: 10px 0;
}

ul.flow-faq > li ul{
    list-style: none;
    margin: 5px 0;
    padding: 0 32px 32px;
    background: #FFF;
    border-radius: 6px;
}

ul.flow-faq > li ul.use-num{
    list-style: decimal;
}

ul.flow-faq > li ul > li{
    padding: 8px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #18244E;
    width: 100% !important;
    i {
        margin-right: 10px;
        color: #DB334D;
    }
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #18244E;
    }
    .button-test {
        margin-top: 24px;
        display: inline-block;
        width: auto;
        background: transparent;
        padding: 14px 50px;
        border: 2px solid #18244E;
        border-radius: 6px;
    }
    a {
        margin: 0;
        display: inline;
        color: #18244E;
        font-size: 16px;
        line-height: 24px;
        i {
            color: #18244E;
        }
    }
}

ul.flow-faq > li ul > li ul{
    list-style: disc;
    b {display: block;}
}

.cant{
    margin: 40px 0 0 0;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    strong {
        display: block;
        color: #18244E;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 8px;
    }
    a {
        color: #4B6FF2;
    }
}

.cant a,
ul.price-faq li a{
    font-weight: bold;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

.box-price-desktop {display: block;}
.box-price-mobile {display: none;}

.box-price-mobile {
    .tit-price:last-child {
        margin-bottom: 0;
        a {margin-top: 16px !important;display: block;}
    }
}

.comparison-list-desktop {display: block;}
.comparison-list-mobile {display: none;}

.comparison-list-mobile {
    padding: 0;
    .list-compare {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        > div {width: 50% !important;}
        > div:last-child {width: 35% !important;}
    }
}

.dropbtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #DB334D;
    i {margin-left: 5px;}
}

.dropdown-select {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  right: 0;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-content div {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #DB334D;
  padding: 15px;
  border-radius: 5px;
}

.dropdown-content div:hover {background-color: #f1f1f1}

.dropdown-select:hover .dropdown-content, .dropdown-select:focus .dropdown-content, .dropdown-select:active .dropdown-content {
  display: block;
}

.dropdown-select:hover .dropbtn {
  background-color: transparent;
}

.pop{
    position: fixed;
    background: #FFF;
    padding: 16px 40px;
    border-radius: 4px;
    width: 480px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
    box-sizing: border-box;
    box-shadow: 0px 10px 40px rgba(64, 64, 64, 0.05);
    hr {
        margin: 16px -16px;
        border-bottom: 1px solid #E8E8E8;
    }
}

.pop .pop-head{
    position: relative;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    padding: 0 27px 0 0;
}

.pop .pop-head a{
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translate(0, -50%);
    color: #999;
    font-size: 24px;
    line-height: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

.pop .pop-head a:hover{
    color: #DB334D;
    transform: translate(0, -50%) rotate(90deg);
}

.pop .pop-body{
    color: #333;
    text-align: center;
    label {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
    .div-selection {
        margin-top: 24px;
        .box-option {
            cursor: pointer;
            border: 2px solid #E8E8E8;
            box-sizing: border-box;
            border-radius: 6px;
            width: 215px;
            height: 173px;
            padding: 14px;
            img {
                max-width: 136px;
            }
            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                margin-top: 10px;
            }
            &:hover, &:focus, &:active {
                border: 2px solid #DB334D;
            }
            &.selected {
               border: 2px solid #DB334D; 
            }
        }
    }
    .div-info {
        margin-top: 26px;
        margin-bottom: 16px;
        h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
        }
        label {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
        h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            margin-top: 8px;
            margin-bottom: 0;
        }
        .dollar {
            font-weight: normal;
        }
        .slash-block {
            font-weight: normal;
            font-size: 16px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 16px;
        }
        .div-button {
            margin: 40px 0;
        }
        a {
            text-decoration: unset !important;
            display: inline-block;
            width: auto;
            font-weight: bold;
            text-align: center;
            font-size: 18px;
            line-height: 27px;
            padding: 14px 22px;
            border-radius: 6px;
            color: #FFF;
            background: #DB334D;
            border: 2px solid #DB334D;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;
            &:hover {
                background: transparent;
                color: #DB334D;
            }
        }
        ol {
            list-style: decimal;
            margin: 8px 0;
            font-size: 14px;
            line-height: 21px;
            padding-left: 20px;
        }
    }
}

.overlay{
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, .6);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
}

.hide {
    display: none !important;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 886px) and (max-width: 1380px){
    .tit-price .div-harga {
        min-height: 84px;
    }
    
    ul.the-price > li a {
        font-size: 16px;
    }
}

@media screen and (min-width: 886px) and (max-width: 1134px){
    // .get-astrnt-free-btn {
    //     margin-top: -8px !important;
    // }
    .tit-price .div-harga {
        min-height: 84px;
    }
    
    ul.the-price > li a {
        max-width: 125px;
    }

    .div-plan-not-basic > p {
        margin-bottom: 36px;
    }
}

@media screen and (min-width: 886px) and (max-width: 1008px){
    // .get-astrnt-free-btn {
    //     margin-top: -32px !important;
    // }

    .div-plan-not-basic > p {
        margin-bottom: 60px;
    }
}

@media screen and (min-width: 886px) and (max-width: 1024px){
    ul.the-price{
        padding: 0 20px;
    }
    ul.the-price > li a{
        width: 105px;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 20px;
    }
    .div-dollar {
        p {
            font-size: 12px;
            line-height: 14px;
            margin-top: 4px !important;
        }
    }
}

@media screen and (min-width: 886px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
//     ul.the-price > li{
//         width: 50%;
//         padding: 20px;
//     }
    
//     ul.the-price > li:nth-child(3){
//         width: 100%;
//         padding: 20px 20px 40px 20px;
//     }
    
//     ul.the-price > li:nth-child(3) .box-price > ul{
//         overflow: auto;
//     }
    
//     ul.the-price > li:nth-child(3) .box-price > ul > li{
//         float: left;
//         width: 50%;
//         box-sizing: border-box;
//     }
    
//     .note{
//         text-align: center;
//         margin: 40px 0 0 0;
//     }
// }

@media screen and (max-width: 885px){
    .pop .pop-head a{
        right: 0;
    }

    .pop{
        width: auto;
        margin: 0 5%;
        padding: 16px 16px;
    }

    .div-info {
        margin-top: 24px !important;
    }
    
    h1 {
        font-size: 18px !important;
        line-height: 27px !important;
        margin-top: 10px !important;
    }
    label {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        margin-top: 8px;
        margin-bottom: 0;
    }
    .dollar {
        font-weight: normal;
    }
    .slash-block {
        font-weight: normal;
        font-size: 16px;
    }
    p {
        font-size: 14px !important;
        line-height: 21px !important;
        min-height: 0 !important;
    }

    .section-pricing {
        padding: 24px 20px 40px;
    }
    .price-tit {
        font-size: 32px;
        line-height: 48px;
    }
    .div-dollar {
        > div {
            font-size: 32px;
            line-height: 48px;
        }
        span {
            font-size: 16px;
            font-weight: normal;
        }
        .note-custom-pricing {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #494CA1;
            margin-top: 16px;
        }
    }
    .cant{
        margin: 40px 0 0 0;
        text-align: center;
        font-size: 18px;
        line-height: 27px;
        strong {
            display: block;
            color: #18244E;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 8px;
        }
    }
    .note{
        text-align: center;
        margin: 40px 0 0 0;
    }

    ul.the-price > li .box-price-covid{
        padding: 20px 30px 32px;
    }

    .tit-faq{
        font-size: 32px;
        line-height: 48px;
    }

    ul.support-faq > li > a.dropdown-new {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        i {
            top: 28px;
        }
    }

    ul.support-faq > li ul > li {
        font-size: 14px;
        line-height: 21px;
    }

    ul.price-faq > li > a.dropdown-new {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        i {
            top: 28px;
            font-size: 16px;
        }
    }

    ul.price-faq > li ul > li {
        font-size: 14px;
        line-height: 21px;
    }

    .box-price-desktop {display: none;}
    .box-price-mobile {
        display: block;
        padding: 16px !important;
    }

    .comparison-list-desktop {display: none;}
    .comparison-list-mobile {display: block;}

    .div-ngo-startup {
        padding: 36px 16px;
        a {
            width: 100% !important;
            box-sizing: border-box;
            padding: 10px 30px !important;
            min-width: 0;
        }
    }
    .list-compare a {
        margin: 0 !important;
    }
}

// @media screen and (max-width: 885px) and (orientation: landscape){
//     ul.the-price > li{
//         width: 50%;
//         padding: 20px;
//     }
    
//     ul.the-price > li:nth-child(3){
//         width: 100%;
//         padding: 20px 20px 40px 20px;
//     }
    
//     ul.the-price > li:nth-child(3) .box-price > ul{
//         overflow: auto;
//     }
    
//     ul.the-price > li:nth-child(3) .box-price > ul > li{
//         float: left;
//         width: 50%;
//         box-sizing: border-box;
//     }
// }

@media screen and (max-width: 885px) and (orientation: portrait){
    ul.the-price > li{
        padding: 20px;
        width: 100%;
    }
    
    ul.the-price > li:last-child{
        padding: 20px 20px 40px 20px;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}