/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 26, 2019
UPDATE DATE: Oct 26, 2019
REVISION: 1
NAME: astronaut.partner-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#partner {
.banner{
    position: relative;
    overflow: hidden;
}

.ban-text{
    color: #FFF;
    position: relative;
    padding: 190px 10% 100px 10%;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
    text-align: center;
}

.ban-text .big-text{
    display: block;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
    margin: 0 0 10px 0;
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/partner.jpg) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

ul.list-partner{
    overflow: auto;
    margin: -20px;
}

ul.list-partner > li{
    position: relative;
    float: left;
    overflow: hidden;
    width: 33.33%;
    padding: 20px 20px 20px 40px;
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
}

ul.list-partner > li:before{
    content: '';
    position: absolute;
    left: 20px;
    top: 25px;
    height: 0%;
    width: 1px;
    background: #CCC;
    animation: line-anim 4s infinite ease;
    -webkit-animation: line-anim 4s infinite ease;
}

@keyframes line-anim{
    0% {height: 0%; opacity: 1;}
    90% {height: 100%; opacity: 1;}
    100% {height: 0%; opacity: 0;}
}

@-webkit-keyframes line-anim{
    0% {height: 0%; opacity: 1;}
    90% {height: 100%; opacity: 1;}
    100% {height: 0%; opacity: 0;}
}

ul.list-partner > li:nth-child(2) {
    width: 48%;
}

ul.list-partner > li:nth-child(3),
ul.list-partner > li:nth-child(4){
    width: 50%;
}

ul.list-partner > li span{
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 5px 0;
}

ul.list-partner > li span:before{
    content: '';
    position: absolute;
    width: 0;
    left: -19px;
    top: 5px;
    bottom: 3px;
    background: #564FDB;
    animation: purple-anim 4s infinite ease;
    -webkit-animation: purple-anim 4s infinite ease;
}

@keyframes purple-anim{
    0% {width: 0px; opacity: 0;}
    50% {width: 5px; opacity: 1;}
    90% {width: 5px; opacity: 1;}
    100% {width: 0px; opacity: 0;}
}

@-webkit-keyframes purple-anim{
    0% {width: 0px; opacity: 0;}
    50% {width: 5px; opacity: 1;}
    90% {width: 5px; opacity: 1;}
    100% {width: 0px; opacity: 0;}
}

ul.list-partner > li:nth-child(1):before,
ul.list-partner > li:nth-child(1) span:before{
    animation-delay: 0s;
}

ul.list-partner > li:nth-child(2):before,
ul.list-partner > li:nth-child(2) span:before{
    animation-delay: 0.5s;
}

ul.list-partner > li:nth-child(3):before,
ul.list-partner > li:nth-child(3) span:before{
    animation-delay: 1s;
}

ul.list-partner > li:nth-child(4):before,
ul.list-partner > li:nth-child(4) span:before{
    animation-delay: 1.5s;
}

ul.list-partner > li:nth-child(5):before,
ul.list-partner > li:nth-child(5) span:before{
    animation-delay: 2s;
}

ul.list-partner > li > ul{
    overflow: auto;
    margin: 0 -10px;
}

ul.list-partner > li:nth-child(2) > ul > li{
    float: left;
    width: 33.33%;
    padding: 10px;
    box-sizing: border-box;
}   

ul.list-partner > li > ul > li{
    float: left;
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}



ul.list-partner > li > ul > li > img{
    display: block;
    width: 100%;
    height: 80px;
    object-fit: contain;
    padding: 10px;
    background: #FFF;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    ul.list-partner > li,
    ul.list-partner > li:nth-child(3),
    ul.list-partner > li:nth-child(4){
        width: 50%;
    }
    ul.list-partner > li:nth-child(2) > ul > li{
        width: 50%;
    }
}

@media screen and (max-width: 767px){
    .ban-text{
        font-size: 14px;
        line-height: 22px;
    }
    
    .ban-text .big-text{
        font-size: 26px;
        line-height: 34px;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){
    .ban-text{
        padding: 90px 0 0 0;
    }
    
    ul.list-partner > li,
    ul.list-partner > li:nth-child(3),
    ul.list-partner > li:nth-child(4){
        width: 50%;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text{
        padding: 100px 0 10px 0;
    }
    
    ul.list-partner > li,
    ul.list-partner > li:nth-child(3),
    ul.list-partner > li:nth-child(4){
        width: 100% !important;
    }

    ul.list-partner > li:nth-child(2) > ul > li{
        width: 50%;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}