/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jul 23, 2019
UPDATE DATE: Jul 23, 2019
REVISION: 1
NAME: astronaut.index-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #DB334D / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/

.desktop-hide {
    display: block !important;
  }
  .mobile-hide {
    display: none !important;
  }

  .desktop-hide-header {
    display: block !important;
  }
  .mobile-hide-header {
    display: none !important;
  }

  .desktop-flex-hide {
    display: flex !important;
  }

  .mobile-flex-hide {
    display: none !important;
  }
  
  .cursor-pointer {cursor: pointer;}
  
  @font-face{
      font-family: 'OpenSans';
      src: url('../font/OpenSans.woff2') format('woff2'),
           url('../font/OpenSans.woff') format('woff'),
           url('../font/OpenSans.ttf') format('ttf'),
           url('../font/OpenSans.eot') format('eot'),
           url('../font/OpenSans.svg') format('svg');
      font-weight: normal;
      font-style: normal;
  }
  
  @font-face{
      font-family: 'OpenSans';
      src: url('../font/OpenSans-Light.woff2') format('woff2'),
           url('../font/OpenSans-Light.woff') format('woff'),
           url('../font/OpenSans-Light.ttf') format('ttf'),
           url('../font/OpenSans-Light.eot') format('eot'),
           url('../font/OpenSans-Light.svg') format('svg');
      font-weight: 100;
      font-style: normal;
  }
  
  @font-face{
      font-family: 'OpenSans';
      src: url('../font/OpenSans-Bold.woff2') format('woff2'),
           url('../font/OpenSans-Bold.woff') format('woff'),
           url('../font/OpenSans-Bold.ttf') format('ttf'),
           url('../font/OpenSans-Bold.eot') format('eot'),
           url('../font/OpenSans-Bold.svg') format('svg');
      font-weight: bold;
      font-style: normal;
  }
  
  html{
      -webkit-text-size-adjust: 100%;
      scroll-behavior: smooth;
  }
  
  body{
      background: #EFF0F6;
      color: #18244E;
      font-size: 12px;
      line-height: 12px;
      font-family: 'OpenSans', Helvetica, Arial, sans-serif;
      margin: 0;
      font-smoothing: antialiased;
      -o-font-smoothing: antialiased;
      -ms-font-smoothing: antialiased;
      -moz-font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
  }
  
  body a{
      text-decoration: none;
      color: #DB334D;
      /*transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;*/
  }
  
  section{
      max-width: 1280px;
      margin: 0 auto;
      padding: 40px 20px;
  }
  
  body input,
  body textarea,
  body button{
      font-family: 'OpenSans', Helvetica, Arial, sans-serif;
      margin: 0;
      display: block;
      width: 100%;
      color: #333;
      box-sizing: border-box;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /*transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;*/
  }

  .block {
    display: block;
  }

  .hide {
    display: none;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .hidden {
    display: none;
  }

  .btn:hover, .btn:focus {
      box-shadow: none;
  }

  .text-left {
      text-align: left;
  }

  .text-right {
      text-align: right;
  }

  .inline-content {
    display: inline-block;
  }

  .img-responsive {
    margin: 0 auto;
    text-align: center;
  }

  .img-100 {
    max-width: 100%;
  }

  .auto-margin {
    margin: 0 auto;
  }

  .block {
    display: block;
  }

  .visible {
      visibility: visible;
  }

  .invisible {
      visibility: hidden;
  }

  .relative {
    position: relative;
  }

  .no-border {
      border: 0;
  }

  .uppercase {
      text-transform: uppercase;
  }

  .float-align-left {
    float: left;
  }

  .float-align-right {
    float: right;
  }

  /*
  * FLEX CONTAINER
  */

  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }

  .flex {
      display: flex;
  }

  .flex-column {
    flex-flow: column;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .flex-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .flex-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .flex-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .flex-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .flex-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .flex-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .align-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .align-items-start {
    -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
            align-items: flex-start;
  }

  .align-items-end {
    -webkit-box-align: flex-end;
        -ms-flex-align: flex-end;
            align-items: flex-end;
  }

  .vertical-item--center {
      @extend .flex-container;
      @extend .align-items-center;
  }

  .vertical-item--top {
      @extend .flex-container;
      @extend .align-items-start;
  }

  .vertical-item--justify {
      @extend .flex-container;
      @extend .flex-start;
  }

  .horizontal-item--start {
    @extend .flex-container;
    @extend .flex-center;
    @extend .align-items-start;
  }

  .horizontal-item--around {
      @extend .flex-container;
      @extend .flex-around;
      @extend .align-items-center;
  }

  .horizontal-item--between {
      @extend .flex-container;
      @extend .flex-between;
      @extend .align-items-center;
  }

  .horizontal-item--center {
      @extend .flex-container;
      @extend .flex-center;
      @extend .align-items-center;
  }

  .vertical-item--between {
      @extend .flex;
      @extend .flex-column;
      @extend .flex-between;
  }

  .align-self-end {
      align-self: flex-end;
  }

  .align-self-start {
      align-self: flex-start;
  }

  .align-self-center {
      align-self: center;
  }

  .flex-initial {
      flex: initial;
  }

  .flex-1 {
      flex: 1;
  }

  .flex-inherit {
      flex: inherit;
  }

  .flex-row {
      flex-flow: row;
  }

  .flex-grow {
      flex-grow: 1;
      flex: 1;
  }

  .max-width-100 {
    max-width: 100% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .flex-item-left {
    flex: 50%;
    display: flex;
  }
  
  .flex-item-right {
    flex: 50%;
    display: flex;
  }

  @each $widthConfig in '0' '4' '5' '6' '8' '10' '12' '13' '15' '16' '20' '24' '25' '26' '30' '32' '35' '36' '40' '50' '56' '80' '100' '120' {
    .p-#{$widthConfig} {
        padding: #{$widthConfig}px !important;
    }

    .p-t-#{$widthConfig} {
        padding-top: #{$widthConfig}px;
    }

    .p-l-#{$widthConfig} {
        padding-left: #{$widthConfig}px;
    }

    .p-r-#{$widthConfig} {
        padding-right: #{$widthConfig}px;
    }

    .p-b-#{$widthConfig} {
        padding-bottom: #{$widthConfig}px;
    }

    .m-#{$widthConfig} {
        margin: #{$widthConfig}px  !important;
    }

    .m-l-#{$widthConfig} {
        margin-left: #{$widthConfig}px;
    }

    .m-l-n-#{$widthConfig} {
        margin-left: -#{$widthConfig}px;
    }

    .m-t-#{$widthConfig} {
        margin-top: #{$widthConfig}px !important;
    }

    .m-t-n-#{$widthConfig} {
        margin-top: -#{$widthConfig}px;
    }

    .m-r-#{$widthConfig} {
        margin-right: #{$widthConfig}px !important;
    }

    .m-r-n-#{$widthConfig} {
        margin-right: -#{$widthConfig}px;
    }

    .m-b-#{$widthConfig} {
        margin-bottom: #{$widthConfig}px !important;
    }

    .m-b-n-#{$widthConfig} {
        margin-bottom: -#{$widthConfig}px;
    }

  }
  
  .pad-top{
      height: 120px;
  }
  
  header{
      width: 100%;
      position: fixed;
      box-shadow: 0px 2px 8px rgba(24, 36, 78, 0.1);
      z-index: 99;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      background: #FFF;
      background-size: cover;
      /*transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;*/
  }
  
  header.clear{
      background: #FFFFFF;
  }
  
  header.on-scroll{
      background: #FFF;
      box-shadow: 0px 2px 8px rgba(24, 36, 78, 0.1);
  }
  
  header section{
      padding: 0 20px;
      overflow: auto;
  }
  
  header a.logo{
      float: left;
      padding: 20px 0;
  }
  
  header.on-scroll a.logo{
      padding: 25px 0;
  }
  
  header a.logo.is-on{
      padding: 25px 0 25px 0;
      box-shadow: inset 0 -5px 0 #FFF;
  }
  
  header.on-scroll a.logo.is-on{
      padding: 25px 0 25px 0;
      /*box-shadow: inset 0 -5px 0 #DB334D;*/
  }
  
  header a.logo img{
      display: block;
      height: 40px;
      /*transition: height 200ms ease-in-out;
      -moz-transition: height 200ms ease-in-out;
      -webkit-transition: height 200ms ease-in-out;*/
  }
  
  header.on-scroll a.logo img{
      height: 30px;
  }
  
  header a.nav-mob{
      display: none;
      float: right;
      color: #333333;
      line-height: 80px;
      font-size: 26px;
  }
  
  header.on-scroll a.nav-mob{
      color: #333333;
      line-height: 60px;
  }
  
  header ul.main-nav{
      float: right;
      margin: 0 -10px;
      overflow: auto;
  }
  
  header ul.main-nav li{
      float: left;
      padding: 0 10px;
  }
  
  header ul.main-nav li a{
      display: block;
      font-size: 14px;
      line-height: 80px;
      font-weight: 600;
      color: #494CA1;
      text-decoration: unset;
      outline: none !important;
    box-shadow: none !important;
  }
  
  header.on-scroll ul.main-nav li a{
      color: #494CA1;
      line-height: 80px;
  }
  
  header ul.main-nav li a:hover{
      text-decoration: unset;
  }
  
  header.on-scroll ul.main-nav li a:hover{
      color: #494CA1;
  }
  
  header ul.main-nav li a.is-on,
  header ul.main-nav li a.is-open{
      color: #494CA1;
      box-shadow: inset 0 -5px 0 #FFF;
  }

  header ul.main-nav li a.is-open {
    font-weight: 700;
    font-size: 14px;
    color: #18244E;
  }
  
  header.on-scroll ul.main-nav li a.is-on,
  header.on-scroll ul.main-nav li a.is-open{
      color: #DB334D;
      /*box-shadow: inset 0 -5px 0 #DB334D;*/
  }
  
  header ul.main-nav > li:nth-child(6) > a,
  header ul.main-nav > li:nth-child(7) > a{
      margin: 20px 0;
      font-size: 14px;
      line-height: 36px;
      padding: 0 32px;
      border-radius: 6px;
      background: rgba(255, 255, 255, .1);
      color: #DB334D;
      border: 2px solid #DB334D;
  }
  
  header ul.main-nav > li:nth-child(6) > a{
      color: #FFF;
      border: none;
      line-height: 40px;
      padding: 0 32px;
      background: #DB334D;
      
  }
  
  .top-header {
      background: #18244E;
      height: 40px;
      text-align: right;
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF;
  }
  
  .top-header section {
      padding: 10px 20px;
  }
  
  .top-header a {
      color: #FFF;
  }
  
  .top-header a:hover {
      color: #FFF;
      text-decoration: underline;
  }
  
  header ul.main-nav li a i{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      /*transition: transform 200ms ease-in-out;
      -moz-transition: transform 200ms ease-in-out;
      -webkit-transition: transform 200ms ease-in-out;*/
  }
  
  header ul.main-nav li:nth-child(1) a i{
      font-size: 16px;
      margin: -2px 0 0 0;
  }
  
  header ul.main-nav li:nth-child(7) a i,
  header ul.main-nav li:nth-child(8) a i{
      margin: -2px 0 0 0;
  }
  
  // header ul.main-nav li:nth-child(1) a.is-open i,
  // header ul.main-nav li:nth-child(2) a.is-open i,
  header ul.main-nav li:nth-child(5) a.is-open i,
  header ul.main-nav li:nth-child(8) a.is-open i{
      transform: rotate(-180deg);
  }

  .drop-btn-lang {
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    i {
      margin-left: 5px;
      font-size: 16px !important;
    }
  }

  .drop-btn-tech {
    cursor: pointer;
    img {
      margin-right: 5px;
    }
    i {
      margin-left: 5px;
      font-size: 16px !important;
    }
  }

  .drop-btn-account {
    margin: 24px 0;
    cursor: pointer;
    font-weight: 700 !important;
    font-size: 11px !important;
    line-height: 14px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    color: #FFFFFF !important;
    width: 32px;
    height: 32px;
    background: #ED2453;
    border-radius: 52px;
    border: 4px solid #EAE7FE;
    text-transform: uppercase;
  }

  #id-li-drop-for {
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #EEEEEE;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #999999;
      border-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #999999;
    }
    .track-vertical, .track-horizontal {
      position: absolute;
      width: 4px !important;
      right: 2px;
      bottom: 2px;
      top: 2px;
      border-radius: 3px;
    }
    .thumb-vertical, .thumb-horizontal {
      position: relative;
      display: block;
      width: 100%;
      cursor: pointer;
      border-radius: inherit;
      background-color: #999999;
      height: 107px;
      transform: translateY(174px);
    }
  }

  #id-a-for {
    cursor: pointer;
  }

  #id-account-info {
    cursor: unset;
    &:hover {
        background: unset;
    }
  }

  .dropdown-for {
    position: absolute;
    margin-top: -10px;
    max-width: 400px;
    overflow-y: auto;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 4px 4px 20px rgba(64, 64, 64, 0.4);
    z-index: 1;
    border-radius: 6px;
    hr {
      border-top: 1px solid #E8E8E8;
      margin: 0 16px;
    }
  }

  .dropdown-for > div {
    color: #18244E !important;
    padding: 16px;
    cursor: pointer;
    font-size: unset !important;
    line-height: unset !important;
    font-weight: unset !important;
    text-decoration: unset !important;
    > div {
      padding: 0 !important;
      margin: 0 !important;
    }
    > a > i {
      float: right;
      color: #494CA1;
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      display: inline-block;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin: 0;
      margin-top: 4px;
      > div {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    > a {
      line-height: unset !important;
      font-size: unset !important;
      color: unset !important;
      font-weight: 500 !important;
    }
    > div > a {
      margin-top: 8px;
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 18px !important;
      color: #DB334D !important;
      i {
        font-size: 9px !important;
        float: unset !important;
        margin-top: -2px !important;
        margin-left: 6px !important;
      }
    }
  }

  .dropdown-for div:first-child{
    border-radius: 6px 6px 0 0;
  }
  .dropdown-for div:last-child{
    border-radius: 0 0 6px 6px;
  }

  /*.dropdown-for div:hover {background-color: #EFF0F6;}*/
  .dropdown-for div:hover {background-color: transparent;}

  .dropdown-for > div > a.a-for.is-on{
      span {
        font-weight: 700;
      }
  }

  .dropdown-for > div > a.a-for.is-on i{
      transform: rotate(-180deg);
      color: #18244E;
  }

  .dropdown-lang {
    position: absolute;
    margin-top: -10px;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 4px 4px 20px rgba(64, 64, 64, 0.4);
    z-index: 1;
    border-radius: 5px;
  }

  .dropdown-lang div {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #494CA1;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }

  .dropdown-lang div:first-child{
    border-radius: 6px 6px 0 0;
  }
  .dropdown-lang div:last-child{
    border-radius: 0 0 6px 6px;
  }

  .dropdown-lang div:hover {background-color: #f1f1f1}

  .dropdown-tech {
    position: absolute;
    // margin-top: -10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 33px 38px 18px;
    background-color: #1A234B;
    width: 654px;
    z-index: 1;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dropdown-tech > div {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    width: 46%;
    color: #FFFFFF;
    padding: 0;
    border-radius: 5px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
    .div-text-container {
      h3 {
        color: #F2F2F2;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        margin: 0 0 16px;
      }
      a {
        display: flex;
        width: 156px;
        border: 2px solid #ffffff;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: 12px 16px;
        border-radius: 6px;
        .fa-arrow-right {display: none;}
        .fa-chevron-right {
          margin-left: 12px;
          margin-top: 4px;
          font-size: 16px;
          vertical-align: initial;
        }
      }
      hr {
        margin-top: 15px;
        margin-bottom: 20px;
        border-top: 1px solid #E8E8E8;
        opacity: .5;
      }
    }
  }

  .dropdown-lang-footer {
    position: absolute;
    bottom: 40px;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;
  }

  .dropdown-lang-footer div {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #494CA1;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }

  .dropdown-lang-footer div:hover {background-color: #f1f1f1}

  .dropdown-account {
    position: absolute;
    margin-left: -200px;
    width: 228px;
    margin-top: -10px;
    background-color: #f9f9f9;
    box-shadow: 4px 4px 20px rgba(64, 64, 64, 0.4);
    z-index: 1;
    border-radius: 5px;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    hr {
        border: 1px solid #E8E8E8;
        margin: 0 auto;
        width: 132px;
    }
    .account-icon {
        margin-right: 8px;
        background: #ED2453;
        border-radius: 52px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        text-transform: uppercase;
    }
    .account-info {
        display: flex;
        flex-direction: column;
        width: 75%;
        label {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #404040;
        }
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808080;
        }
    }
  }

  .dropdown-account > div {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #494CA1;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }

  .dropdown-account > div:first-child{
    border-radius: 6px 6px 0 0;
  }
  .dropdown-account > div:last-child{
    border-radius: 0 0 6px 6px;
  }

  .dropdown-account > div:hover {background-color: #f1f1f1}

  .dropdown-account-footer {
    position: absolute;
    bottom: 40px;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;
  }

  .dropdown-account-footer div {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #494CA1;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }

  .dropdown-account-footer div:hover {background-color: #f1f1f1}

  header ul.for-nav{
      overflow: auto;
      background: #1A234B;
      margin: 0;
      padding: 80px 6%;
  /*    border-top: 1px solid #FFF;*/
  /*    background: #FFF;*/
  }
  
  header ul.for-nav li{
      float: left;
      width: 50%;
      margin-bottom: 24px;
  }
  
  header ul.for-nav li:nth-child(3),
  header ul.for-nav li:nth-child(5),
  header ul.for-nav li:nth-child(7){
      margin-bottom: 0;
  }
  
  header ul.for-nav li a{
      display: inline-flex;
      position: relative;
      font-weight: bold;
      color: #FFFFFF;
      overflow: hidden;
      align-items: center;
      text-decoration: unset;
  }
  
  header ul.for-nav li a span{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
  }
  
  header ul.for-nav li a span i{
      display: block;
      font-size: 28px;
      line-height: 28px;
      margin: 0 0 5px 0;
  }
  
  header ul.for-nav li a img{
      display: inline-block;
      width: 164px;
      height: 164px;
      border-radius: 6px;
      margin-right: 24px;
      /*object-fit: cover;
      object-position: center center;*/
      /*transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;*/
  }
  
  header ul.for-nav li a p{
      margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
      margin-top: 4px;
      padding-right: 10px;
  }
  
  header ul.for-nav li a > div > a{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
      color: #FFFFFF;
      margin-top: 16px;
      border: 2px solid #FFF;
      padding: 12px 16px;
      border-radius: 6px;
  }
  
  header ul.for-nav li a > div > a > i{
    font-size: 16px;
    margin-left: 12px;
  }
  
  /*header ul.for-nav li a:hover img{
      width: 100%;
      margin: 0;
      transition: all 1000ms ease-in-out;
      -moz-transition: all 1000ms ease-in-out;
      -webkit-transition: all 1000ms ease-in-out;
  }*/
  
  .overlay-header{
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(64, 64, 64, .6);
      z-index: 9;
  }
  
  /*
  header ul.for-nav li a{
      background: url(../img/banner.jpg) center center no-repeat;
      background-size: cover;
  }
  
  header ul.for-nav li:nth-child(1) a{
      background: url(../img/stock-for/campuses.jpg) center center no-repeat;
      background-size: cover;
  }
  */
  
  .divider{
      border-bottom: 1px solid #CCC;
  }
  
  ul.feature{
      overflow: auto;
      margin: 10px -10px;
  }
  
  ul.feature li{
      float: left;
      padding: 10px;
      width: 33.33%;
      text-align: right;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 21px;
  }
  
  ul.feature li img{
      height: 64px;
      display: inline-block;
      margin: 0 16px 0 auto;
  }
  
  ul.feature li p{
      width: 257px;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #494CA1;
  }
  
  ul.the-form{
      margin: -10px;
  }
  
  ul.the-form > li{
      padding: 10px;
  }
  
  ul.the-form > li label{
      display: block;
      font-weight: bold;
      color: #333;
      font-size: 14px;
      margin: 0 0 10px 0;
  }
  
  ul.the-form > li input{
      height: 45px;
      font-size: 16px;
      border: 1px solid #CCC;
      padding: 10px;
      border-radius: 5px;
  }
  
  ul.the-form > li input::placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li input:-ms-input-placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li input::-ms-input-placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li input:hover{
      border: 1px solid #18244E;
  }
  
  ul.the-form > li input:focus{
      outline: none;
      border: 1px solid #18244E;
      box-shadow: 0 0 5px #18244E;
  }
  
  ul.the-form > li input.is-error{
      border: 1px solid #E33434;
  }
  
  ul.the-form > li input.is-error:focus{
      box-shadow: 0 0 5px #E33434;
  }
  
  ul.the-form > li textarea{
      height: 128px;
      font-size: 16px;
      border: 1px solid #CCC;
      padding: 10px;
      border-radius: 5px;
      resize: none;
  }
  
  ul.the-form > li textarea::placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li textarea:-ms-input-placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li textarea::-ms-input-placeholder{
      color: #CFCFCF;
  }
  
  ul.the-form > li textarea:hover{
      border: 1px solid #18244E;
  }
  
  ul.the-form > li textarea:focus{
      outline: none;
      border: 1px solid #18244E;
      box-shadow: 0 0 5px #18244E;
  }
  
  ul.the-form > li textarea.is-error{
      border: 1px solid #E33434;
  }
  
  ul.the-form > li textarea.is-error:focus{
      box-shadow: 0 0 5px #E33434;
  }
  
  ul.the-form > li select{
      height: 45px;
      width: 100%;
      background-color: transparent;
      font-size: 16px;
      border: 1px solid #CCC;
      padding: 10px;
      border-radius: 5px;
  }
  
  ul.the-form > li select:hover{
      border: 1px solid #18244E;
  }
  
  ul.the-form > li select:focus{
      outline: none;
      border: 1px solid #18244E;
      box-shadow: 0 0 5px #18244E;
  }
  
  ul.the-form > li select.is-error{
      border: 1px solid #E33434;
  }
  
  ul.the-form > li select.is-error:focus{
      box-shadow: 0 0 5px #E33434;
  }
  
  ul.the-form > li > button{
      cursor: pointer;
      display: block;
      width: 150px;
      margin: 0 auto;
      font-weight: bold;
      text-transform: uppercase;
      height: 45px;
      border: none;
      color: #FFF;
      font-size: 16px;
      border-radius: 6px;
      background: #DB334D;
  }
  
  ul.the-form > li button:hover{
  }
  
  .rc-time-picker {
      width: 100%;
  }
  
  footer{
      background: #18244E;
      color: #FFF;
      font-weight: bold;
  }
  
  footer section > ul{
      overflow: hidden;
  }
  
  footer section > ul > li{
      float: left;
      width: 17%;
      font-size: 18px;
      line-height: 27px;
  }
  
  footer section > ul > li:first-child{
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
  }
  
  footer section > ul > li:first-child,
  footer section > ul > li:nth-child(2){
      width: 24%;
      text-align: left;
  }

  #footer-first-li {
    padding-right: 15px;
  }
  
  footer section > ul > li:last-child > img{
      margin-top: 40px;
  }
  
  footer section > ul > li:first-child > img{
     max-width: 100%;
     width: 200px;
     margin-bottom: 12px;
     display: block;
  }
  
  footer section > ul > li > ul:last-child{
      margin: 32px -7.5px 17.5px 0.5px;
  }
  
  footer section > ul > li > ul:first-child{
      margin: 7.5px -7.5px -7.5px -7.5px !important;
  }
  
  footer section > ul > li > ul > li{
      padding: 7.5px;
      box-sizing: border-box;
  }
  
  footer section > ul > li > ul.for-soc > li{
      display: inline-block;
      background: #4B6FF2;
      border-radius: 50%;
      margin-right: 12px;
  }
  
  footer section > ul > li > ul > li > a{
      display: block;
      pointer-events: auto;
      color: #A7CFFF;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  
  footer section > ul > li > ul.for-soc > li > a{
      font-size: 18px;
      line-height: 18px;
      color: #FFF;
  }
  
  footer section > ul > li > ul > li > a:hover{
      color: #FFF;
      text-decoration: unset;
      text-transform: unset;
  }
  
  footer section > ul > li > ul.for-soc:hover > li > a:hover,
  footer section > ul > li:last-child > ul:hover > li > a:hover{
      padding: 0;
  }

  footer section > ul > li > div > ul:last-child{
      margin: 32px -7.5px 17.5px 0.5px;
  }
  
  footer section > ul > li > div > ul:first-child{
      margin: 7.5px -7.5px -7.5px -7.5px !important;
  }

  footer section > ul > li > div > ul > li{
      padding: 7.5px;
      box-sizing: border-box;
  }
  
  footer section > ul > li > div > ul.for-soc > li{
      display: inline-block;
      background: #4B6FF2;
      border-radius: 50%;
      margin-right: 12px;
  }
  
  footer section > ul > li > div > ul > li > a{
      display: block;
      pointer-events: auto;
      color: #A7CFFF;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  
  footer section > ul > li > div > ul.for-soc > li > a{
      font-size: 18px;
      line-height: 18px;
      color: #FFF;
  }
  
  footer section > ul > li > div > ul > li > a:hover{
      padding: 0 0 0 5px;
      color: #FFF;
      text-decoration: unset;
      text-transform: unset;
  }
  
  footer section > ul > li > div > ul.for-soc:hover > li > a:hover,
  footer section > ul > li:last-child > div > ul:hover > li > a:hover{
      padding: 0;
  }
  
  footer section > ul > li > ul > li > a img{
      display: block;
      width: 100%;
      background: #141212;
      object-fit: contain;
      border-radius: 50px;
      height: 47px;
  }
  
  footer .the-copy{
      background: transparent;
      padding: 20px;
      text-align: left;
      line-height: 30px;
      font-weight: 400;
  }
  
  footer .the-copy a{
      height: 28px;
      margin: 0 10px;
      float: right;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: #FFF;
      cursor: pointer;
  }
  
  footer .the-copy a img{
      height: 28px;
      margin: -1px 0 0 0;
  }
  
  footer .the-copy section{
      padding: 32px 0 0;
      border-top: 1px solid #F5F5F5;
      color: #A7CFFF;
  }
  
  .sec-white, .section-white {
      background: #FFF;
  }
  
  .sec-grey, .section-grey{
      background: #E8E8E8;
  }
  
  .sec-blue, .section-blue {
      background: #EFF0F6;
  }

  .sec-red, .section-red {
    background: #C04051;
    li {
        color: white !important;
    }
}

.sec-darkblue, .section-darkblue {
    background: #1A234B;
}
  
  .sec-purple, .section-purple{
      background: #DCDDFF;
  }
  
  .sec-light-purple, .section-light-purple{
      background: #F3F2FF;
  }
  
  .sec-light-purple .ban-text {
      padding: 24px 52% 24px 0 !important;
  }

  .section-red {
    .flex-container {
        z-index: 1;
    }
    &.triangle-left:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 484px 0 0 635px;
        border-color: transparent transparent transparent #B5394A;
        left: 0;
        bottom: 0;
        position: absolute;
    }
    
    &.triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 0 0 121px;
        border-color: transparent transparent transparent #AC3847;
        left: 0;
        bottom: 0;
        position: absolute;
    }
    &.triangle-right:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 484px 635px;
        border-color: transparent transparent #B5394A transparent;
        right: 0;
        bottom: 0;
        position: absolute;
    }
    
    &.triangle-right:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 92px 121px;
        border-color: transparent transparent #AC3847 transparent;
        right: 0;
        bottom: 0;
        position: absolute;
    }
  }

  .section-darkblue {
    .flex-container {
        z-index: 1;
    }
    &.triangle-left:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 484px 0 0 635px;
        border-color: transparent transparent transparent #0E152F;
        left: 0;
        bottom: 0;
        position: absolute;
        opacity: 30%;
    }
    
    &.triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 0 0 121px;
        border-color: transparent transparent transparent #0E152F;
        left: 0;
        bottom: 0;
        position: absolute;
        opacity: 30%;
    }
    &.triangle-right:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 484px 635px;
        border-color: transparent transparent #0E152F transparent;
        right: 0;
        bottom: 0;
        position: absolute;
        opacity: 30%;
    }
    
    &.triangle-right:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 92px 121px;
        border-color: transparent transparent #0E152F transparent;
        right: 0;
        bottom: 0;
        position: absolute;
        opacity: 30%;
    }
  }

  .section-white.single-triangle {
    .flex-container {
        z-index: 1;
    }
    
    &.triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 0 0 121px;
        border-color: transparent transparent #F7F7F7;
        left: 0;
        bottom: 0;
        position: absolute;
    }
    
    &.triangle-right:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 250px 400px;
        border-color: transparent transparent #F7F7F7 transparent;
        right: 0;
        bottom: 0;
        position: absolute;
    }
  }

  .section-white.double-triangle {
    overflow: hidden;
    .flex-container {
        z-index: 1;
    }
    section {
        z-index: 2;
        position: relative;
    }
    &.triangle-left:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 484px 0 0 635px;
        border-color: transparent transparent transparent #F7F7F7;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
    }
    
    &.triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 0 0 121px;
        border-color: transparent transparent transparent #EDEDED;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
    }
    &.triangle-right:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 600px 800px 0 0;
        border-color: transparent #F7F7F7 transparent #F7F7F7;
        right: 0;
        bottom: 0;
        position: absolute;
    }
    
    &.triangle-right:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 300px 400px 0 0;
        border-color: transparent #EDEDED transparent #EDEDED;
        right: 0;
        bottom: 0;
        position: absolute;
    }
    &.disable-small-triangle:after {
        display: none;
    }
  }

  .section-top-solution.double-triangle {
    overflow: hidden;
    padding-top: 120px;
    .flex-container {
        z-index: 1;
    }
    section {
        z-index: 2;
        position: relative;
    }
    &.triangle-left:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 484px 0 0 635px;
        border-color: transparent transparent transparent #F7F7F7;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 0;
    }
    
    &.triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 0 0 121px;
        border-color: transparent transparent transparent #EDEDED;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: 0;
    }
    &.navy {
        .flex-container {
            padding-bottom: 40px;
        }
    }
  }
  
  .sec-tit{
      position: relative;
      text-align: center;
      font-size: 26px;
      line-height: 28px;
      padding: 0 0 31px 0;
      margin: 0 0 30px 0;
  }
  
  .sec-tit:after{
      content: '';
      position: absolute;
      background: #DB334D;
      height: 5px;
      left: 0;
      right: 0;
      width: 10%;
      bottom: 0;
      margin: 0 auto;
  }
  
  .error-404{
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      padding: 60px 0;
      > div {
        width: 50%;
      }
  }

  .text-404 {
    text-align: left;
    label {
      margin: 0 0 16px;
      font-weight: 700;
      font-size: 48px;
      line-height: 72px;
    }
    span {
      display: block;
      margin: 0 0 12px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }
    p {
      margin: 0 0 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    a {
      display: inline-block;
      background: #DB334D;
      border-radius: 6px;
      padding: 14px 36.5px;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
    }
    a:hover {
      text-decoration: unset;
      color: #FFFFFF;
    }
  }
  
  .error-404 img{
      display: block;
      max-width: 100%;
      margin: 0 auto;
  }
  
  .react-datepicker {
      font-size: 1.2rem !important;
  }
  
  .react-datepicker__current-month {
      font-size: 1.2rem !important;
  }
  
  // .sweet-alert {
  //     /*width: 825px;
  //     transform: translate(-50%,-50%);
  //     margin: 0;*/
  // }
  
  // .sweet-alert h2 {
  //     font-weight: 700;
  //     font-size: 24px;
  //     line-height: 36px;
  //     color: #333333;
  // }
  
  // .sweet-alert button.cancel {
  //     border: 2px solid #DB334D;
  //     background: transparent;
  //     font-weight: 700;
  //     font-size: 18px;
  //     line-height: 27px;
  //     color: #DB334D;
  //     border-radius: 6px;
  //     padding: 14px 0;
  //     width: 50%;
  // }
  
  // .sweet-alert button.cancel:hover {
  //     background: transparent;
  // }
  
  // .sweet-alert button.confirm {
  //     background: #DB334D;
  //     border-radius: 6px;
  //     font-weight: 700;
  //     font-size: 18px;
  //     line-height: 27px;
  //     color: #FFF;
  //     padding: 14px 0;
  //     width: 50%;
  // }
  
  // .sweet-alert button.confirm:hover {
  //     background: #DB334D;
  // }
  
  .swal-public {
      width: 825px;
      margin: auto;
      transform: translateX(-50%);
      padding: 70px 0 80px;
      h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #333333;
      }
      p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #333333;
      }
      .sa-button-container {
          text-align: center;
          margin-top: 14px;
          button {
              width: 336px;
              background: #DB334D;
              border-radius: 6px;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              color: #FFF;
              padding: 14px 0;
          }
          button:hover {
              background: #DB334D;
          }
          .cancel {
              width: 336px;
              border: 2px solid #DB334D;
              background: transparent;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              color: #DB334D;
              border-radius: 6px;
              padding: 14px 0;
          }
          .cancel:hover {
              background: transparent;
          }
      }
  }

  .footer-dropdown {
    > li {
      padding: 9px 0;
      &:last-child {
        padding: 9px 0;
      }
    }
    .dropdown-footer {
      color: #FFF;
      display: block;
      text-decoration: none;
      i {
        float: right;
      }
    }
  }
  
  
  @media screen and (min-width: 1025px) and (max-width: 1600px){
    .dropdown-for {
      max-height: 80vh;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px){
      header a.logo img{
          height: 40px;
      }
      
      header.on-scroll a.logo img{
          height: 30px;
      }
      
      header ul.main-nav li a{
          line-height: 80px;
          font-size: 14px;
      }
      
      header.on-scroll ul.main-nav li a{
          line-height: 60px;
      }
      
      header ul.main-nav li:nth-child(7) a,
      header ul.main-nav li:nth-child(8) a{
          font-size: 12px;
          margin: 20px 0;
      }
      
      header.on-scroll ul.main-nav li:nth-child(7) a,
      header.on-scroll ul.main-nav li:nth-child(8) a{
          margin: 10px 0;
          line-height: inherit;
      }
      
      header.on-scroll ul.main-nav li:nth-child(7) a{
          line-height: 36px;
      }
      
      header.on-scroll ul.main-nav li:nth-child(8) a{
          line-height: 40px;
      }
      
      header ul.for-nav li a span{
          font-size: 18px;
          line-height: 22px;
      }
      .dropdown-for {
        position: relative;
        margin-top: 0;
        background-color: transparent;
        min-width: auto;
        box-shadow: none;
        z-index: unset;
        border-radius: 0;
        max-width: 100%;
    }
    .dropdown-lang {
        position: relative;
        margin-top: 0;
        background-color: transparent;
        min-width: auto;
        box-shadow: none;
        z-index: unset;
        border-radius: 0;
    }
    header ul.main-nav li:nth-child(2) a i {
        float: right;
        margin-top: 20px;
    }
    .drop-btn-account i, .drop-btn-lang i {
        float: right;
        margin-top: 20px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
      .pad-top{
          height: 120px;
      }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
      .pad-top{
          height: 120px;
      }
      
      header a.nav-mob{
          display: block;
      }
      
      header ul.main-nav{
          display: none;
          margin: 0;
          border-top: 1px solid #FFF;
          width: 100%;
      }
      
      header.on-scroll ul.main-nav{
          border-top: 1px solid #CCC;
      }
      
      header ul.main-nav li{
        width: 100%;
        box-sizing: border-box;
        padding: 0;
      }
      
      header ul.main-nav li:nth-child(6),
      header ul.main-nav li:nth-child(7){
          width: 100%;
      }
      
      header ul.main-nav li a{
          text-align: center;
      }
      
      header ul.main-nav li a,
      header.on-scroll ul.main-nav li a{
          line-height: 60px;
          text-align: left;
      }
      
      header ul.main-nav li:nth-child(7) a,
      header.on-scroll ul.main-nav li:nth-child(5) a{
          line-height: 36px;
          margin-top: 0;
      }
      
      header ul.main-nav li:nth-child(8) a,
      header.on-scroll ul.main-nav li:nth-child(6) a{
          line-height: 40px;
          margin-top: 0;
      }
      
      header ul.for-nav li{
          width: 33.33%;
      }
      
      ul.feature li img{
          height: 75px;
      }
      
      footer section > ul > li{
          width: 25%;
      }
      
      footer section > ul > li:last-child{
          width: 100%;
          padding: 40px 0 0 0;
      }
      
      footer section > ul > li:last-child > ul:last-child{
          padding: 0 30%;
      }
  }
  
  @media screen and (max-width: 1024px){
    .desktop-hide-header {
        display: none !important;
      }
      .mobile-hide-header {
        display: block !important;
      }
      header a.nav-mob {
        display: block;
    }
      header ul.main-nav {
        display: none;
        margin: 0;
        border-top: 1px solid #FFF;
        width: 100%;
    }
      header ul.main-nav li {
        width: 100%;
        box-sizing: border-box;
        padding: 0;
      }
      header ul.main-nav li a {
        line-height: 60px;
        text-align: left;
      }
  }

  @media screen and (max-width: 767px){
    .flex-item-right, .flex-item-left {
		flex: 100%;
        justify-content: center;
	}
    header section{
        padding: 0 16px;
    }
    .dropdown-for {
      max-height: unset;
    }
    .swal-public {
        width: 91%;
        margin: auto;
        transform: none;
        padding: 0 24px 24px;
        top: 50%;
        h2 {
            font-size: 18px;
            line-height: 27px;
        }
        p {
            font-size: 14px;
            line-height: 21px;
        }
        .sa-button-container {
            text-align: center;
            margin-top: 14px;
            button {
                width: 100%;
                background: #DB334D;
                border-radius: 6px;
                font-size: 16px;
                line-height: 24px;
                padding: 12px 0;
            }
            button:hover {
                background: #DB334D;
            }
            .cancel {
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                padding: 12px 0;
            }
            .cancel:hover {
                background: transparent;
            }
        }
        .sa-confirm-button-container {
          width: 100%;
        }
    }
    .force-hide-mobile {
      display: none !important;
    }
    .error-404 {
      padding: 0;
    }
    .error-404 > div {
        width: 100%;
    }
    .text-404 {
      text-align: center;
      label {
        font-size: 32px;
        line-height: 48px;
      }
      span {
        margin-top: 40px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
      }
      p {
        margin-bottom: 32px;
        font-size: 14px;
        line-height: 21px;
      }
      a {
        padding: 12px 0;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .for-soc {margin-top: 12px !important;}
      .overlay-header {
          display: none;
      }
      .desktop-hide {
        display: none !important;
      }
      .mobile-hide {
        display: block !important;
      }

      .desktop-flex-hide {
        display: none !important;
      }

      .mobile-flex-hide {
        display: inline-flex !important;
      }
  
      .padding-top-mobile {
          padding-top: 32px !important;
      }
      
      .pad-top{
          height: 120px;
      }
  
      .top-header {
          font-size: 12px;
          line-height: 18px;
          height: auto;
      }
  
      .top-header section {
          padding: 11px 10px;
          text-align: center;
      }
  
      header {
          position: relative;
      }
  
      header a.logo {
          padding: 15px 0;
      }
      
      header a.logo img{
          height: 30px;
      }
      
      header.on-scroll a.logo img{
          height: 30px;
      }
      
      header a.nav-mob{
          display: block;
          line-height: 60px;
      }
      
      header ul.main-nav{
          display: none;
          margin: 0;
          border-top: 1px solid #FFF;
          width: 100%;
      }
  
      header ul.main-nav.greenhouse-nav{
          width: 100%;
      }
      
      header.on-scroll ul.main-nav{
          border-top: 1px solid #CCC;
      }
      
      header ul.main-nav li:nth-child(7),
      header ul.main-nav li:nth-child(8){
          width: 100%;
      }
      
      header ul.main-nav li a,
      header.on-scroll ul.main-nav li a{
          line-height: 60px;
          font-size: 14px;
          text-align: left;
      }
      
      header ul.main-nav > li:nth-child(6) > a,
      header ul.main-nav > li:nth-child(7) > a{
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          padding: 12px 0;
          margin: 10px 0;
          text-align: center;
      }

      header ul.main-nav li:nth-child(7) > a{
        margin-bottom: 40px;
      }

      header ul.main-nav li:nth-child(2) a i{
        float: right;
        margin-top: 20px;
      }
      
      header.on-scroll ul.main-nav > li:nth-child(6) a,
      header.on-scroll ul.main-nav > li:nth-child(7) a{
          margin: 10px 0;
          line-height: inherit;
      }

      .drop-btn-lang {
        i {
          float: right;
          margin-top: 20px;
        }
      }

      .drop-btn-account {
        i {
            float: right;
            margin-top: 20px;
          }
      }

      .dropdown-lang {
        position: relative;
        margin-top: 0;
        background-color: transparent;
        min-width: auto;
        box-shadow: none;
        z-index: unset;
        border-radius: 0;
        div {
          padding: 7px 9px;
          margin-bottom: 8px;
        }
      }
      
      .dropdown-tech {
        position: relative;
        left: unset;
        transform: none;
        padding: 0 0 0 16px;
        background: transparent;
        width: 100%;
        border-radius: 0;
      }

      .dropdown-tech > div {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        color: #1A234B;
        padding: 0;
        border-radius: 5px;
        cursor: pointer;
        img {
          padding-right: 5px;
        }
        .div-text-container {
          h3 {
            color: #1A234B;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            margin: 0 0 8px;
          }
          a {
            display: flex;
            width: auto;
            border: 0;
            color: #C04051;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            padding: 0;
            border-radius: 0;
            .fa-chevron-right {display: none;}
            .fa-arrow-right {
              display: inline-block;
              margin-left: 8px;
              margin-top: 3px;
              font-size: 11px;
              vertical-align: unset;
            }
          }
          hr {
            margin-top: 10;
            margin-bottom: 10px;
            border-top: 1px solid #E8E8E8;
            opacity: 1;
          }
        }
      }
      
      .dropdown-account {
        position: relative;
        margin-top: 0;
        background-color: transparent;
        min-width: auto;
        box-shadow: none;
        z-index: unset;
        border-radius: 0;
        margin-left: 0;
        width: auto;
        > div {
          padding: 7px 8px;
          margin-bottom: 8px;
        }
        .account-info {
            margin-bottom: 0;
        }
      }

      .dropdown-for {
        position: relative;
        margin-top: 0;
        background-color: transparent;
        min-width: auto;
        box-shadow: none;
        z-index: unset;
        border-radius: 0;
        div {
          padding: 12px 0 12px 16px;
          span {
            font-size: 14px;
            line-height: 15px;
          }
          p {
            font-size: 12px;
            line-height: 18px;
          }
          i {
            margin-top: 3px !important;
          }
        }
      }

      header ul.for-nav {
        padding: 0;
        background: #FFF;
        section {
          padding: 0;
        }
        li > a {
          margin-bottom: 32px;
        }
        li:last-child > a {
          margin-bottom: 0;
        }
      }
      
      header ul.for-nav li a span{
          font-size: 12px;
          line-height: 16px;
      }
      
      header ul.for-nav li a span i{
          font-size: 26px;
          line-height: 26px;
      }

      footer section > ul > li:first-child {
        font-size: 12px;
        line-height: 18px;
      }

      // mobile resp new solution page
      .section-top-solution {
        padding-top: 0 !important;
        > .flex-container {
            flex-direction: column-reverse !important;
        }
      }
      .section-top-solution.double-triangle {
        &.navy {
            .flex-container {
                padding-bottom: unset;
            }
        }
      }
  }
  
  @media screen and (max-width: 767px) and (orientation: landscape){
      header ul.main-nav li{
          width: 25%;
          box-sizing: border-box;
      }
      
      ul.feature li img{
          height: 75px;
      }
      
      footer section > ul > li{
          width: 25%;
      }
      
      footer section > ul > li:last-child{
          width: 100%;
          padding: 40px 0 0 0;
      }
      
      footer section > ul > li:last-child > ul:last-child{
          padding: 0 30%;
      }
  }
  
  @media screen and (max-width: 767px) and (orientation: portrait){
      header ul.main-nav li{
          width: 100%;
          box-sizing: border-box;
          padding: 0;
      }
      
      ul.feature li{
          width: 100%;
          text-align: center;
      }
  
      ul.feature li p{
          text-align: center;
          font-size: 12px;
          line-height: 18px;
      }
      
      ul.feature li img{
          height: 75px;
          margin-bottom: 15px;
      }
      
      header ul.for-nav li{
          width: 100%;
          padding: 0;
          margin: 0;
          a > div > a {
            display: flex !important;
            margin: 0 !important;
            margin-top: 12px !important;
          }
          img {
            width: 48px !important;
            height: 48px !important;
            align-self: start;
            align-self: flex-start;
            margin-right: 12px !important;
          }
          span{
            vertical-align: top !important;
            font-size: 14px !important;
            line-height: 21px !important;
          }
          p{
            padding-right: 0 !important;
            margin-top: -20px !important;
            font-size: 12px !important;
            line-height: 18px !important;
          }
          .fa-arrow-right {
            font-size: 12px !important;
            margin: 0 0 0 6px !important;
          }
      }
      
      footer section > ul{
          margin: -20px 0;
      }
      
      footer section > ul > li{
          width: 100%;
          padding: 20px 0;
          font-size: 14px;
          line-height: 21px;
      }
  
      footer section > ul > li > ul > li > a{
          font-size: 14px;
          line-height: 21px;
      }

      footer section > ul > li > div > ul > li > a{
          font-size: 14px;
          line-height: 21px;
      }
      
      footer section > ul > li:nth-child(odd){
          /*clear: both;*/
          width: 100%;
      }
  
      footer section > ul > li:nth-child(2){
          /*clear: both;*/
          width: 100%;
          font-weight: bold;
          font-size: 14px;
      }
      
      footer section > ul > li:last-child{
          width: 100%;
          padding: 20px 0;
      }

      footer section > ul > li:last-child > img{
        width: 64px;
      }
      
      footer .the-copy{
          font-size: 12px;
          line-height: 18px;
          section {
            padding: 16px 0 0;
          }
      }
      
      footer .the-copy a{
          float: unset;
          display: block;
          margin: 5px 0;
          font-size: 12px;
          line-height: 18px;
      }
  }
  
  /*
  @media screen and (min-width: 1025px) and (max-width: 1600px){}
  
  @media screen and (min-width: 768px) and (max-width: 1024px){}
  
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}
  
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}
  
  @media screen and (max-width: 767px){}
  
  @media screen and (max-width: 767px) and (orientation: landscape){}
  
  @media screen and (max-width: 767px) and (orientation: portrait){}
  */