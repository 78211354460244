/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Sep 23, 2019
UPDATE DATE: Sep 23, 2019
REVISION: 1
NAME: astronaut.faq-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#faq {
.banner{
    position: relative;
    overflow: hidden;
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/faq.jpg?v=1) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

.ban-text{
    color: #FFF;
    position: relative;
    padding: 140px 0 50px 0;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    z-index: 2;
}

.ban-text .big-text{
    display: block;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
}

.ban-text .big-text em{
    font-weight: bold;
    position: relative;
    font-style: normal;
}

.search-faq{
    position: relative;
    width: 55%;
    margin: 20px auto;
}

.search-faq input{
    height: 55px;
    font-size: 18px;
    border: none;
    padding: 15px 55px 15px 15px;
    border-radius: 5px;
}

.search-faq input:hover{
    box-shadow: 0 0 5px rgba(255, 255, 255, .5);
}

.search-faq input:focus{
    outline: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.search-faq button{
    position: absolute;
    opacity: .5;
    pointer-events: none;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 35px;
    border: none;
    color: #FFF;
    font-size: 18px;
    text-align: center;
    border-radius: 100px;
    box-shadow: 0 0 0 #564FDB;
    background: rgb(86,79,219);
    background: -moz-linear-gradient(-45deg,  rgba(86,79,219,1) 0%, rgba(132,79,219,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(86,79,219,1) 0%,rgba(132,79,219,1) 100%);
    background: linear-gradient(135deg,  rgba(86,79,219,1) 0%,rgba(132,79,219,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#564fdb', endColorstr='#844fdb',GradientType=1 );
}

.search-faq button:hover{
    box-shadow: 0 0 10px #564FDB;
}

.search-faq button i{
    
}

.sec-faq{
    box-shadow: inset 0 -1px 0 #CCC;
}

.sec-faq section{
    padding: 0 20px;
}

.sec-faq section ul{
    overflow: auto;
    margin: 0 -10px;
}

.sec-faq section ul li{
    float: left;
    width: 33.33%;
    padding: 0 10px;
    box-sizing: border-box;
}

.sec-faq section ul li a{
    display: block;
    text-align: center;
    color: #999;
    padding: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sec-faq section ul li a:hover{
    color: #666;
    box-shadow: inset 0 -5px 0 #666;
}

.sec-faq section ul li a.is-on{
    color: #564FDB;
    box-shadow: inset 0 -5px 0 #564FDB;
}

.sec-faq section ul li a img{
    display: block;
    margin: 0 auto 10px auto;
    width: 100px;
    height: 100px;
}

.misc-content{
    padding: 20px 20px 20px 20px;
}

.faq-empty{
    padding: 20px 0;
    text-align: center;
    color: #999;
}

.faq-empty span{
    display: block;
    margin: 0 0 20px 0;
    color: rgba(86, 79, 219, .25);
    font-size: 70px;
    line-height: 70px;
}

.faq-empty span img{
    opacity: .5;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

ul.list-faq{
    
}

ul.list-faq > li{
    border-bottom: 1px solid #CCC;
}

ul.list-faq > li > a{
    display: block;
    position: relative;
    padding: 20px 0 20px 26px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #666;
}

ul.list-faq > li a:hover{
    color: #564FDB;
}

ul.list-faq > li a i{
    position: absolute;
    left: 0;
    top: 24px;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

ul.list-faq > li a.is-on i{
    transform: rotate(180deg);
}

ul.list-faq > li div{
    margin: -20px 0 0 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    line-height: 20px;
}

ul.list-faq > li div a,
.cant a{
    font-weight: bold;
}

ul.list-faq > li div a:hover,
.cant a:hover{
    color: #844fdb;
}


ul.list-faq > li div p{
    margin: 10px 0;
}

ul.list-faq > li ul{
    list-style: disc;
    margin: 5px 0;
    padding: 0 0 0 20px;
}

ul.list-faq > li ul.use-num{
    list-style: decimal;
}

ul.list-faq > li ul > li{
    padding: 5px 0;
}

.cant{
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .sec-faq section ul li a img{
        width: 80px;
        height: 80px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .search-faq{
        width: 60%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .search-faq{
        width: 80%;
    }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
    .ban-text{
        padding: 90px 0 0 0;
    }
    
    .search-faq{
        width: 80%;
    }
    
    .sec-faq section ul li a img{
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text{
        padding: 110px 0 20px 0;
    }
    
    .search-faq{
        width: 100%;
    }
    
    .sec-faq section ul li a{
        font-size: 14px;
        line-height: 14px;
        padding: 20px 0;
    }
    
    .sec-faq section ul li a img{
        width: 50px;
        height: 50px;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}

.ReactModalPortal {
    *, *:hover, *:focus {
        outline: none !important;
    }
    .button {
        font-size: 12px;
        line-height: 16px;
        border-radius: 3px;
        padding: 6px 10px;
        font-weight: 200;
        border-width: 3px;
        background-color: #828282;
        border-color: transparent;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        width: auto;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        transition: all 300ms ease;
    }
    .button__secondary {
        background-color: transparent;
        border-color: #564FDB;
        color: #564FDB;
    }
    .button--small {
        font-size: 12px;
        line-height: 16px;
        border-radius: 3px;
        padding: 6px 10px;
    }
    .Modal {
        position: absolute;
        // top: 40px;
        // left: 40px;
        // right: 40px;
        // bottom: 40px;
        background-color: transparent;
        display:block;
        justify-content:center;
        align-items:center;
        ::-webkit-media-controls {
          display:none !important;
        }
        video::-webkit-media-controls-enclosure {
          display:none !important;
        }
        video::-webkit-media-controls {
            opacity: 0 !important;
            display: none !important;
            -webkit-appearance: none !important;
        }
        video::-webkit-media-controls-timeline {
            display: none !important;
            -webkit-appearance: none !important;
        }
        video::-webkit-media-controls-volume-slider {
            display:none !important;
            -webkit-appearance: none !important;
        }

        video::-webkit-media-controls-mute-button {
            display:none !important;
            -webkit-appearance: none !important;
        }
        video::-webkit-media-controls-current-time-display {
            display:none !important;
            -webkit-appearance: none !important;
        }
        video::-webkit-media-controls-time-remaining-display {
            display:none !important;
            -webkit-appearance: none !important;
        }
        video::-webkit-media-controls-fullscreen-button {
            display:none !important;
            -webkit-appearance: none !important;
        }
        .video-faq-body {
            width: 100%;
        }
        .play-button-video-faq {
            display: inline-block;
            position: absolute;
            width: auto;
            margin-left: -96%;
            margin-top: 47%;
            background-color: black;
            color: white;
            border-radius: 15px;
            border: 0;
            padding: 3px 6px;
        }
    }

    .Overlay {
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display:flex;
        justify-content:center;
        align-items:center;
        height: 100%;
    }

    .welcome-video-modal .content {
        position:relative;
        display:inline-block;
    }
    .welcome-video-modal .content > .custom-button {
        position:absolute;
        border-radius: 50%;
        background-color:#000;
        color:#fff;
        height:28px;
        z-index:10000;
        bottom:25px;
        margin-left: -89%;
    }
    .modal {
        .modal-header {
            border-bottom: 0;
        }
        .modal-content {
            background-color: transparent;
            box-shadow:none;
            border: 0;
        }
        button.close {
            -webkit-appearance: none;
            padding: 0;
            cursor: pointer;
            background: 0 0;
            border: 0;
        }
        .close {
            float: right;
            font-size: 21px;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            opacity: .2;
        }
    }

    .welcome-video-modal {
      background-color: transparent;
      max-width: 2000px !important;
      max-height: 2000px !important;
      outline: 0;
      @media screen and (max-width: 1500px) {
        max-width: 850px !important;
      }
      @media screen and (max-width: 1024px) {
        max-width: 640px !important;
      }
      @media screen and (max-height: 768px) {
        max-height: 640px !important;
      }
      .content {
        background-color: white;
        max-height: 100%;
        padding: 15px !important;
      }

      .modal-footer {
        border-top: 0;
      }

      .close-button {
        background-color: white;
        width: 100px;
        height: 40px;
        font-size: 16px;
        align-items: center;
        align-content: center;
        text-align: center;
        margin: 0 auto;
      }
    }

    .img-option {
        max-width: 300px;
        max-height: 240px;
        cursor: zoom-in;
    }

    .img-question {
        max-width: 500px;
        max-height: 240px;
        cursor: zoom-in;
    }

    .invisible {
        visibility: hidden;
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}