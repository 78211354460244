/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Aug 1, 2019
UPDATE DATE: Aug 1, 2019
REVISION: 1
NAME: astronaut.for-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#for {
.ban-img.for-camp{
    background: url(../img/stock-for/campuses.jpg) center top no-repeat;
    background-size: cover;
}

.ban-img.for-pro{
    background: url(../img/stock-for/professionals.jpg) center top no-repeat;
    background-size: cover;
}

.ban-img.for-tech{
    background: url(../img/stock-for/technical.jpg) center top no-repeat;
    background-size: cover;
}

.ban-img.for-remote{
    background: url(../img/stock-for/remote.jpg) center top no-repeat;
    background-size: cover;
}

.ban-img.for-global{
    background: url(../img/stock-for/global.jpg) center top no-repeat;
    background-size: cover;
}

.ban-img.for-api{
    background: url(../img/stock-for/partner.jpg) center top no-repeat;
    background-size: cover;
}

.ban-text{
    padding: 190px 10% 100px 10%;
    text-align: center;
}

.ban-text .big-text{
    font-size: 50px;
    line-height: 62px;
    font-weight: 100;
}

.ban-text ul{
    overflow: auto;
    padding: 0 0 20px 0;
    margin: 15px -10px -20px -10px;
}

.ban-text ul li{
    float: left;
    width: 33.33%;
    padding: 20px 10px;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    box-sizing: border-box;
}

.ban-text ul li .icon-ban{
    width: 100px;
    height: 100px;
    color: #564FDB;
    font-size: 40px;
    line-height: 100px;
    border-radius: 100px;
    background: rgba(255, 255, 255, .75);
    margin: 10px auto 20px auto;
    box-shadow: 0 0 30px rgba(255, 255, 255, .4);
    animation: icon-anim 3.5s infinite ease;
    -webkit-animation: icon-anim 3.5s infinite ease;
}

.ban-text ul li:nth-child(1) .icon-ban{
    animation-delay: 0s;
}

.ban-text ul li:nth-child(2) .icon-ban{
    animation-delay: 0.4s;
}

.ban-text ul li:nth-child(3) .icon-ban{
    animation-delay: 0.8s;
}

@keyframes icon-anim{
    0% {background: rgba(255, 255, 255, .75); box-shadow: 0 0 30px rgba(255, 255, 255, .4); transform: scale(1, 1);}
    50% {background: rgba(255, 255, 255, 1); box-shadow: 0 0 20px rgba(255, 255, 255, .2); transform: scale(1.2, 1.2);}
    100% {background: rgba(255, 255, 255, .75); box-shadow: 0 0 30px rgba(255, 255, 255, .4); transform: scale(1, 1);}
}

@-webkit-keyframes icon-anim{
    0% {background: rgba(255, 255, 255, .75); -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, .4); -webkit-transform: scale(1, 1);}
    50% {background: rgba(255, 255, 255, 1); -webkit-box-shadow: 0 0 20px rgba(255, 255, 255, .2); -webkit-transform: scale(1.2, 1.2);}
    100% {background: rgba(255, 255, 255, .75); -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, .4); -webkit-transform: scale(1, 1);}
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .ban-text{
        padding: 190px 5% 100px 5%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .ban-text{
        padding: 190px 0 100px 0;
    }
}

@media screen and (max-width: 767px){
    .ban-text{
        padding: 100px 0 30px 0;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text .big-text{
        font-size: 40px;
        line-height: 52px;
    }
    
    .ban-text .big-text i{
        display: block;
        font-size: 50px;
        line-height: 50px;
    }
    
    .ban-text ul{
        margin: 15px -10px -10px -10px;
    }
    
    .ban-text ul li{
        width: 100%;
        padding: 15px;
    }
    
    .ban-text ul li .icon-ban{
        width: 90px;
        height: 90px;
/*        color: #564FDB;*/
        font-size: 38px;
        line-height: 90px;
        border-radius: 90px;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}