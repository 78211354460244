/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 19, 2019
UPDATE DATE: Oct 19, 2019
REVISION: 1
NAME: astronaut.about-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#about {
    background: #EFF0F6;
.banner{
    position: relative;
    overflow: hidden;
}

.ban-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: url(../img/banner/about.jpg?v=1) center top no-repeat;
    background-size: cover;
    z-index: 0;
}

.ban-text{
    color: #18244E;
    position: relative;
    padding: 190px 52% 240px 0;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
}

.ban-text a.vid-msg{
    cursor: default;
    display: block;
    color: #18244E;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 0 30px 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
}

.ban-text a.vid-msg img{
    display: block;
    margin: 0 0 10px 0;
    width: 100%;
}

.ban-text .big-text{
    display: block;
    font-size: 48px;
    line-height: 72px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.ban-text .big-text em{
    font-weight: bold;
    color: #DB334D;
    position: relative;
    font-style: normal;
}

.ban-text .big-text em::after{
    content: ".";
    color: #18244E;
}

.ban-text .ban-link{
    margin: 15px 0 0 0;
}

.ban-text .ban-link a{
    font-weight: bold;
    color: #DB334D;
}

.ban-text .ban-link a.try{
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    padding: 14px 32px;
    margin: 0 48px 0 0;
    border-radius: 6px;
    background: #DB334D;
    color: #FFF;
    border: 2px solid #FFF;
}

.span-cc {
    margin-top: 24px;
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #494CA1
}

.the-quote{
    position: relative;
    overflow: visible;
    max-width: 750px;
    margin: -96.5px auto 0 auto;
    padding: 0 20px;
}

.the-quote div{
    background: #FFF;
    padding: 40px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, .1);
}

.the-quote img{
    float: left;
    width: 112px;
    height: 112px;
    border-radius: 112px;
    object-fit: cover;
    margin: 0 20px 0 0;
}

.the-quote div span{
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0 0 0;
}

.tit-about{
    font-size: 40px;
    line-height: 34px;
    margin: 0 0 50px 0;
    text-align: center;
    font-weight: bold;
    color: #18244E;
}

.tit-about span{
    display: block;
    margin: 17px 0 0 0;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #18244E;
}

ul.the-care{
    overflow: auto;
    margin: -10px;
    padding: 0 0 30px 0;
}

ul.the-care li{
    position: relative;
    float: left;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 10px 10px 42px;
    width: 33.33%;
    box-sizing: border-box;
}

ul.the-care li span{
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

ul.the-care li i{
    position: absolute;
    font-size: 24px;
    top: 10px;
    left: 10px;
    color: #564FDB;
}

.pad-plus{
    padding: 60px 20px;
    text-align: center;
}

ul.the-team{
    overflow: auto;
    margin: -20px auto -40px auto;
    max-width: 900px;
    padding: 0 0 20px 0;
}

ul.the-team li{
    position: relative;
    float: left;
    width: 33.33%;
    padding: 20px;
    box-sizing: border-box;
}

ul.the-team li img{
    display: block;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, .3);
    transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
}

ul.the-team li img.img-2{
    position: absolute;
    opacity: 0;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    box-shadow: none;
}

ul.the-team li:hover img.img-2{
    opacity: 1;
}

ul.the-team li .team-detail{
    position: absolute;
    color: #FFF;
    bottom: 20px;
    left: 20px;
    right: 20px;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    background: rgba(86, 79, 219, .5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 0 0 20px 20px;
    transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
}

ul.the-team li:hover .team-detail{
    bottom: 0;
    left: 0;
    right: 0;
}

ul.the-team li .team-detail span{
    display: block;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}

ul.the-team li .team-detail strong{
    display: block;
    margin: 5px 0 0 0;
    font-size: 12px;
    line-height: 16px;
    opacity: 0;
    max-height: 0;
    transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
}

ul.the-team li:hover .team-detail strong{
    opacity: 1;
    max-height: 100px;
}

ul.the-team li .team-detail a{
    display: block;
    margin: 5px 0 0 0;
    color: #FFF;
    opacity: 0;
    max-height: 0;
    transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
}

ul.the-team li:hover .team-detail a{
    opacity: 1;
    max-height: 100px;
}

ul.the-team li .team-detail a:hover{
    text-shadow: 0 5px 5px #564FDB;
}

.sec-opor{
    max-width: 55%
}

.vid-tit{
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

.the-vid{
    width: 100%;
    margin: 20px 0;
    border-radius: 20px;
    outline: 0;
}

.vid-desc{
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10%;
}

.btn-see-details {
    outline: 0;
    cursor: pointer;
    display: block;
    width: 150px;
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
    height: 45px;
    border: none;
    color: #FFF;
    font-size: 16px;
    border-radius: 100px;
    box-shadow: 0 0 0 #564FDB;
    background: rgb(86,79,219);
    background: linear-gradient(135deg, rgba(86,79,219,1) 0%,rgba(132,79,219,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#564fdb', endColorstr='#844fdb',GradientType=1 );
}

a.request-demo-logged{
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    padding: 14px 32px;
    margin: 0 48px 0 0;
    border-radius: 6px;
    border: 2px solid #ED2453;
    background: transparent;
    color: #DB334D;
    font-weight: bold;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){
    ul.the-team li .team-detail strong,
    ul.the-team li .team-detail a{
        opacity: 1;
        max-height: 100px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .ban-text{
        padding: 150px 10%;
    }
    
    .sec-opor{
        max-width: 75%
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .ban-text{
        padding: 150px 0;
    }
    
    ul.the-care li{
        width: 50%;
    }
    
    ul.the-team{
        padding: 0 0 30px 0;
    }
    
    ul.the-team li{
        padding: 10px;
    }
    
    ul.the-team li .team-detail{
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
    
    .sec-opor{
        max-width: 1200px;
    }
    
    .vid-desc{
        padding: 0;
    }
}

@media screen and (max-width: 767px){
    .ban-text{
        font-size: 14px;
        line-height: 22px;
    }
    
    .ban-text .big-text {
        font-size: 26px;
        line-height: 34px;
    }
    
    ul.the-team li .team-detail strong,
    ul.the-team li .team-detail a{
        opacity: 1;
        max-height: 100px;
    }
    
    ul.the-team{
        padding: 0 0 30px 0;
    }
    
    ul.the-team li{
        padding: 10px;
    }
    
    ul.the-team li .team-detail{
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
    
    .sec-opor{
        max-width: 1200px;
    }
    
    .vid-desc{
        padding: 0;
    }
    .pad-plus{
        padding: 0px 16px 60px;
    }
    a.request-demo-logged{
        width: 100%;
        text-align: center;
        margin: 0;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){
    .ban-text{
        padding: 70px 0 90px 0;
    }
    
    .the-quote img{
        width: 130px;
        height: 130px;
    }
    
    ul.the-care li{
        width: 50%;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait){
    .ban-text{
        padding: 100px 0;
    }
    
    .the-quote img{
        display: block;
        float: none;
        margin: 0 auto 10px auto;
        width: 100px;
        height: 100px;
    }
    
    ul.the-care li{
        width: 100%;
    }
    
    ul.the-team li{
        width: 100%;
    }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

.sec-con{
    padding: 150px 20px;
    color:#18244E;
}

.sec-con ul{}

.sec-con ul li{}

.sec-con ul li ul{
    overflow: auto;
    margin: -150px -10px -150px -10px;
    padding: 200px 0;
}
// angga change 20 px 
.sec-con ul li ul li{
    position: relative;
    font-size: 17px;
    line-height: 30px;
    padding: 1px 20px;
    float: right;
    font-weight: bold;
    box-sizing: border-box;
}

.sec-con ul li ul.on-right li{
    float: left;
}

.sec-con ul li ul.on-left li:first-child{
    width: 50%;
}

.sec-con ul li ul.on-left li:last-child{
    text-align: left;
    width: 50%;
}

.sec-con ul li ul.on-right li:first-child{
    width: 50%;
}

.sec-con ul li ul.on-right li:last-child{
    width: 50%;
}

.sec-con ul li ul li label{
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #DB334D;
    margin: 0 0 8px 0;
}

.sec-con ul li ul li span{
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0 0 0;
}

.sec-con ul li ul li > img{
    position: absolute;
    display: block;
    z-index: 1;
    top: -130px;
    height: 465px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.sec-con ul li ul li button{
    cursor: pointer;
    display: inline-block;
    background: transparent;
    margin-top: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #18244E;
    width: auto;
    border: 2px solid #18244E;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 14px 32px;
}

.sec-con ul li:nth-child(2) ul li img{
    height: 260px;
}

.sec-con ul li:nth-child(3) ul li img{
    top: 10px;
}

.sec-con ul li ul li .square-pers{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 300px;
    height: 300px;
    background: #FFF;
    border-radius: 50px;
    margin: -75px auto 0 auto;
    perspective: 50em;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, .1);
    animation: shad-anim 4s infinite ease;
    -webkit-animation: shad-anim 4s infinite ease;
}

.sec-con ul li:nth-child(1) ul li .square-pers{
    animation-delay: 0s;
}

.sec-con ul li:nth-child(2) ul li .square-pers{
    animation-delay: 0.3s;
}

.sec-con ul li:nth-child(3) ul li .square-pers{
    animation-delay: 0.6s;
}
    .tit-about{
        padding-top: 155px;
        color: #18244E;
        h1{
            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
            text-align: center;
            padding-left: 16px;
            margin-right: 16px;
        }
        span{
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .sec-history{
        padding-top: 64px;
        padding-bottom: 98px;
        .history{
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 2px rgba(24, 36, 78, 0.1);
            border-radius: 10px;   
            h1{
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                color: #18244E;
                margin-top: 80px;
                margin-bottom: 32px;
                padding-left: 32px;
                padding-right: 32px;
            }
            img{
                float: right;
                width: 100%;
                border-radius: 0px 10px 10px 0px;
            }
            p{
                padding-left: 32px;
                padding-right: 32px;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: #18244E;
                margin-bottom: 48px;
            }
            
        }
    }
    .sec-team{
        padding-top: 48px;
        padding-bottom: 80px;
        section{
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .team-gallery{
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: center;
            color: #18244E;
            .title-meet-people{
                margin-bottom: 56px;
                h1{
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 72px;
                    margin-bottom: 24px;
                    margin-top:0px;
                }
                span{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
            
            .gallery{
                margin-bottom: 70px;
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
                .main-grid {
                    display: grid;
                    margin: auto;
                    min-height: 146px;
                    max-height: 556px;
                    grid-template-columns: auto auto ;
                    background-color: none;
                    .left-grid{
                        grid-column:1;
                        grid-row: 1;
                        grid-auto-rows: max-content;
                        grid-auto-columns: max-content;
                    }
                    .right-grid{
                        width: 100%;
                        // margin: auto;
                        grid-column: 2;
                        grid-row: 1;
                        grid-template-columns: 1fr;
                        .item-grid{
                            margin: 0px;
                            padding: 0px;
                            display: grid;
                            grid-template-columns: 1fr 1fr ;
                        }
                    }
                    img{
                        height: 100%;
                        padding: 10px;
                    }
                }
            }
        }
        
        .leadership{
            .title-leadership{
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #18244E;
                margin-bottom: 24px;
            }
            .team-panel{
                padding: 31px 16px;
                font-family: 'OpenSans';
                font-style: normal;
                height: 575px;
                .team-image{
                    height: 280px;
                    img{
                        vertical-align: bottom;
                        height: 100%;
                    }
                }
                .team-detail{
                    text-align: center;
                    .team-name{
                        width: 100%;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 36px;
                        color: #18244E;
                        margin-bottom: 8px;
                    }
                    .team-position{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: #18244E;
                        text-transform: uppercase;
                    }
                    .team-description{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: #18244E;
                        margin-top: 24px;
                    }
                }
                .bg-1{
                    background: #564FDB;
                }
                .bg-2{
                    background: #479AFF;
                }
                .bg-3{
                    background: #DB334D;
                }
                .bg-4{
                    background: #CFCFCF;
                }
                .bg-5{
                    background: #C2DDFF;
                }
                .bg-6{
                    background: #DB334D;
                }
                .bg-7{
                    background: #A7CFFF;
                }
            }
        }
    }
    .about-people{
        section{
            padding-top: 64px;
            padding-bottom: 83px;
        }
        .tit-about-people{
            text-align: center;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 72px;
                text-align: center;
                color: #18244E;
                padding-top: 0px;
                margin-top:0px;
                margin-bottom:0px;
            }
            h3{
                margin-top: 20px;
                margin-bottom: 30px;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
            }
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #18244E;
                margin-bottom: 25px;
            }
        }
        .talent-pool{
            margin-top:50px;
            padding: 36px 100px;
            background: #18244E;
            border-radius: 6px;
            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #FFFFFF;
            h1{
                margin-top: 0px;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #FFFFFF;
            }
            p{
                margin: 12px;
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
            }
            .btn{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                background: #18244E;
                width: auto;
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
                border-radius: 6px;
                padding: 14px 32px;
            }
        }
    }
    #sec-request-demo{
        width:100%;
        max-width: 100%;
    }
    .request-demo{
        width:100%;
        background: #F3F2FF;
        padding: 64px 97px;
        h1{
            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 72px;
            /* or 150% */

            display: flex;
            align-items: center;

            color: #18244E;
        }
        p{
            /* Text / Medium */

            font-family: 'OpenSans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height, or 150% */

            display: flex;
            align-items: center;

            color: #18244E;
            margin-bottom: 30px;
        }
        .btn-start-trial{
            padding: 14px 32px;
            height: 55px;
            background: #DB334D;
            border-radius: 6px;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
        }
        .btn-request-demo{
            padding: 14px 60px;
            height: 55px;
            background: transparent;
            color: #DB334D;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
        }
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #494CA1;
            margin-top:15px ;
        }

        .img-request-demo{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            border-radius: 6px;
        }
    }
    
    @media screen and (max-width: 767px) and (orientation: portrait){
        .tit-about{
            padding-top: 2px;
            color: #18244E;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                padding-left: 16px;
                margin-right: 16px;
            }
            span{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .sec-history{
            padding-top: 32px;
            padding-bottom: 48px;
            .history{
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 2px rgba(24, 36, 78, 0.1);
                border-radius: 10px;
                h1{
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #18244E;
                    margin-top: 16px;
                    margin-bottom: 24px;
                    padding-left: 32px;
                    padding-right: 32px;
                }
                img{
                    width: 100%;
                    border-radius: 0px 0px 10px 10px;
                }
                p{
                    padding-left: 32px;
                    padding-right: 32px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #18244E;
                    margin-bottom: 48px;
                }
                
            }
        }
        

        .sec-team{
            padding-top: 32px;
            padding-bottom: 80px;
            section{
                padding-top: 0px;
                padding-bottom: 0px;
            }
            
            .team-gallery{
                padding-top: 15px;
                padding-bottom: 15px;
                text-align: center;
                color: #18244E;
                .title-meet-people{
                    margin-bottom: 24px;
                    h1{
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 12px;
                        margin-top:0px;
                    }
                    span{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
                
                .gallery{
                    margin-bottom: 53px;
                    display: inline-block;
                    margin-left: auto;
                    margin-right: auto;
                    .main-grid {
                        display: grid;
                        margin: auto;
                        height: 150px;
                        grid-template-columns: auto auto ;
                        background-color: none;
                        .left-grid{
                            height: 150px;
                            grid-column:1;
                            grid-row: 1;
                            grid-auto-rows: min-content;
                            grid-auto-columns: min-content;
                            img{
                                height: 150px;
                            }
                        }
                        .right-grid{
                            width: 100%;
                            height: 150px;
                            grid-column: 2;
                            grid-row: 1;
                            grid-template-columns: 1fr;
                            grid-auto-flow: column dense;
                            .item-grid{
                                margin: 0px;
                                padding: 0px;
                                display: grid;
                                grid-template-columns: auto auto ;
                                img{
                                    height: 75px;
                                    vertical-align: middle;
                                    float: left;
                                }
                            }
                        }
                        img{
                            padding: 3px;
                        }
                    }
                }
            }
            
            .leadership{
                .title-leadership{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: #18244E;
                    margin-bottom: 24px;
                }
                .team-panel{
                    padding: 24px 8px;
                    font-family: 'OpenSans';
                    font-style: normal;
                    height: auto;
                    .team-image{
                        height: auto;
                        img{
                            vertical-align: bottom;
                            height: 100%;
                        }
                    }
                    .team-detail{
                        text-align: center;
                        .team-name{
                            width: 100%;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 27px;
                            color: #18244E;
                            margin-bottom: 8px;
                        }
                        .team-position{
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: #18244E;
                        }
                        .team-description{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            color: #18244E;
                            margin-top: 24px;
                        }
                    }
                    .bg-1{
                        background: #564FDB;
                    }
                    .bg-2{
                        background: #479AFF;
                    }
                    .bg-3{
                        background: #DB334D;
                    }
                    .bg-4{
                        background: #CFCFCF;
                    }
                    .bg-5{
                        background: #C2DDFF;
                    }
                }
            }
        }

        .request-demo{
            section{
                padding-top: 32px;
                padding-bottom: 32px;
                padding-left: 16px;
                padding-right: 16px;
            }
            width:100%;
            background: #F3F2FF;
            padding: 0;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
    
                color: #18244E;
            }
            p{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
    
                color: #18244E;
                margin-bottom: 30px;
            }
            .btn-start-trial{
                padding: 14px 32px;
                height: 55px;
                background: #DB334D;
                border-radius: 6px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                align-items: center;
            }
            .btn-request-demo{
                padding: 14px 32px;
                height: 55px;
                background: transparent;
                color: #DB334D;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-top: 32px;
                margin-bottom: 32px;
            }
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #494CA1;
                margin-top:16px ;
            }
    
            .img-request-demo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 95%;
                padding-top: 50px;
                border-radius: 6px;
            }

            .button-center-xs{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .about-people{
            section{
                padding-top: 32px;
                padding-bottom: 44px;
            }
            .tit-about-people{
                text-align: center;
                h1{
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    text-align: center;
                    color: #18244E;
                    padding-top: 0px;
                    margin-top:0px;
                    margin-bottom:0px;
                }
                h3{
                    margin-top: 20px;
                    margin-bottom: 30px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                }
                p{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #18244E;
                    margin-bottom: 25px;
                }
            }
            .talent-pool{
                margin-top:40px;
                padding: 32px 24px;
                background: #18244E;
                border-radius: 6px;
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #FFFFFF;
                h1{
                    margin-top: 0px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: #FFFFFF;
                }
                p{
                    margin: 12px;
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #FFFFFF;
                }
                .btn{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    background: #18244E;
                    width: 100%;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                    border-radius: 6px;
                    padding: 12px 0;
                    margin-top: 40px;
                }
            }
        }
        
        
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
        .tit-about{
            padding-top: 32px;
            color: #18244E;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                padding-left: 16px;
                margin-right: 16px;
            }
            span{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .sec-history{
            padding-top: 32px;
            padding-bottom: 48px;
            .history{
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 2px rgba(24, 36, 78, 0.1);
                border-radius: 10px;
                h1{
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #18244E;
                    margin-top: 16px;
                    margin-bottom: 24px;
                    padding-left: 32px;
                    padding-right: 32px;
                }
                img{
                    width: 100%;
                    border-radius: 0px 0px 10px 10px;
                }
                p{
                    padding-left: 32px;
                    padding-right: 32px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #18244E;
                    margin-bottom: 48px;
                }
                
            }
        }
        

        .sec-team{
            padding-top: 32px;
            padding-bottom: 80px;
            section{
                padding-top: 0px;
                padding-bottom: 0px;
            }
            
            .team-gallery{
                padding-top: 15px;
                padding-bottom: 15px;
                text-align: center;
                color: #18244E;
                .title-meet-people{
                    margin-bottom: 24px;
                    h1{
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 12px;
                        margin-top:0px;
                    }
                    span{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
                
                .gallery{
                    margin-bottom: 53px;
                    display: inline-block;
                    margin-left: auto;
                    margin-right: auto;
                    .main-grid {
                        display: grid;
                        margin: auto;
                        height: 300px;
                        grid-template-columns: auto auto ;
                        background-color: none;
                        .left-grid{
                            height: 360px;
                            grid-column:1;
                            grid-row: 1;
                            grid-auto-rows: min-content;
                            grid-auto-columns: min-content;
                            img{
                                height: 300px;
                            }
                        }
                        .right-grid{
                            width: 100%;
                            height: 150px;
                            grid-column: 2;
                            grid-row: 1;
                            grid-template-columns: 1fr;
                            grid-auto-flow: column dense;
                            .item-grid{
                                margin: 0px;
                                padding: 0px;
                                display: grid;
                                grid-template-columns: auto auto ;
                                img{
                                    height: 150px;
                                    vertical-align: middle;
                                    float: left;
                                }
                            }
                        }
                        img{
                            padding: 3px;
                        }
                    }
                }
            }
            
            .leadership{
                .title-leadership{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: #18244E;
                    margin-bottom: 24px;
                }
                .team-panel{
                    padding: 24px 8px;
                    font-family: 'OpenSans';
                    font-style: normal;
                    .team-image{
                        height: 280px;
                        img{
                            vertical-align: bottom;
                            height: 100%;
                        }
                    }
                    .team-detail{
                        text-align: center;
                        .team-name{
                            width: 100%;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 27px;
                            color: #18244E;
                            margin-bottom: 8px;
                        }
                        .team-position{
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: #18244E;
                        }
                        .team-description{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            color: #18244E;
                            margin-top: 24px;
                        }
                    }
                    .bg-1{
                        background: #564FDB;
                    }
                    .bg-2{
                        background: #479AFF;
                    }
                    .bg-3{
                        background: #DB334D;
                    }
                    .bg-4{
                        background: #CFCFCF;
                    }
                    .bg-5{
                        background: #C2DDFF;
                    }
                }
            }
        }

        .request-demo{
            section{
                padding-top: 32px;
                padding-bottom: 32px;
            }
            width:100%;
            background: #F3F2FF;
            padding: 0px 32px;
            h1{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
    
                color: #18244E;
            }
            p{
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
    
                color: #18244E;
                margin-bottom: 30px;
            }
            .btn-start-trial{
                padding: 14px 32px;
                height: 55px;
                background: #DB334D;
                border-radius: 6px;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                align-items: center;
            }
            .btn-request-demo{
                padding: 14px 32px;
                height: 55px;
                background: transparent;
                color: #DB334D;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-top: 32px;
                margin-bottom: 32px;
            }
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #494CA1;
                margin-top:16px ;
            }
    
            .img-request-demo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                border-radius: 6px;
            }

            .button-center-xs{
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .about-people{
            section{
                padding-top: 32px;
                padding-bottom: 44px;
            }
            .tit-about-people{
                text-align: center;
                h1{
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    text-align: center;
                    color: #18244E;
                    padding-top: 0px;
                    margin-top:0px;
                    margin-bottom:0px;
                }
                h3{
                    margin-top: 20px;
                    margin-bottom: 30px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                }
                p{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #18244E;
                    margin-bottom: 25px;
                }
            }
            .talent-pool{
                margin-top:40px;
                padding: 32px 24px;
                background: #18244E;
                border-radius: 6px;
                font-family: 'OpenSans';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                text-align: center;
                color: #FFFFFF;
                h1{
                    margin-top: 0px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: #FFFFFF;
                }
                p{
                    margin: 12px;
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #FFFFFF;
                }
                .btn{
                    font-family: 'OpenSans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    background: #18244E;
                    width: auto;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                    border-radius: 6px;
                    padding: 14px 32px;
                }
            }
        }
        
    }
}