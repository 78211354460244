/*
ASTRONAUT LANDING
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Sep 15, 2019
UPDATE DATE: Sep 15, 2019
REVISION: 1
NAME: astronaut.register-1.0.css
TYPE: Cascading Style Sheet
DESCRIPTION: Astronaut | Uncover talent, engage & make confident offers fast.
PALETTE:    1. #564FDB / rgb(86, 79, 219) (Soft Blue / Royal Blue)
            2. #844FDB / rgb(132, 79, 219) (Soft Violet / Medium Purple)
*/
#code {
.center {
    text-align: center;
}

.error-code {
    color: red;
    font-size: 12px;
    margin-top: 10px;
}

.loader-submit {
    margin-right: 10px;
}

.sec-code{}

.box-code{
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background: #FFF;
    border-radius: 20px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
}

.tit-code{
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin: -5px 0 20px 0;
    color: #18244E;
}

.tit-code img{
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 44px;
    margin: 0 2px;
    box-sizing: border-box;
}

ul.the-form > li input{
    text-align: center;
}

ul.the-form.for-sign > li input{
    text-align: left;
}

ul.the-form > li.reg-text{
    text-align: center;
    font-size: 12px;
    line-height: 16px;
}

ul.the-form > li.reg-text a{
    font-weight: bold;
}

ul.the-form > li.reg-text a:hover{
    color: #844FDB;
}

ul.the-form.for-dev li button{
    width: 200px;
}

.box-desc{
    max-width: 500px;
    margin: 30px auto -15px auto;
    font-size: 14px;
    line-height: 18px;
}

.box-desc p{
    margin: 15px 0;
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){
    .tit-code{
        text-align: center;
        font-size: 24px;
        line-height: 30px;
    }
    
    .tit-code img{
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/

}